import React, { useState, useRef, useEffect } from 'react';
import { Search, Upload, ChevronRight, Clock, Trophy, History, Trash2, FileText, Link, Loader2 } from 'lucide-react';

const CareerTransitionSearch = () => {
  const searchRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [recentSearches, setRecentSearches] = useState([
    'Marketing Manager',
    'Software Developer',
    'Data Entry Specialist'
  ]);

  const popularTransitions = [
    {
      title: 'Customer Service → AI Prompt Engineer',
      growth: '+125% YoY',
      avgSalary: '$75,000',
      difficulty: 'Medium'
    },
    {
      title: 'Teacher → Instructional Designer',
      growth: '+85% YoY',
      avgSalary: '$70,000',
      difficulty: 'Low'
    },
    {
      title: 'Sales Rep → Data Analyst',
      growth: '+95% YoY',
      avgSalary: '$85,000',
      difficulty: 'Medium'
    }
  ];

  const getSearchSuggestions = (query) => {
    const suggestions = [
      {
        role: 'Customer Service Representative',
        category: 'Service',
        transitions: 3
      },
      {
        role: 'Customer Success Manager',
        category: 'Management',
        transitions: 5
      },
      {
        role: 'Customer Experience Designer',
        category: 'Design',
        transitions: 4
      }
    ].filter(item => 
      item.role.toLowerCase().includes(query.toLowerCase())
    );
    return suggestions;
  };

  const handleResumeUpload = async (file) => {
    setIsUploading(true);
    setShowPreview(true);
    
    setTimeout(() => {
      setIsUploading(false);
    }, 1500);
  };

  const clearRecentSearches = () => {
    setRecentSearches([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={searchRef}>
      {/* Search Input */}
      <div className="flex items-center space-x-2 bg-white p-4 rounded-lg border shadow-sm">
        <Search className="w-5 h-5 text-gray-400" />
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Enter your current job title..."
          className="flex-1 outline-none"
          onFocus={() => setIsSearchFocused(true)}
        />
      </div>

      {/* Dropdown Panel */}
      {isSearchFocused && (
        <div className="absolute w-full mt-2 bg-white rounded-lg shadow-lg divide-y divide-gray-100 z-20">
          {/* Resume Upload Section */}
          <div className="p-4 bg-white rounded-t-lg">
            <div className="flex items-center justify-between mb-4">
              <h3 className="font-medium text-gray-800">Quick Resume Upload</h3>
              {isUploading && <Loader2 className="w-4 h-4 animate-spin text-blue-500" />}
            </div>
            <div className="flex flex-wrap gap-3">
              <div className="flex-1">
                <input 
                  type="file" 
                  className="hidden" 
                  id="resume-upload"
                  onChange={(e) => handleResumeUpload(e.target.files[0])}
                />
                <label 
                  htmlFor="resume-upload" 
                  className="flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg cursor-pointer hover:bg-blue-600 transition-colors"
                >
                  <Upload className="w-4 h-4" />
                  Upload Resume
                </label>
              </div>
              <button className="flex items-center gap-2 px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors">
                <Link className="w-4 h-4" />
                LinkedIn
              </button>
              <button className="flex items-center gap-2 px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors">
                <FileText className="w-4 h-4" />
                Paste Text
              </button>
            </div>
          </div>

          {/* Resume Preview */}
          {showPreview && (
            <div className="p-4 bg-gray-50">
              <h3 className="font-medium text-gray-800 mb-3">Quick Preview</h3>
              {isUploading ? (
                <div className="flex items-center gap-2 text-gray-600">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  Analyzing your resume...
                </div>
              ) : (
                <div className="space-y-2 text-gray-700">
                  <div className="text-sm">
                    <span className="font-medium">Current Role:</span> Customer Service Manager
                  </div>
                  <div className="text-sm">
                    <span className="font-medium">Top Skills:</span> Team Leadership, Client Relations
                  </div>
                  <div className="text-sm">
                    <span className="font-medium">Experience:</span> 3 years
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Search Suggestions */}
          {searchQuery && (
            <div className="p-4 bg-white">
              <h3 className="font-medium text-gray-800 mb-3">Suggestions</h3>
              {getSearchSuggestions(searchQuery).map((suggestion, index) => (
                <div 
                  key={index}
                  className="flex items-center justify-between py-2 px-3 hover:bg-gray-50 rounded cursor-pointer transition-colors"
                >
                  <div>
                    <div className="font-medium text-gray-800">{suggestion.role}</div>
                    <div className="text-sm text-gray-500">{suggestion.category}</div>
                  </div>
                  <div className="text-sm text-blue-600">
                    {suggestion.transitions} possible transitions
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Recent Searches */}
          {recentSearches.length > 0 && (
            <div className="p-4 bg-white">
              <div className="flex items-center justify-between mb-3">
                <h3 className="font-medium text-gray-800">Recent Searches</h3>
                <button 
                  onClick={clearRecentSearches}
                  className="text-sm text-gray-500 hover:text-gray-700 flex items-center gap-1 transition-colors"
                >
                  <Trash2 className="w-4 h-4" />
                  Clear
                </button>
              </div>
              {recentSearches.map((search, index) => (
                <div 
                  key={index}
                  className="flex items-center gap-2 py-2 px-3 hover:bg-gray-50 rounded cursor-pointer transition-colors"
                >
                  <History className="w-4 h-4 text-gray-400" />
                  <span className="text-gray-700">{search}</span>
                </div>
              ))}
            </div>
          )}

          {/* Popular Transitions */}
          <div className="p-4 bg-white rounded-b-lg">
            <h3 className="font-medium text-gray-800 mb-3">Popular Career Transitions</h3>
            {popularTransitions.map((transition, index) => (
              <div 
                key={index}
                className="flex items-center justify-between py-2 px-3 hover:bg-gray-50 rounded cursor-pointer transition-colors"
              >
                <div>
                  <div className="font-medium text-gray-800">{transition.title}</div>
                  <div className="flex items-center gap-3 mt-1">
                    <span className="text-sm text-green-600">{transition.growth}</span>
                    <span className="text-sm text-gray-600">{transition.avgSalary}</span>
                    <span className="text-sm text-blue-600">
                      {transition.difficulty} Difficulty
                    </span>
                  </div>
                </div>
                <Trophy className="w-4 h-4 text-yellow-500" />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerTransitionSearch;