import React, { useState } from 'react';
import { ClipboardList, Target, Users, Briefcase, Plus, X, ArrowRight } from 'lucide-react';

const JobResponsibilities = ({ formData, handleChange }) => {
  const [newResponsibility, setNewResponsibility] = useState('');
  const [newObjective, setNewObjective] = useState('');

  const addItem = (type, value, setValue) => {
    if (value.trim()) {
      const currentItems = formData.responsibilities[type] || [];
      handleChange('responsibilities', type, [...currentItems, value.trim()]);
      setValue('');
    }
  };

  const removeItem = (type, index) => {
    const currentItems = formData.responsibilities[type] || [];
    handleChange(
      'responsibilities', 
      type, 
      currentItems.filter((_, i) => i !== index)
    );
  };

  return (
    <div className="space-y-6">
      {/* Core Responsibilities */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <ClipboardList className="h-5 w-5 mr-2 text-blue-600" />
          Core Responsibilities
          <span className="text-red-500 ml-1">*</span>
        </h3>
        <div className="space-y-4">
          <div className="flex space-x-2">
            <input
              type="text"
              value={newResponsibility}
              onChange={(e) => setNewResponsibility(e.target.value)}
              placeholder="Add a core responsibility"
              className="flex-1 rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  addItem('coreResponsibilities', newResponsibility, setNewResponsibility);
                }
              }}
            />
            <button
              type="button"
              onClick={() => addItem('coreResponsibilities', newResponsibility, setNewResponsibility)}
              className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="h-5 w-5" />
            </button>
          </div>
          <div className="space-y-2">
            {formData.responsibilities?.coreResponsibilities?.map((resp, index) => (
              <div
                key={index}
                className="flex items-center bg-gray-50 rounded-lg p-3 group"
              >
                <ArrowRight className="h-4 w-4 text-blue-600 mr-2 flex-shrink-0" />
                <span className="flex-1 text-gray-700">{resp}</span>
                <button
                  onClick={() => removeItem('coreResponsibilities', index)}
                  className="ml-2 text-gray-400 hover:text-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Key Objectives */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Target className="h-5 w-5 mr-2 text-blue-600" />
          Key Objectives
        </h3>
        <div className="space-y-4">
          <div className="flex space-x-2">
            <input
              type="text"
              value={newObjective}
              onChange={(e) => setNewObjective(e.target.value)}
              placeholder="Add a key objective or goal"
              className="flex-1 rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  addItem('keyObjectives', newObjective, setNewObjective);
                }
              }}
            />
            <button
              type="button"
              onClick={() => addItem('keyObjectives', newObjective, setNewObjective)}
              className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="h-5 w-5" />
            </button>
          </div>
          <div className="space-y-2">
            {formData.responsibilities?.keyObjectives?.map((obj, index) => (
              <div
                key={index}
                className="flex items-center bg-blue-50 rounded-lg p-3 group"
              >
                <Target className="h-4 w-4 text-blue-600 mr-2 flex-shrink-0" />
                <span className="flex-1 text-gray-700">{obj}</span>
                <button
                  onClick={() => removeItem('keyObjectives', index)}
                  className="ml-2 text-gray-400 hover:text-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Role Details */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Briefcase className="h-5 w-5 mr-2 text-blue-600" />
          Role Details
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Project Involvement
            </label>
            <select
              value={formData.responsibilities?.projectInvolvement || ''}
              onChange={(e) => handleChange('responsibilities', 'projectInvolvement', e.target.value)}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="">Select Involvement Level</option>
              <option value="individual">Individual Contributor</option>
              <option value="team_member">Team Member</option>
              <option value="team_lead">Team Lead</option>
              <option value="project_manager">Project Manager</option>
              <option value="program_manager">Program Manager</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Decision Making Level
            </label>
            <select
              value={formData.responsibilities?.decisionMaking || ''}
              onChange={(e) => handleChange('responsibilities', 'decisionMaking', e.target.value)}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="">Select Decision Level</option>
              <option value="independent">Independent Decisions</option>
              <option value="team">Team-based Decisions</option>
              <option value="department">Departmental Decisions</option>
              <option value="organizational">Organizational Decisions</option>
            </select>
          </div>
        </div>
      </div>

      {/* Team Collaboration */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Users className="h-5 w-5 mr-2 text-blue-600" />
          Team Collaboration
        </h3>
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Team Interaction
              </label>
              <select
                value={formData.responsibilities?.teamInteraction || ''}
                onChange={(e) => handleChange('responsibilities', 'teamInteraction', e.target.value)}
                className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Interaction Level</option>
                <option value="minimal">Minimal Team Interaction</option>
                <option value="moderate">Moderate Team Interaction</option>
                <option value="high">High Team Interaction</option>
                <option value="leadership">Team Leadership</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Cross-functional Collaboration
              </label>
              <div className="space-y-2">
                {[
                  'Engineering',
                  'Product',
                  'Design',
                  'Marketing',
                  'Sales',
                  'Customer Support',
                  'Finance'
                ].map((dept) => (
                  <label key={dept} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.responsibilities?.crossFunctional?.includes(dept)}
                      onChange={(e) => {
                        const current = formData.responsibilities?.crossFunctional || [];
                        const updated = e.target.checked
                          ? [...current, dept]
                          : current.filter(d => d !== dept);
                        handleChange('responsibilities', 'crossFunctional', updated);
                      }}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-600">{dept}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Additional Notes
            </label>
            <textarea
              value={formData.responsibilities?.additionalNotes || ''}
              onChange={(e) => handleChange('responsibilities', 'additionalNotes', e.target.value)}
              placeholder="Any additional details about the role's responsibilities..."
              rows={4}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobResponsibilities;