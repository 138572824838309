import React, { useState } from 'react';
import { useAuth } from '../authentication/AuthContext';
import { useNavigate } from 'react-router-dom';

const TrainingSignup = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [employeeKey, setEmployeeKey] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, employeeKey, role: 'TrainingDataAdmin' }),
      });

      if (!response.ok) {
        throw new Error('Signup failed: ' + (await response.text()));
      }

      const { user, token } = await response.json();
      login(user, token);
      navigate('/training/admin');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <form onSubmit={handleSignup}>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
      />
      <input
        type="text"
        value={employeeKey}
        onChange={(e) => setEmployeeKey(e.target.value)}
        placeholder="Employee Key"
        required
      />
      <button type="submit">Signup</button>
    </form>
  );
};

export default TrainingSignup;
