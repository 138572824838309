import React, { useState } from 'react';
import { Check, Clock, Briefcase, Building2, ArrowLeft, Award, BookOpen, Star } from 'lucide-react';

const SlidingPanel = ({ certification, isOpen, onClose }) => {
    const [activeTab, setActiveTab] = useState('overview');
  
    const tabs = [
      { id: 'overview', label: 'Overview' },
      { id: 'curriculum', label: 'Curriculum' },
      { id: 'career', label: 'Career Path' },
      { id: 'regions', label: 'Regional Info' },
      { id: 'trainer', label: 'Trainer Info' }
    ];
  
    if (!isOpen || !certification) return null;
  
    return (
      <>
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40 p-10" onClick={onClose} />
        <div className="fixed inset-y-0 right-0 w-full md:w-2/3 lg:w-1/2 bg-white shadow-xl z-50">
          <div className="h-full flex flex-col">
            <div className="border-b">
              <div className="p-4 flex items-center gap-4">
                <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
                  <ArrowLeft className="w-5 h-5" />
                </button>
                <div>
                  <h2 className="text-xl font-bold">{certification.title}</h2>
                  <p className="text-sm text-gray-600">{certification.description}</p>
                </div>
              </div>
  
              <div className="px-4 flex space-x-4 border-b">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`px-4 py-2 border-b-2 transition-colors ${
                      activeTab === tab.id ? 'border-blue-600 text-blue-600' : 'border-transparent text-gray-600 hover:text-gray-900'
                    }`}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            </div>
  
            <div className="flex-1 overflow-y-auto">
              <div className="p-6 space-y-6">
                {activeTab === 'overview' && (
                  <>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="border rounded-lg p-4">
                        <div className="flex items-center gap-2 mb-2">
                          <Clock className="w-5 h-5 text-blue-600" />
                          <h3 className="font-medium">Duration</h3>
                        </div>
                        <p>{certification.duration}</p>
                      </div>
                      <div className="border rounded-lg p-4">
                        <div className="flex items-center gap-2 mb-2">
                          <Award className="w-5 h-5 text-blue-600" />
                          <h3 className="font-medium">Recognition</h3>
                        </div>
                        <p>{certification.recognition}</p>
                      </div>
                    </div>
  
                    <div className="border rounded-lg p-4">
                      <h3 className="font-medium mb-3">Required Skills</h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                        {certification.skills.map((skill, idx) => (
                          <div key={idx} className="flex items-center gap-2">
                            <Check className="w-4 h-4 text-green-500" />
                            <span>{skill}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
  
                {activeTab === 'career' && (
                  <div className="border rounded-lg p-4">
                    <h3 className="font-medium mb-4">Career Opportunities</h3>
                    <div className="space-y-4">
                      {certification.jobs.map((job, idx) => (
                        <div key={idx} className="flex items-center gap-2">
                          <Briefcase className="w-4 h-4 text-blue-600" />
                          <span>{job}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {activeTab === 'trainer' && (
                  <div className="space-y-6">
                    <div className="border rounded-lg p-4">
                      <div className="flex items-center gap-2 mb-4">
                        <BookOpen className="w-5 h-5 text-blue-600" />
                        <h3 className="font-medium">Trainer Details</h3>
                      </div>
                      
                      {certification.trainerDetails && certification.trainerDetails.length > 0 ? (
                        <div className="space-y-4">
                          {certification.trainerDetails.map((trainer, idx) => (
                            <div key={idx} className="border-b last:border-b-0 pb-4 last:pb-0">
                              <div className="flex items-center gap-2 mb-2">
                                <Star className="w-4 h-4 text-yellow-500" />
                                <span className="font-medium">{trainer.name || 'Trainer Name Not Available'}</span>
                              </div>
                              {trainer.experience && (
                                <p className="text-gray-600 ml-6">
                                  Experience: {trainer.experience}
                                </p>
                              )}
                              {trainer.specialization && (
                                <p className="text-gray-600 ml-6">
                                  Specialization: {trainer.specialization}
                                </p>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-600">No trainer information is currently available.</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
  
            <div className="border-t p-4 bg-white">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-2xl font-bold">{certification.price}</p>
                  <p className="text-sm text-gray-600">All inclusive</p>
                </div>
                <button className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700">
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default SlidingPanel;

// import React, { useState } from 'react';
// import { ArrowLeft } from 'lucide-react';
// import OverviewTab from './sliding-panel-components/OverviewTab';
// import CurriculumTab from './sliding-panel-components/CurriculumTab';
// import CareerPathTab from './sliding-panel-components/CareerPathTab';
// import RegionalInfoTab from './sliding-panel-components/RegionalInfoTab';
// import TrainerDetailsTab from './sliding-panel-components/TrainerDetailsTab';

// const SlidingPanel = ({ certification, isOpen, onClose }) => {
//     const [activeTab, setActiveTab] = useState('overview');
  
//     const tabs = [
//       { id: 'overview', label: 'Overview', component: <OverviewTab certification={certification} /> },
//       { id: 'curriculum', label: 'Curriculum', component: <CurriculumTab certification={certification} /> },
//       { id: 'career', label: 'Career Path', component: <CareerPathTab certification={certification} /> },
//       { id: 'regions', label: 'Regional Info', component: <RegionalInfoTab certification={certification} /> },
//       { id: 'trainer', label: 'Trainer Details', component: <TrainerDetailsTab certification={certification} /> }
//     ];
  
//     if (!isOpen || !certification) return null;
  
//     return (
//       <>
//         <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={onClose} />
//         <div className="fixed inset-y-0 right-0 w-full md:w-2/3 lg:w-1/2 bg-white shadow-xl z-50">
//           <div className="h-full flex flex-col">
//             <div className="border-b">
//               <div className="p-4 flex items-center gap-4">
//                 <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
//                   <ArrowLeft className="w-5 h-5" />
//                 </button>
//                 <div>
//                   <h2 className="text-xl font-bold">{certification.name}</h2>
//                   <p className="text-sm text-gray-600">{certification.description}</p>
//                 </div>
//               </div>
  
//               <div className="px-4 flex space-x-4 border-b">
//                 {tabs.map((tab) => (
//                   <button
//                     key={tab.id}
//                     onClick={() => setActiveTab(tab.id)}
//                     className={`px-4 py-2 border-b-2 transition-colors ${
//                       activeTab === tab.id ? 'border-blue-600 text-blue-600' : 'border-transparent text-gray-600 hover:text-gray-900'
//                     }`}
//                   >
//                     {tab.label}
//                   </button>
//                 ))}
//               </div>
//             </div>
  
//             <div className="flex-1 overflow-y-auto p-6 space-y-6">
//               {tabs.find((tab) => tab.id === activeTab)?.component}
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   };
  
//   export default SlidingPanel;