export const transitionPlansData = {
    "AI Prompt Engineer": {
      currentRole: "Customer Service Representative",
      targetRole: "AI Prompt Engineer",
      duration: "6 months",
      phases: [
        {
          title: "Foundation Phase",
          duration: "6 weeks",
          progress: 0,
          milestones: [
            {
              title: "AI Fundamentals",
              duration: "2 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Course",
                  title: "Introduction to AI and Machine Learning",
                  platform: "Coursera",
                  duration: "20 hours",
                  cost: "$49"
                },
                {
                  type: "Practice",
                  title: "Basic AI Concepts Quiz",
                  platform: "Internal",
                  duration: "2 hours",
                  cost: "Free"
                }
              ]
            },
            {
              title: "NLP Basics",
              duration: "2 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Course",
                  title: "Natural Language Processing Fundamentals",
                  platform: "edX",
                  duration: "25 hours",
                  cost: "$99"
                }
              ]
            },
            {
              title: "Prompt Engineering Principles",
              duration: "2 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Workshop",
                  title: "Prompt Engineering Basics",
                  platform: "Industry Expert Session",
                  duration: "15 hours",
                  cost: "$149"
                }
              ]
            }
          ]
        },
        {
          title: "Skill Building Phase",
          duration: "8 weeks",
          progress: 0,
          milestones: [
            {
              title: "Advanced NLP",
              duration: "3 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Course",
                  title: "Advanced NLP Techniques",
                  platform: "Stanford Online",
                  duration: "30 hours",
                  cost: "$199"
                }
              ]
            },
            {
              title: "Practical Prompt Engineering",
              duration: "3 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Project",
                  title: "Real-world Prompt Engineering",
                  platform: "Industry Project",
                  duration: "40 hours",
                  cost: "$299"
                }
              ]
            }
          ]
        },
        {
          title: "Practical Application Phase",
          duration: "10 weeks",
          progress: 0,
          milestones: [
            {
              title: "Portfolio Building",
              duration: "4 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Project",
                  title: "Build Professional Portfolio",
                  platform: "Guided Project",
                  duration: "40 hours",
                  cost: "Free"
                }
              ]
            },
            {
              title: "Industry Certification",
              duration: "3 weeks",
              status: "not-started",
              resources: [
                {
                  type: "Certification",
                  title: "AI Prompt Engineering Certification",
                  platform: "Industry Standard Cert",
                  duration: "30 hours",
                  cost: "$399"
                }
              ]
            }
          ]
        }
      ]
    },
    "Data Analyst": {
    currentRole: "Data Entry Clerk",
    targetRole: "Data Analyst",
    duration: "8 months",
    phases: [
      {
        title: "Foundation Phase",
        duration: "10 weeks",
        progress: 0,
        milestones: [
          {
            title: "SQL Fundamentals",
            duration: "3 weeks",
            status: "not-started",
            resources: [
              {
                type: "Course",
                title: "Introduction to SQL",
                platform: "DataCamp",
                duration: "25 hours",
                cost: "$39"
              },
              {
                type: "Practice",
                title: "SQL Query Practice Lab",
                platform: "HackerRank",
                duration: "10 hours",
                cost: "Free"
              }
            ]
          },
          {
            title: "Python Basics",
            duration: "4 weeks",
            status: "not-started",
            resources: [
              {
                type: "Course",
                title: "Python for Data Analysis",
                platform: "Udemy",
                duration: "35 hours",
                cost: "$89"
              },
              {
                type: "Workshop",
                title: "Python Programming Exercises",
                platform: "CodeAcademy",
                duration: "15 hours",
                cost: "$49"
              }
            ]
          },
          {
            title: "Statistics Fundamentals",
            duration: "3 weeks",
            status: "not-started",
            resources: [
              {
                type: "Course",
                title: "Statistics for Data Analysis",
                platform: "Coursera",
                duration: "20 hours",
                cost: "$79"
              }
            ]
          }
        ]
      },
      {
        title: "Technical Skills Phase",
        duration: "12 weeks",
        progress: 0,
        milestones: [
          {
            title: "Data Visualization",
            duration: "4 weeks",
            status: "not-started",
            resources: [
              {
                type: "Course",
                title: "Data Visualization with Tableau",
                platform: "Tableau Academy",
                duration: "30 hours",
                cost: "$199"
              },
              {
                type: "Project",
                title: "Interactive Dashboard Creation",
                platform: "GitHub",
                duration: "20 hours",
                cost: "Free"
              }
            ]
          },
          {
            title: "Advanced SQL & Database Management",
            duration: "4 weeks",
            status: "not-started",
            resources: [
              {
                type: "Course",
                title: "Advanced SQL for Analytics",
                platform: "Stanford Online",
                duration: "40 hours",
                cost: "$299"
              }
            ]
          },
          {
            title: "Data Analysis Libraries",
            duration: "4 weeks",
            status: "not-started",
            resources: [
              {
                type: "Course",
                title: "Pandas, NumPy & Matplotlib",
                platform: "DataCamp",
                duration: "35 hours",
                cost: "$159"
              }
            ]
          }
        ]
      },
      {
        title: "Professional Development Phase",
        duration: "10 weeks",
        progress: 0,
        milestones: [
          {
            title: "Analytics Project Portfolio",
            duration: "4 weeks",
            status: "not-started",
            resources: [
              {
                type: "Project",
                title: "Real-world Data Analysis Projects",
                platform: "Guided Projects",
                duration: "40 hours",
                cost: "Free"
              },
              {
                type: "Workshop",
                title: "Portfolio Development Workshop",
                platform: "Industry Expert Session",
                duration: "10 hours",
                cost: "$99"
              }
            ]
          },
          {
            title: "Business Analytics",
            duration: "3 weeks",
            status: "not-started",
            resources: [
              {
                type: "Course",
                title: "Business Analytics Fundamentals",
                platform: "Google Analytics Academy",
                duration: "25 hours",
                cost: "Free"
              }
            ]
          },
          {
            title: "Professional Certification",
            duration: "3 weeks",
            status: "not-started",
            resources: [
              {
                type: "Certification",
                title: "Data Analyst Professional Certificate",
                platform: "IBM",
                duration: "30 hours",
                cost: "$399"
              }
            ]
          }
        ]
      }
    ]
  }
  };
  
  export const jobTransitions = [
    {
      fromJob: "Customer Service Representative",
      toJob: "AI Prompt Engineer",
      requiredSkills: ["Natural Language Processing", "Creative Writing", "AI Systems"],
      timeToAchieve: "6 months",
      matchScore: "85%",
      existingSkills: ["Communication", "Problem Solving"],
      skillsToAcquire: ["NLP", "AI Fundamentals"]
    },
    {
      fromJob: "Data Entry Clerk",
      toJob: "Data Analyst",
      requiredSkills: ["SQL", "Python", "Data Visualization"],
      timeToAchieve: "8 months",
      matchScore: "75%",
      existingSkills: ["Attention to Detail", "Data Entry"],
      skillsToAcquire: ["SQL", "Python"]
    }
  ];
  
  export const countryDemandData = {
    'United States': {
      'AI Prompt Engineer': { demand: 'Very High', salary: '$70,000 - $85,000' },
      'Data Analyst': { demand: 'High', salary: '$75,000 - $95,000' },
      'Project Coordinator': { demand: 'Medium', salary: '$60,000 - $75,000' }
    },
    'United Kingdom': {
      'AI Prompt Engineer': { demand: 'High', salary: '£40,000 - £55,000' },
      'Data Analyst': { demand: 'Very High', salary: '£45,000 - £65,000' },
      'Project Coordinator': { demand: 'Medium', salary: '£35,000 - £45,000' }
    }
  };