import React from 'react';
import { Outlet } from 'react-router-dom';

const TalentLayout = () => (
  <div>
    <h1>Talent Dashboard</h1>
    {/* Add User-specific Header or Navigation if needed */}
    <Outlet />
  </div>
);

export default TalentLayout;
