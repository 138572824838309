// import React, { useState } from 'react';
// import { useLocation, Link } from 'react-router-dom';
// import { BookOpen, Clock, Trophy, Calendar, Target, CheckCircle, Book, Users, Briefcase, ChevronRight, ArrowRight } from 'lucide-react';

// const TransitionPlan = () => {
//   const location = useLocation();
//   const { transition, planData } = location.state || {};
//   const [activePhase, setActivePhase] = useState(0);
//   const [selectedMilestone, setSelectedMilestone] = useState(null);

//   // If no data was passed, show error or redirect
//   if (!transition || !planData) {
//     return (
//       <div className="max-w-4xl mx-auto p-4">
//         <div className="bg-red-50 p-6 rounded-lg">
//           <h2 className="text-red-700 font-semibold">No transition plan data available</h2>
//           <Link to="/" className="text-blue-500 hover:underline mt-4 inline-block">
//             Return to Career Transitions
//           </Link>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="max-w-4xl mx-auto p-4 space-y-6">
//   {/* Header Section */}
//   <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg shadow">
//     <div className="p-6">
//       <div className="flex justify-between items-center">
//         <div>
//           <h2 className="text-2xl font-bold">Transition Plan</h2>
//           <div className="flex items-center mt-2 text-gray-600">
//             <Briefcase className="w-4 h-4 mr-2" />
//             {planData.currentRole}
//             <ArrowRight className="mx-2" />
//             <span className="font-medium text-blue-600">{planData.targetRole}</span>
//           </div>
//         </div>
//         <div className="text-right">
//           <div className="text-sm text-gray-600">Total Duration</div>
//           <div className="flex items-center text-lg font-semibold">
//             <Clock className="w-4 h-4 mr-2 text-blue-500" />
//             {planData.duration}
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>

//   {/* Responsive Layout */}
//   <div className="flex flex-col-reverse md:flex-row gap-4">
//     {/* Phase Navigation - Moves to Top on Mobile */}
//     <div className="w-full md:w-1/3 order-first md:order-none">
//       <div className="bg-white rounded-lg shadow">
//         <div className="border-b p-4">
//           <h2 className="text-lg font-semibold">Learning Phases</h2>
//         </div>
//         <div className="p-4">
//           <div className="flex flex-row md:flex-col space-x-2 md:space-x-0 md:space-y-4">
//             {planData.phases.map((phase, index) => (
//               <button
//                 key={index}
//                 onClick={() => setActivePhase(index)}
//                 className={`flex-1 text-left p-4 rounded-lg transition-all ${
//                   activePhase === index
//                     ? 'bg-blue-500 text-white'
//                     : 'bg-gray-50 hover:bg-gray-100'
//                 }`}
//               >
//                 <div className="font-medium">{phase.title}</div>
//                 <div className="text-sm mt-1 flex items-center gap-2">
//                   <Clock className="w-4 h-4" />
//                   {phase.duration}
//                 </div>
//               </button>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>

//     {/* Active Phase Details */}
//     <div className="w-full md:w-2/3">
//       <div className="bg-white rounded-lg shadow">
//         <div className="border-b p-4">
//           <h2 className="text-lg font-semibold">{planData.phases[activePhase].title}</h2>
//         </div>
//         <div className="p-4">
//           <div className="space-y-6">
//             {planData.phases[activePhase].milestones.map((milestone, index) => (
//               <div
//                 key={index}
//                 className={`p-4 rounded-lg border cursor-pointer transition-all mb-4 ${
//                   selectedMilestone === index ? 'border-blue-500 bg-blue-50' : 'hover:bg-gray-50'
//                 }`}
//                 onClick={() => setSelectedMilestone(selectedMilestone === index ? null : index)}
//               >
//                 <div className="flex justify-between items-start">
//                   <div>
//                     <h3 className="font-medium">{milestone.title}</h3>
//                     <div className="text-sm text-gray-600 mt-1 flex items-center gap-2">
//                       <Clock className="w-4 h-4" />
//                       {milestone.duration}
//                     </div>
//                   </div>
//                   <div
//                     className={`px-3 py-1 rounded-full text-sm ${
//                       milestone.status === 'completed'
//                         ? 'bg-green-100 text-green-700'
//                         : milestone.status === 'in-progress'
//                         ? 'bg-blue-100 text-blue-700'
//                         : 'bg-gray-100 text-gray-700'
//                     }`}
//                   >
//                     {milestone.status === 'completed'
//                       ? 'Completed'
//                       : milestone.status === 'in-progress'
//                       ? 'In Progress'
//                       : 'Not Started'}
//                   </div>
//                 </div>

//                 {selectedMilestone === index && (
//                   <div className="mt-4 space-y-4">
//                     <h4 className="font-medium">Learning Resources</h4>
//                     {milestone.resources.map((resource, rIndex) => (
//                       <div key={rIndex} className="bg-white p-4 rounded-lg border mb-2">
//                         <div className="flex justify-between items-start">
//                           <div>
//                             <div className="font-medium">{resource.title}</div>
//                             <div className="text-sm text-gray-600 mt-1">{resource.platform}</div>
//                             <div className="flex items-center gap-4 mt-2 text-sm text-gray-600">
//                               <span className="flex items-center gap-1">
//                                 <Clock className="w-4 h-4" />
//                                 {resource.duration}
//                               </span>
//                               <span className="flex items-center gap-1">
//                                 <Book className="w-4 h-4" />
//                                 {resource.type}
//                               </span>
//                             </div>
//                           </div>
//                           <div>
//                             <div className="text-lg font-semibold text-blue-600">
//                               {resource.cost}
//                             </div>
//                             <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
//                               Start Learning
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>


//   );
// };

// export default TransitionPlan;


import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { BookOpen, Clock, Trophy, Calendar, Target, CheckCircle, Book, Users, Briefcase, ChevronRight, ArrowRight } from 'lucide-react';

const TransitionPlan = () => {
  const location = useLocation();
  const { transition, planData } = location.state || {};
  const [activePhase, setActivePhase] = useState(0);
  const [selectedMilestone, setSelectedMilestone] = useState(null);

  // Add debug logging
  useEffect(() => {
    console.log('Location State:', location.state);
    console.log('Transition:', transition);
    console.log('Plan Data:', planData);
  }, [location.state, transition, planData]);

  // If no data was passed, show error or redirect
  if (!transition || !planData) {
    return (
      <div className="max-w-4xl mx-auto p-4">
        <div className="bg-red-50 p-6 rounded-lg">
          <h2 className="text-red-700 font-semibold">No transition plan data available</h2>
          <Link to="/career-transition" className="text-blue-500 hover:underline mt-4 inline-block">
            Return to Career Transitions
          </Link>
        </div>
      </div>
    );
  }
  
return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
  {/* Header Section */}
  <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg shadow">
    <div className="p-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold">Transition Plan</h2>
          <div className="flex items-center mt-2 text-gray-600">
            <Briefcase className="w-4 h-4 mr-2" />
            {planData.currentRole}
            <ArrowRight className="mx-2" />
            <span className="font-medium text-blue-600">{planData.targetRole}</span>
          </div>
        </div>
        <div className="text-right">
          <div className="text-sm text-gray-600">Total Duration</div>
          <div className="flex items-center text-lg font-semibold">
            <Clock className="w-4 h-4 mr-2 text-blue-500" />
            {planData.duration}
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* Responsive Layout */}
  <div className="flex flex-col-reverse md:flex-row gap-4">
    {/* Phase Navigation - Moves to Top on Mobile */}
    <div className="w-full md:w-1/3 order-first md:order-none">
      <div className="bg-white rounded-lg shadow">
        <div className="border-b p-4">
          <h2 className="text-lg font-semibold">Learning Phases</h2>
        </div>
        <div className="p-4">
          <div className="flex flex-row md:flex-col space-x-2 md:space-x-0 md:space-y-4">
            {planData.phases.map((phase, index) => (
              <button
                key={index}
                onClick={() => setActivePhase(index)}
                className={`flex-1 text-left p-4 rounded-lg transition-all ${
                  activePhase === index
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-50 hover:bg-gray-100'
                }`}
              >
                <div className="font-medium">{phase.title}</div>
                <div className="text-sm mt-1 flex items-center gap-2">
                  <Clock className="w-4 h-4" />
                  {phase.duration}
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>

    {/* Active Phase Details */}
    <div className="w-full md:w-2/3">
      <div className="bg-white rounded-lg shadow">
        <div className="border-b p-4">
          <h2 className="text-lg font-semibold">{planData.phases[activePhase].title}</h2>
        </div>
        <div className="p-4">
          <div className="space-y-6">
            {planData.phases[activePhase].milestones.map((milestone, index) => (
              <div
                key={index}
                className={`p-4 rounded-lg border cursor-pointer transition-all mb-4 ${
                  selectedMilestone === index ? 'border-blue-500 bg-blue-50' : 'hover:bg-gray-50'
                }`}
                onClick={() => setSelectedMilestone(selectedMilestone === index ? null : index)}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium">{milestone.title}</h3>
                    <div className="text-sm text-gray-600 mt-1 flex items-center gap-2">
                      <Clock className="w-4 h-4" />
                      {milestone.duration}
                    </div>
                  </div>
                  <div
                    className={`px-3 py-1 rounded-full text-sm ${
                      milestone.status === 'completed'
                        ? 'bg-green-100 text-green-700'
                        : milestone.status === 'in-progress'
                        ? 'bg-blue-100 text-blue-700'
                        : 'bg-gray-100 text-gray-700'
                    }`}
                  >
                    {milestone.status === 'completed'
                      ? 'Completed'
                      : milestone.status === 'in-progress'
                      ? 'In Progress'
                      : 'Not Started'}
                  </div>
                </div>

                {selectedMilestone === index && (
                  <div className="mt-4 space-y-4">
                    <h4 className="font-medium">Learning Resources</h4>
                    {milestone.resources.map((resource, rIndex) => (
                      <div key={rIndex} className="bg-white p-4 rounded-lg border mb-2">
                        <div className="flex justify-between items-start">
                          <div>
                            <div className="font-medium">{resource.title}</div>
                            <div className="text-sm text-gray-600 mt-1">{resource.platform}</div>
                            <div className="flex items-center gap-4 mt-2 text-sm text-gray-600">
                              <span className="flex items-center gap-1">
                                <Clock className="w-4 h-4" />
                                {resource.duration}
                              </span>
                              <span className="flex items-center gap-1">
                                <Book className="w-4 h-4" />
                                {resource.type}
                              </span>
                            </div>
                          </div>
                          <div>
                            <div className="text-lg font-semibold text-blue-600">
                              {resource.cost}
                            </div>
                            <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                              Start Learning
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  );
};

export default TransitionPlan;