// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import trainingData from '../data/trainingData.json';
// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import companyLogos from "../data/companyLogos.json"

// const TrainingDetailPage = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [isScrolled, setIsScrolled] = useState(false);

//   // Find the relevant certification or workshop based on the ID
//   const allTrainings = Object.entries(trainingData.trainings).flatMap(([category, content]) => [
//     ...content.certifications || [],
//     ...content.workshops || []
//   ]);

//   const training = allTrainings.find((item) => item.id === parseInt(id));

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = window.scrollY;
//       setIsScrolled(scrollPosition > 300);
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   if (!training) {
//     return <div className="text-center py-10">Training not found!</div>;
//   }

//   const handleBack = () => {
//     navigate(-1);
//   };

//   return (
//     <div className="bg-gray-100 min-h-screen relative">
//       <button
//         onClick={handleBack}
//         className="absolute top-4 left-4 bg-blue-500 text-white py-2 px-4 rounded-full shadow-lg hover:bg-blue-600 transition duration-300"
//       >
//         Back
//       </button>

//       <CourseHeader training={training} />

//       <div className="max-w-4xl mx-auto bg-white p-4 sm:p-8 rounded-lg shadow-lg mt-4">
//         <CourseDetails training={training} />
//         <CourseOutcome />
//         <CompaniesHiring />
//         <FAQSection />
//       </div>

//       <div
//         className={`fixed ${
//           isScrolled ? 'bottom-6 right-6' : 'bottom-6 left-1/2 transform -translate-x-1/2'
//         } bg-white p-4 rounded-lg shadow-lg transition-all duration-300`}
//       >
//         <p className="text-xl font-bold mb-2">Course Fee: ₹{training.price}</p>
//         <a
//           href="/payment"
//           className="block bg-blue-500 text-white text-center py-2 px-4 rounded-full shadow-lg hover:bg-blue-600 transition duration-300"
//         >
//           Pay Now
//         </a>
//       </div>
//     </div>
//   );
// };

// export default TrainingDetailPage;

// const CourseHeader = ({ training }) => (
//   <div className="relative">
//     <img src={training.image} alt={training.title} className="w-full h-64 object-cover" />
//     <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//       <div className="text-center">
//         <h1 className="text-4xl font-bold text-white mb-2">{training.title}</h1>
//         <p className="text-xl text-gray-200">Industry: {training.industry}</p>
//       </div>
//     </div>
//   </div>
// );

// const CourseDetails = ({ training }) => (
//   <div>
//     <h2 className="text-2xl font-semibold mt-4 mb-2">Course Details</h2>
//     <p className="text-gray-700 mb-4">{training.description}</p>
//     <ul className="list-disc list-inside mb-4">
//       <li><strong>Duration:</strong> 10 weeks</li>
//       <li><strong>Format:</strong> Self-paced with weekly live sessions</li>
//       <li><strong>Prerequisites:</strong> None</li>
//       <li><strong>Certification:</strong> Certificate of completion provided</li>
//       <li><strong>Materials:</strong> Access to online resources and e-books</li>
//     </ul>
//   </div>
// );

// const CourseOutcome = () => (
//   <div>
//     <h2 className="text-2xl font-semibold mt-4 mb-2">Course Outcomes</h2>
//     <ul className="list-disc list-inside mb-4">
//       <li>Gain proficiency in industry-standard tools and techniques</li>
//       <li>Develop problem-solving skills through hands-on projects</li>
//       <li>Build a portfolio of work to showcase to potential employers</li>
//       <li>Network with industry professionals and peers</li>
//       <li>Earn a recognized certification to boost your resume</li>
//     </ul>
//   </div>
// );

// const CompaniesHiring = () => {
//   const companies = companyLogos.companyLogos;
//   return (
//     <div className="mt-8">
//       <h2 className="text-2xl font-semibold mb-4">Companies Hiring for These Skills</h2>
//       <Carousel
//         showStatus={false}
//         showThumbs={false}
//         showIndicators={false}
//         infiniteLoop={true}
//         autoPlay={true}
//         interval={3000}
//         showArrows={false}
//       >
//         {companies.reduce((acc, company, index) => {
//           if (index % 3 === 0) acc.push([]);
//           acc[acc.length - 1].push(company);
//           return acc;
//         }, []).map((group, groupIndex) => (
//           <div key={groupIndex} className="flex justify-center items-center">
//             {group.map((company, logoIndex) => (
//               <div key={logoIndex} className="flex justify-center items-center mx-4">
//                 <img src={company.logo} alt={company.name} className="h-16 object-contain" />
//               </div>
//             ))}
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// const FAQSection = () => (
//   <div className="mt-8">
//     <h2 className="text-2xl font-semibold mb-4">FAQs</h2>
//     <div className="space-y-4">
//       <div>
//         <h3 className="font-semibold">Q: What if I miss a live session?</h3>
//         <p>A: All sessions will be recorded and available for you to watch at your convenience.</p>
//       </div>
//       <div>
//         <h3 className="font-semibold">Q: Is there a refund policy?</h3>
//         <p>A: Yes, we offer a 7-day money-back guarantee if you're not satisfied with the course.</p>
//       </div>
//       <div>
//         <h3 className="font-semibold">Q: How long do I have access to the course materials?</h3>
//         <p>A: You'll have lifetime access to all course materials after purchase.</p>
//       </div>
//     </div>
//   </div>
// );

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import trainingData from '../data/trainingData.json';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import companyLogos from "../data/companyLogos.json";
import PaymentModal from '../payments/PaymentModal'; // Import the PaymentModal component

const TrainingDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Find the relevant certification or workshop based on the ID
  const allTrainings = Object.entries(trainingData.trainings).flatMap(([category, content]) => [
    ...content.certifications || [],
    ...content.workshops || []
  ]);

  const training = allTrainings.find((item) => item.id === parseInt(id));

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!training) {
    return <div className="text-center py-10">Training not found!</div>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-gray-100 min-h-screen relative">
      <button
        onClick={handleBack}
        className="absolute top-4 left-4 bg-blue-500 text-white py-2 px-4 rounded-full shadow-lg hover:bg-blue-600 transition duration-300"
      >
        Back
      </button>

      <CourseHeader training={training} />

      <div className="max-w-4xl mx-auto bg-white p-4 sm:p-8 rounded-lg shadow-lg mt-4">
        <CourseDetails training={training} />
        <CourseOutcome />
        <CompaniesHiring />
        <FAQSection />
      </div>

      <div
        className={`fixed flex flex-col ${
          isScrolled ? 'bottom-6 right-6' : 'bottom-6 left-1/2 transform -translate-x-1/2'
        } bg-white p-4 rounded-lg shadow-lg transition-all duration-300`}
      >
        <p className="text-xl font-bold mb-2">Course Fee: ₹{training.price}</p>
        <button
          onClick={() => setIsModalOpen(true)}
          className="block bg-custom-blue text-white justify-center items-center text-center py-2 px-4 rounded-full shadow-lg hover:bg-custome-gray hover:text-white transition duration-300"
        >
          Pay Now
        </button>
      </div>

      <PaymentModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} training={training} />
    </div>
  );
};

const CourseHeader = ({ training }) => (
  <div className="relative">
    <img src={training.image} alt={training.title} className="w-full h-64 object-cover" />
    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-white mb-2">{training.title}</h1>
        <p className="text-xl text-gray-200">Industry: {training.industry}</p>
      </div>
    </div>
  </div>
);

const CourseDetails = ({ training }) => (
  <div>
    <h2 className="text-2xl font-semibold mt-4 mb-2">Course Details</h2>
    <p className="text-gray-700 mb-4">{training.description}</p>
    <ul className="list-disc list-inside mb-4">
      <li><strong>Duration:</strong> 10 weeks</li>
      <li><strong>Format:</strong> Self-paced with weekly live sessions</li>
      <li><strong>Prerequisites:</strong> None</li>
      <li><strong>Certification:</strong> Certificate of completion provided</li>
      <li><strong>Materials:</strong> Access to online resources and e-books</li>
    </ul>
  </div>
);

const CourseOutcome = () => (
  <div>
    <h2 className="text-2xl font-semibold mt-4 mb-2">Course Outcomes</h2>
    <ul className="list-disc list-inside mb-4">
      <li>Gain proficiency in industry-standard tools and techniques</li>
      <li>Develop problem-solving skills through hands-on projects</li>
      <li>Build a portfolio of work to showcase to potential employers</li>
      <li>Network with industry professionals and peers</li>
      <li>Earn a recognized certification to boost your resume</li>
    </ul>
  </div>
);

const CompaniesHiring = () => {
  const companies = companyLogos.companyLogos;
  return (
    <div className="mt-8">
      <h2 className="text-2xl font-semibold mb-4">Companies Hiring for These Skills</h2>
      <Carousel
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
        showArrows={false}
      >
        {companies.reduce((acc, company, index) => {
          if (index % 3 === 0) acc.push([]);
          acc[acc.length - 1].push(company);
          return acc;
        }, []).map((group, groupIndex) => (
          <div key={groupIndex} className="flex justify-center items-center">
            {group.map((company, logoIndex) => (
              <div key={logoIndex} className="flex justify-center items-center mx-4">
                <img src={company.logo} alt={company.name} className="h-16 object-contain" />
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

const FAQSection = () => (
  <div className="mt-8">
    <h2 className="text-2xl font-semibold mb-4">FAQs</h2>
    <div className="space-y-4">
      <div>
        <h3 className="font-semibold">Q: What if I miss a live session?</h3>
        <p>A: All sessions will be recorded and available for you to watch at your convenience.</p>
      </div>
      <div>
        <h3 className="font-semibold">Q: Is there a refund policy?</h3>
        <p>A: Yes, we offer a 7-day money-back guarantee if you're not satisfied with the course.</p>
      </div>
      <div>
        <h3 className="font-semibold">Q: How long do I have access to the course materials?</h3>
        <p>A: You'll have lifetime access to all course materials after purchase.</p>
      </div>
    </div>
  </div>
);

export default TrainingDetailPage;
