import React, { useState } from 'react';
import { Users, DollarSign, Clock, Building2, BriefcaseIcon, GraduationCap, Settings } from 'lucide-react';
import EmployerProfile from './components/EmployerProfile';
import EmployerHiring from './components/EmployerHiring';
import EmployerPayments from './components/EmployerPayements';
import EmployerTrainings from './components/EmployerTrainings';
import EmployerWorkers from './components/EmployerWorkers';


const EmployerDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [isPostJobOpen, setIsPostJobOpen] = useState(false);

  const dashboardStats = {
    activeJobs: 8,
    totalHires: 45,
    pendingTraining: 12,
    totalSpent: 284500
  };

  const recentHiresData = [
    {
      id: 1,
      name: "John Smith",
      position: "Software Engineer",
      type: "Permanent",
      startDate: "2024-03-15",
      salary: 95000,
      status: "Training"
    },
    {
      id: 2,
      name: "Alice Johnson",
      position: "Project Manager",
      type: "Temporary",
      startDate: "2024-03-01",
      rate: 75,
      status: "Active"
    }
  ];

  const pendingTrainingsData = [
    {
      id: 1,
      employeeName: "John Smith",
      trainingType: "Technical Onboarding",
      startDate: "2024-04-05",
      duration: "2 weeks",
      status: "Pending"
    }
  ];

  const renderOverview = () => (
    <>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Active Jobs</p>
              <p className="text-2xl font-bold">{dashboardStats.activeJobs}</p>
            </div>
            <BriefcaseIcon className="h-8 w-8 text-blue-600" />
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Total Hires</p>
              <p className="text-2xl font-bold">{dashboardStats.totalHires}</p>
            </div>
            <Users className="h-8 w-8 text-green-600" />
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Pending Training</p>
              <p className="text-2xl font-bold">{dashboardStats.pendingTraining}</p>
            </div>
            <GraduationCap className="h-8 w-8 text-orange-600" />
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Total Spent</p>
              <p className="text-2xl font-bold">${dashboardStats.totalSpent.toLocaleString()}</p>
            </div>
            <DollarSign className="h-8 w-8 text-purple-600" />
          </div>
        </div>
      </div>

      <RecentHiresTable hires={recentHiresData} />
      <PendingTrainingsTable trainings={pendingTrainingsData} />
    </>
  );

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'hiring':
        return <EmployerHiring />;
      case 'workers':
        return <EmployerWorkers />;
      case 'training':
        return <EmployerTrainings />;
      case 'payments':
        return <EmployerPayments />;
      case 'profile':
        return <EmployerProfile />;
      default:
        return renderOverview();
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="flex min-h-screen">
        <div className="w-64 bg-white shadow-lg">
          <div className="p-4">
            <h2 className="text-xl font-bold mb-6">TechCorp Inc.</h2>
            <nav className="space-y-2">
              {[
                { id: 'overview', icon: Building2, label: 'Overview' },
                { id: 'hiring', icon: BriefcaseIcon, label: 'Hiring' },
                { id: 'workers', icon: Users, label: 'Workers' },
                { id: 'training', icon: GraduationCap, label: 'Training' },
                { id: 'payments', icon: DollarSign, label: 'Payments' },
                { id: 'profile', icon: Settings, label: 'Company Profile' }
              ].map((item) => (
                <button
                  key={item.id}
                  onClick={() => setActiveTab(item.id)}
                  className={`flex items-center space-x-2 w-full px-4 py-2 rounded-lg ${
                    activeTab === item.id ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <item.icon className="h-5 w-5" />
                  <span>{item.label}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>

        <div className="flex-1 overflow-auto">
          <div className="p-8">
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-2xl font-bold">
                {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
              </h1>
              {activeTab === 'overview' && (
                <button 
                  onClick={() => setIsPostJobOpen(true)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Create New Job Post
                </button>
              )}
            </div>
            {renderActiveTab()}
          </div>
        </div>
      </div>
    </div>
  );
};

const RecentHiresTable = ({ hires }) => (
  <div className="bg-white rounded-lg shadow mb-8">
    <div className="p-6 border-b">
      <h2 className="text-lg font-semibold">Recent Hires</h2>
    </div>
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Position</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Start Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Compensation</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {hires.map((hire) => (
            <tr key={hire.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{hire.name}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{hire.position}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{hire.type}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{hire.startDate}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className={`px-2 py-1 rounded-full text-xs ${
                  hire.status === 'Training' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'
                }`}>
                  {hire.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                {hire.salary ? `$${hire.salary.toLocaleString()}/yr` : `$${hire.rate}/hr`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const PendingTrainingsTable = ({ trainings }) => (
  <div className="bg-white rounded-lg shadow">
    <div className="p-6 border-b">
      <h2 className="text-lg font-semibold">Pending Training</h2>
    </div>
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Employee</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Training Type</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Start Date</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Duration</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {trainings.map((training) => (
            <tr key={training.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {training.employeeName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {training.trainingType}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {training.startDate}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {training.duration}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className="px-2 py-1 rounded-full text-xs bg-yellow-100 text-yellow-800">
                  {training.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export default EmployerDashboard;