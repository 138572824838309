import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import TrainingCard from '../components/TrainingCard';
import { trainingDataState } from '../recoil/atomTrainingData';

const TrainingAdmin = () => {
  const navigate = useNavigate();
  const [trainingData, setTrainingData] = useRecoilState(trainingDataState); // Use Recoil state for training data
  const [filter, setFilter] = useState('all');
  const [industryFilter, setIndustryFilter] = useState('all'); // State for industry filter

  // Fetch all trainings from the backend and store in Recoil state
  const fetchTrainings = async () => {
    const response = await fetch('/api/trainings'); // Adjust this to your API endpoint
    if (response.ok) {
      const data = await response.json();
      setTrainingData(data); // Save the fetched data in Recoil state
    } else {
      console.error('Failed to fetch trainings');
    }
  };

  useEffect(() => {
    fetchTrainings();
  }, []);

  // Function to filter by both type (trainings/workshops/all) and industry
  const filterTrainings = () => {
    let filteredData = trainingData;

    if (filter !== 'all') {
      filteredData = filteredData.filter(item =>
        filter === 'trainings' ? item.type === 'training' : item.type === 'workshop'
      );
    }

    if (industryFilter !== 'all') {
      filteredData = filteredData.filter(item => item.industry === industryFilter);
    }

    return filteredData;
  };

  const handleAddTraining = () => {
    navigate('/add-training'); // Redirect to Add page
  };

  const handleUpdateTraining = (training) => {
    navigate(`/update-training/${training.id}`, { state: { training } }); // Send training details via state
  };

  const handleDeleteTraining = async (id) => {
    const response = await fetch(`/api/trainings/${id}`, {
      method: 'DELETE',
    });
    if (response.ok) {
      setTrainingData(trainingData.filter(training => training.id !== id)); // Remove the deleted training from state
    } else {
      console.error('Failed to delete training');
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-10">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-6">Our Trainings and Workshops</h2>

        {/* Add Training Button */}
        <div className="flex justify-center mb-8">
          <button 
            className="mx-2 px-4 py-2 rounded-lg bg-green-500 text-white text-lg"
            onClick={handleAddTraining}
          >
            Add New Training
          </button>
        </div>

        {/* Display Trainings/Workshops */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {filterTrainings().map((training) => (
            <div key={training.id} className="bg-white shadow-md p-4 rounded-lg">
              <TrainingCard
                id={training.id}
                title={training.title}
                industry={training.industry}
                description={training.description}
                image={training.image}
              />
              <div className="flex justify-between mt-4">
                {/* Update Button */}
                <button 
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                  onClick={() => handleUpdateTraining(training)}
                >
                  Update
                </button>
                {/* Delete Button */}
                <button 
                  className="px-4 py-2 bg-red-500 text-white rounded-lg"
                  onClick={() => handleDeleteTraining(training.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrainingAdmin;
