import React from 'react';

const Unauthorized = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-red-600">Unauthorized Access</h1>
        <p className="mt-4 text-lg">
          You do not have permission to view this page.
        </p>
        <p className="mt-2">
          If you believe this is an error, please contact your administrator.
        </p>
        <a href="/" className="mt-6 inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
          Go Back to Home
        </a>
      </div>
    </div>
  );
};

export default Unauthorized;
