import React, { useState } from 'react';
import { 
  ChevronLeft, 
  ChevronRight, 
  Clock, 
  Users,
  Video,
  FileText
} from 'lucide-react';

export default function TrainerSchedule() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewType, setViewType] = useState('week');

  const timeSlots = [
    "9:00 AM", "10:00 AM", "11:00 AM", "12:00 PM",
    "1:00 PM", "2:00 PM", "3:00 PM", "4:00 PM",
    "5:00 PM", "6:00 PM", "7:00 PM", "8:00 PM"
  ];

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const schedule = [
    {
      id: 1,
      title: "AWS Developer Certification",
      module: "Cloud Architecture",
      time: "2:00 PM - 4:00 PM",
      students: 32,
      day: "Wed"
    },
    {
      id: 2,
      title: "React Advanced Patterns",
      module: "Custom Hooks",
      time: "10:00 AM - 12:00 PM",
      students: 28,
      day: "Thu"
    }
  ];

  const preparationTasks = [
    {
      id: 1,
      title: "Upload Course Materials",
      course: "React Advanced Patterns",
      deadline: "Due Today",
      type: "material"
    },
    {
      id: 2,
      title: "Review Assignments",
      course: "AWS Developer Certification",
      deadline: "Due Tomorrow",
      type: "review"
    }
  ];

  return (
    <div className="space-y-6">
      {/* Calendar Header */}
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="text-2xl font-semibold">Schedule</h2>
          <div className="flex bg-gray-100 rounded-lg p-1">
            <button
              onClick={() => setViewType('day')}
              className={`px-4 py-2 rounded-md ${
                viewType === 'day'
                  ? 'bg-white shadow text-blue-600'
                  : 'text-gray-600'
              }`}
            >
              Day
            </button>
            <button
              onClick={() => setViewType('week')}
              className={`px-4 py-2 rounded-md ${
                viewType === 'week'
                  ? 'bg-white shadow text-blue-600'
                  : 'text-gray-600'
              }`}
            >
              Week
            </button>
            <button
              onClick={() => setViewType('month')}
              className={`px-4 py-2 rounded-md ${
                viewType === 'month'
                  ? 'bg-white shadow text-blue-600'
                  : 'text-gray-600'
              }`}
            >
              Month
            </button>
          </div>
        </div>
        
        <div className="flex items-center gap-4">
          <button className="p-2 hover:bg-gray-100 rounded-full">
            <ChevronLeft className="w-5 h-5" />
          </button>
          <span className="font-medium">{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
          <button className="p-2 hover:bg-gray-100 rounded-full">
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Week View Calendar */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <div className="grid grid-cols-8 gap-4">
            {/* Time column */}
            <div className="space-y-6 pt-16">
              {timeSlots.map(time => (
                <div key={time} className="text-sm text-gray-500">{time}</div>
              ))}
            </div>

            {/* Day columns */}
            {weekDays.map(day => (
              <div key={day} className="relative">
                <div className="text-center pb-4 border-b">
                  <p className="font-medium">{day}</p>
                  <p className="text-sm text-gray-500">Oct 24</p>
                </div>

                {/* Time slots */}
                <div className="space-y-6 relative">
                  {timeSlots.map((time, index) => (
                    <div
                      key={`${day}-${time}`}
                      className="h-6 border-t border-gray-100"
                    />
                  ))}

                  {/* Scheduled sessions */}
                  {schedule.map(session => session.day === day && (
                    <div
                      key={session.id}
                      className="absolute top-24 left-0 right-0 bg-blue-100 border-l-4 border-blue-500 p-2 rounded-r"
                      style={{ height: "120px" }}
                    >
                      <p className="text-sm font-medium text-blue-600">
                        {session.title}
                      </p>
                      <p className="text-xs text-blue-500">{session.time}</p>
                      <div className="flex items-center gap-1 mt-2">
                        <Users className="w-3 h-3" />
                        <span className="text-xs">{session.students} Students</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Today's Schedule & Tasks */}
      <div className="grid grid-cols-2 gap-6">
        {/* Today's Sessions */}
        <div className="bg-white rounded-lg shadow">
          <div className="p-6">
            <h3 className="text-lg font-semibold mb-4">Today's Sessions</h3>
            <div className="space-y-4">
              {schedule.map(session => (
                <div key={session.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div>
                    <p className="font-medium">{session.title}</p>
                    <p className="text-sm text-gray-600">{session.module}</p>
                    <div className="flex items-center gap-2 mt-2 text-sm text-gray-600">
                      <Clock className="w-4 h-4" />
                      <span>{session.time}</span>
                    </div>
                  </div>
                  <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                    Start Session
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Preparation Tasks */}
        <div className="bg-white rounded-lg shadow">
          <div className="p-6">
            <h3 className="text-lg font-semibold mb-4">Preparation Tasks</h3>
            <div className="space-y-4">
              {preparationTasks.map(task => (
                <div key={task.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center gap-3">
                    {task.type === 'material' ? (
                      <Video className="w-5 h-5 text-blue-500" />
                    ) : (
                      <FileText className="w-5 h-5 text-orange-500" />
                    )}
                    <div>
                      <p className="font-medium">{task.title}</p>
                      <p className="text-sm text-gray-600">{task.course}</p>
                    </div>
                  </div>
                  <span className="text-orange-500 text-sm">{task.deadline}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}