// import React, { useEffect, useState } from 'react';

// const AddUpdateTraining = ({ mode, trainingId }) => {
//   const [trainingData, setTrainingData] = useState({
//     courseName: '',
//     industry: '',
//     description: '',
//     courseContent: [],
//     pricing: '',
//     trainerDetails: '',
//     courseOutcome: '',
//     courseBenefits: [],
//     companiesHiring: [],
//     courseFAQs: [],
//   });
  
//   const [file, setFile] = useState(null);
//   const [imageUrl, setImageUrl] = useState('');
//   const apiUrl = process.env.REACT_APP_API_URL;

//   useEffect(() => {
//     // Fetch existing training data for updating
//     if (mode === 'update' && trainingId) {
//       const fetchTrainingData = async () => {
//         const response = await fetch(`${apiUrl}/api/trainings/${trainingId}`);
//         if (response.ok) {
//           const data = await response.json();
//           setTrainingData(data);
//           setImageUrl(data.imageUrl); // Set image URL if available
//         } else {
//           console.error('Failed to fetch training data');
//         }
//       };
//       fetchTrainingData();
//     }
//   }, [mode, trainingId, apiUrl]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === 'courseContent' || name === 'courseBenefits' || name === 'companiesHiring' || name === 'courseFAQs') {
//       const values = value.split(',').map(item => item.trim());
//       setTrainingData({ ...trainingData, [name]: values });
//     } else {
//       setTrainingData({ ...trainingData, [name]: value });
//     }
//   };

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const fetchPresignedUrl = async (fileName, fileType) => {
//     const response = await fetch(`${apiUrl}/api/trainings/presigned-url?fileName=${encodeURIComponent(fileName)}&fileType=${encodeURIComponent(fileType)}`);
//     if (!response.ok) {
//       throw new Error('Failed to get pre-signed URL');
//     }
//     const { url } = await response.json();
//     return url;
//   };

//   const uploadFile = async (file) => {
//     const uploadUrl = await fetchPresignedUrl(file.name, file.type);
//     const uploadResponse = await fetch(uploadUrl, {
//       method: 'PUT',
//       body: file,
//       headers: {
//         'Content-Type': file.type,
//       },
//     });

//     if (uploadResponse.ok) {
//       console.log('File uploaded successfully');
//       setImageUrl(uploadUrl.split('?')[0]); // Get the URL of the uploaded file
//       return uploadUrl.split('?')[0];
//     } else {
//       console.error('File upload failed');
//       throw new Error('File upload failed');
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       if (file) {
//         await uploadFile(file); // Upload the file and get the image URL
//       }
//       const response = await fetch(mode === 'update' ? `${apiUrl}/api/trainings/${trainingId}` : `${apiUrl}/api/trainings`, {
//         method: mode === 'update' ? 'PUT' : 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ ...trainingData, imageUrl }), // Include the image URL in the training data
//       });

//       if (response.ok) {
//         console.log(mode === 'update' ? 'Training updated successfully' : 'Training created successfully');
//         // Optionally reset the form or show a success message
//       } else {
//         console.error('Failed to submit training data');
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <h2>{mode === 'update' ? 'Update Training' : 'Add Training'}</h2>
//       <div>
//         <label>Course Name:</label>
//         <input type="text" name="courseName" value={trainingData.courseName} onChange={handleChange} required />
//       </div>
//       <div>
//         <label>Industry:</label>
//         <input type="text" name="industry" value={trainingData.industry} onChange={handleChange} required />
//       </div>
//       <div>
//         <label>Description:</label>
//         <textarea name="description" value={trainingData.description} onChange={handleChange} />
//       </div>
//       <div>
//         <label>Course Content (comma-separated):</label>
//         <input type="text" name="courseContent" value={trainingData.courseContent.join(', ')} onChange={handleChange} />
//       </div>
//       <div>
//         <label>Pricing:</label>
//         <input type="number" name="pricing" value={trainingData.pricing} onChange={handleChange} required />
//       </div>
//       <div>
//         <label>Trainer Details:</label>
//         <input type="text" name="trainerDetails" value={trainingData.trainerDetails} onChange={handleChange} />
//       </div>
//       <div>
//         <label>Course Outcome:</label>
//         <input type="text" name="courseOutcome" value={trainingData.courseOutcome} onChange={handleChange} />
//       </div>
//       <div>
//         <label>Course Benefits (comma-separated):</label>
//         <input type="text" name="courseBenefits" value={trainingData.courseBenefits.join(', ')} onChange={handleChange} />
//       </div>
//       <div>
//         <label>Companies Hiring (comma-separated):</label>
//         <input type="text" name="companiesHiring" value={trainingData.companiesHiring.join(', ')} onChange={handleChange} />
//       </div>
//       <div>
//         <label>Course FAQs (comma-separated):</label>
//         <input type="text" name="courseFAQs" value={trainingData.courseFAQs.join(', ')} onChange={handleChange} />
//       </div>
//       <div>
//         <label>Upload Image:</label>
//         <input type="file" onChange={handleFileChange} accept="image/*" />
//       </div>
//       <button type="submit">{mode === 'update' ? 'Update Training' : 'Add Training'}</button>
//     </form>
//   );
// };

// export default AddUpdateTraining;

import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate, useLocation } from 'react-router-dom';
import { trainingDataState } from '../recoil/atomTrainingData';

const AddUpdateTraining = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [trainingData, setTrainingData] = useRecoilState(trainingDataState);
  const apiUrl = process.env.REACT_APP_API_URL;

  const isUpdateMode = location.pathname.includes('update-training');
  const existingTraining = location.state?.training || {};

  const [formData, setFormData] = useState({
    title: existingTraining.title || '',
    industry: existingTraining.industry || '',
    description: existingTraining.description || '',
    imageFile: null, // Change this to handle file uploads
    imageUrl: existingTraining.image || '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, imageFile: e.target.files[0] });
  };

  const fetchPresignedUrl = async (fileName, fileType) => {
    const response = await fetch(`${apiUrl}/app3/api/trainings/presigned-url?fileName=${encodeURIComponent(fileName)}&fileType=${encodeURIComponent(fileType)}`);
    if (!response.ok) {
      throw new Error('Failed to get pre-signed URL');
    }
    const { url } = await response.json();
    return url;
  };

  const uploadImageToS3 = async (file) => {
    const presignedUrl = await fetchPresignedUrl(file.name, file.type);
    const uploadResponse = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });

    if (!uploadResponse.ok) {
      throw new Error('Image upload failed');
    }

    return presignedUrl.split('?')[0]; // Return the URL of the uploaded image
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.imageFile) {
        const imageUrl = await uploadImageToS3(formData.imageFile);
        formData.imageUrl = imageUrl; // Store the S3 URL in formData
      }

      const apiEndpoint = isUpdateMode
        ? `/api/trainings/${existingTraining.id}`
        : '/api/trainings';

      const method = isUpdateMode ? 'PUT' : 'POST';

      const response = await fetch(apiEndpoint, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: formData.title,
          industry: formData.industry,
          description: formData.description,
          image: formData.imageUrl, // Use the imageUrl from S3
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (isUpdateMode) {
          setTrainingData(prevData =>
            prevData.map(item => (item.id === existingTraining.id ? data : item))
          );
        } else {
          setTrainingData([...trainingData, data]);
        }
        navigate('/trainings');
      } else {
        console.error('Failed to submit training');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-10">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-6">
          {isUpdateMode ? 'Update Training' : 'Add New Training'}
        </h2>

        <form onSubmit={handleSubmit}>
          {/* Training Title */}
          <div className="mb-6">
            <label className="block mb-2 text-lg">Training Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
              required
            />
          </div>

          {/* Industry */}
          <div className="mb-6">
            <label className="block mb-2 text-lg">Industry</label>
            <input
              type="text"
              name="industry"
              value={formData.industry}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
              required
            />
          </div>

          {/* Description */}
          <div className="mb-6">
            <label className="block mb-2 text-lg">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
              required
            />
          </div>

          {/* Image Upload */}
          <div className="mb-6">
            <label className="block mb-2 text-lg">Upload Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full p-2 border border-gray-300 rounded-lg"
              required={!isUpdateMode} // Require image upload if adding a new training
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded-lg"
            >
              {isUpdateMode ? 'Update Training' : 'Add Training'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUpdateTraining;
