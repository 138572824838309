
import { Clock, Building2, BookOpen, Star } from 'lucide-react';

const CertificationCard = ({ certification, onViewDetails }) => {
    return (
      <div className="bg-white border rounded-lg hover:shadow-lg transition-shadow">
        <div className="p-4">
          <div className="flex justify-between items-start mb-3">
            <div>
              <h3 className="font-medium text-xl flex items-center gap-2">
                {certification.title}
                {certification.isPopular && (
                  <span className="px-2 py-1 bg-yellow-100 text-yellow-800 text-xs rounded-full">
                    Popular
                  </span>
                )}
              </h3>
              <p className="text-sm text-gray-600 mt-1">{certification.description}</p>
            </div>
            <button 
              onClick={() => onViewDetails(certification)}
              className="p-2 rounded-full text-gray-400 hover:bg-gray-100"
            >
              <BookOpen className="w-5 h-5" />
            </button>
          </div>
  
          <div className="grid grid-cols-2 gap-4 my-4">
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4 text-blue-600" />
              <div>
                <p className="text-sm text-gray-600">Duration</p>
                <p className="font-medium">{certification.duration}</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Building2 className="w-4 h-4 text-blue-600" />
              <div>
                <p className="text-sm text-gray-600">Level</p>
                <p className="font-medium">{certification.level}</p>
              </div>
            </div>
          </div>
  
          <div className="flex flex-wrap gap-2 my-4">
            {certification.skills.slice(0, 3).map((skill, idx) => (
              <span key={idx} className="px-2 py-1 bg-blue-50 text-blue-700 text-sm rounded-full">
                {skill}
              </span>
            ))}
            {certification.skills.length > 3 && (
              <span className="px-2 py-1 bg-gray-50 text-gray-600 text-sm rounded-full">
                +{certification.skills.length - 3} more
              </span>
            )}
          </div>
  
          <div className="flex items-center justify-between mt-4 pt-4 border-t">
            <div className="flex items-center gap-4">
              <div>
                <p className="text-sm text-gray-600">Next Batch</p>
                <p className="font-medium">{certification.nextBatch}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Success Rate</p>
                <div className="flex items-center gap-1">
                  <Star className="w-4 h-4 text-yellow-400 fill-current" />
                  <span className="font-medium">{certification.successRate}</span>
                </div>
              </div>
            </div>
            <button
              onClick={() => onViewDetails(certification)}
              className="px-4 py-2 bg-custom-blue text-white rounded-full hover:bg-blue-700"
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    );
  };

export default CertificationCard
  