import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import useWindowSize from '../hooks/useWindowSize.jsx';

const IndustryList = () => {
    const { cards } = useWindowSize(); // Dynamically gets the number of cards based on screen size
    const industries = [
        {
            name: "Technology",
            description: "Innovative solutions driving the digital revolution.",
            imageUrl: "https://talentfirst-website-data.s3.ap-south-1.amazonaws.com/industies/technology.png", 
        },
        {
            name: "Manufacturing",
            description: "Streamlining production processes for efficiency.",
            imageUrl: "https://talentfirst-website-data.s3.ap-south-1.amazonaws.com/industies/manufacturing.png",
        },
        {
            name: "Healthcare",
            description: "Providing quality care and medical expertise.",
            imageUrl: "https://talentfirst-website-data.s3.ap-south-1.amazonaws.com/industies/healthcare.png",
        },
        {
            name: "Hospitality and Tourism",
            description: "Creating memorable experiences for guests.",
            imageUrl: "https://talentfirst-website-data.s3.ap-south-1.amazonaws.com/industies/hospitality.png",
        },
        {
            name: "Construction",
            description: "Building the future with precision and safety.",
            imageUrl: "https://talentfirst-website-data.s3.ap-south-1.amazonaws.com/industies/construction.png",
        },
        {
            name: "Maritime",
            description: "Navigating the waters with skilled professionals.",
            imageUrl: "https://talentfirst-website-data.s3.ap-south-1.amazonaws.com/industies/maritime.png",
        },
        {
            name: "Logistics and Warehousing",
            description: "Ensuring timely delivery and efficient storage.",
            imageUrl: "https://talentfirst-website-data.s3.ap-south-1.amazonaws.com/industies/logistics.png",
        },
        {
            name: "Oil and Gas",
            description: "Powering industries with reliable energy sources.",
            imageUrl: "https://talentfirst-website-data.s3.ap-south-1.amazonaws.com/industies/oilgas.png",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % industries.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [industries.length]);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % industries.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + industries.length) % industries.length);
    };

    return (
        <div className="w-full mx-auto p-10 bg-gray-300 relative">
            <h2 className="text-2xl sm:text-3xl text-gray-800 font-bold text-center">Industries We Serve</h2>
            <h2 className='sm:text-xl font-regular text-xl text-custome-gray text-center p-5 mb-6'>
                Unlocking your business potential across industries. TalentFirst is your dedicated partner in sourcing and placing top talent.
                With our expertise extending seamlessly across diverse sectors, we're here to fulfill your staffing requirements.
            </h2>
            
            <div className="relative w-full">
                {/* Left Arrow */}
                <button 
                    onClick={handlePrev} 
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 p-2 rounded-full z-10"
                >
                    <ChevronLeft size={24} />
                </button>

                {/* Right Arrow */}
                <button 
                    onClick={handleNext} 
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 p-2 rounded-full z-10"
                >
                    <ChevronRight size={24} />
                </button>

                <div className="overflow-hidden">
                    <div 
                        className="flex transition-transform duration-300 ease-in-out"
                        style={{ transform: `translateX(-${(currentIndex * 100) / cards}%)` }}
                    >
                        {industries.concat(industries.slice(0, cards)).map((industry, index) => (
                            <div 
                                key={index} 
                                className={`flex-shrink-0 px-2`} 
                                style={{ width: `${100 / cards}%` }} // Dynamically calculate card width
                            >
                                <div className="bg-white rounded shadow h-80 flex flex-col items-center justify-between">
                                    <div 
                                        className="w-full h-1/2 bg-cover bg-center rounded-t"
                                        style={{ backgroundImage: `url(${industry.imageUrl})` }}
                                    />
                                    <div className="p-4 h-1/2 flex flex-col justify-start items-center text-center">
                                        <p className="font-semibold text-xl p-3">{industry.name}</p>
                                        <p className="text-gray-800 text-md ">{industry.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndustryList;
