import React, { useState } from 'react';
import { DollarSign, Calendar, FileText, TrendingUp, Filter, Download, Search, ArrowDown } from 'lucide-react';

const EmployerPayments = () => {
  const [invoiceType, setInvoiceType] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  const invoices = [
    {
      id: 1,
      companyName: "TechCorp Inc.",
      workerType: "Permanent",
      totalAmount: 15000,
      issuedDate: "2024-03-30",
      dueDate: "2024-04-30",
      status: "Paid",
      invoiceNumber: "INV-2024-001",
      workers: [
        { name: "John Smith", role: "Software Engineer", amount: 5000 },
        { name: "Alice Brown", role: "Product Manager", amount: 5000 },
        { name: "Mike Wilson", role: "DevOps Engineer", amount: 5000 }
      ]
    },
    {
      id: 2,
      companyName: "Global Solutions",
      workerType: "Temporary",
      totalAmount: 24000,
      issuedDate: "2024-03-30",
      dueDate: "2024-04-30",
      status: "Pending",
      invoiceNumber: "INV-2024-002",
      workers: [
        { name: "Sarah Davis", role: "Project Manager", amount: 8000 },
        { name: "Tom Harris", role: "Business Analyst", amount: 8000 },
        { name: "Emma Clark", role: "UX Designer", amount: 8000 }
      ]
    }
  ];

  const filteredInvoices = invoiceType === 'all' 
    ? invoices 
    : invoices.filter(i => i.workerType.toLowerCase() === invoiceType);

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Company Invoices</h1>
        <div className="flex gap-2">
          <button className="flex items-center px-4 py-2 border text-gray-700 rounded-lg hover:bg-gray-50">
            <Download className="h-4 w-4 mr-2" />
            Download Statement
          </button>
          <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
            <FileText className="h-4 w-4 mr-2" />
            View All Invoices
          </button>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Total Invoiced</span>
            <DollarSign className="h-5 w-5 text-blue-600" />
          </div>
          <div className="text-2xl font-bold">$485.2k</div>
          <div className="text-sm text-gray-500">This month</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Pending Collection</span>
            <Calendar className="h-5 w-5 text-yellow-600" />
          </div>
          <div className="text-2xl font-bold">$78.5k</div>
          <div className="text-sm text-gray-500">12 invoices</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Permanent Placements</span>
            <FileText className="h-5 w-5 text-green-600" />
          </div>
          <div className="text-2xl font-bold">$320.8k</div>
          <div className="text-sm text-gray-500">24 workers</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Temporary Placements</span>
            <TrendingUp className="h-5 w-5 text-purple-600" />
          </div>
          <div className="text-2xl font-bold">$164.4k</div>
          <div className="text-sm text-gray-500">18 workers</div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6">
        <div className="flex space-x-2">
          {['all', 'permanent', 'temporary'].map((type) => (
            <button
              key={type}
              onClick={() => setInvoiceType(type)}
              className={`px-3 py-1 rounded-full text-sm ${
                invoiceType === type 
                  ? 'bg-blue-100 text-blue-600' 
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </button>
          ))}
        </div>

        <div className="flex space-x-4">
          <div className="relative">
            <Search className="h-5 w-5 absolute left-3 top-2.5 text-gray-400" />
            <input
              type="text"
              placeholder="Search invoices..."
              className="pl-10 pr-4 py-2 border rounded-lg w-64"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button className="flex items-center px-4 py-2 border rounded-lg">
            <Filter className="h-4 w-4 mr-2" />
            Filters
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Invoice #</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Company</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Workers</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Issued Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Due Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Amount</th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredInvoices.map((invoice) => (
              <React.Fragment key={invoice.id}>
                <tr className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm text-gray-500">{invoice.invoiceNumber}</td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">{invoice.companyName}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{invoice.workerType}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{invoice.workers.length}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{invoice.issuedDate}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{invoice.dueDate}</td>
                  <td className="px-6 py-4 text-sm">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      invoice.status === 'Paid' 
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {invoice.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500 text-right">
                    ${invoice.totalAmount.toLocaleString()}
                  </td>
                  <td className="px-6 py-4 text-sm text-center">
                    <button className="text-blue-600 hover:text-blue-800">
                      <Download className="h-4 w-4" />
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployerPayments;