
import React, { useState } from 'react';
import trainingData from '../data/trainingData.json';
import TrainingCard from '../components/TrainingCard';

const Trainings = () => {
  const [filter, setFilter] = useState('all');
  const [industryFilter, setIndustryFilter] = useState('all'); // New state for industry filter

  // Function to filter by both type (trainings/workshops/all) and industry
  const filterTrainings = () => {
    let filteredData = Object.entries(trainingData.trainings);

    if (filter !== 'all') {
      filteredData = filteredData.map(([category, content]) => {
        const filteredContent = filter === 'trainings' ? { certifications: content.certifications } : { workshops: content.workshops };
        return [category, filteredContent];
      });
    }

    if (industryFilter !== 'all') {
      filteredData = filteredData.map(([category, content]) => {
        const certifications = content.certifications?.filter(cert => cert.industry === industryFilter) || [];
        const workshops = content.workshops?.filter(workshop => workshop.industry === industryFilter) || [];
        return [category, { certifications, workshops }];
      });
    }

    return filteredData;
  };



  return (
    <div className="bg-gray-100 min-h-screen py-10">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-6">Our Trainings and Workshops</h2>

        {/* Toggle Buttons */}
        <div className="flex justify-center mb-8">
          <button 
            className={`mx-2 px-4 py-2 rounded-lg text-lg ${filter === 'all' ? 'bg-custom-blue text-white' : 'bg-gray-200'}`} 
            onClick={() => setFilter('all')}
          >
            All
          </button>
          <button 
            className={`mx-2 px-4 py-2 rounded-lg text-lg ${filter === 'trainings' ? 'bg-custom-blue text-white' : 'bg-gray-200'}`} 
            onClick={() => setFilter('trainings')}
          >
            Trainings
          </button>
          <button 
            className={`mx-2 px-4 py-2 rounded-lg text-lg ${filter === 'workshops' ? 'bg-custom-blue text-white' : 'bg-gray-200'}`} 
            onClick={() => setFilter('workshops')}
          >
            Workshops
          </button>
        </div>

        {/* Industry Filter Dropdown */}
        <div className="flex justify-center mb-8">
          <select
            className="px-4 py-2 rounded-lg bg-white shadow-md focus:outline-none focus:ring-0 border"
            value={industryFilter}
            onChange={(e) => setIndustryFilter(e.target.value)}
          >
            <option value="all">All Industries</option>
            <option value="Information Technology">Information Technology</option>
            <option value="Data Engineering & Analytics">Data Engineering & Analytics</option>
            <option value="Mechanical Engineering">Mechanical Engineering</option>
          </select>
        </div>

        {/* Display Trainings/Workshops */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {filterTrainings().map(([category, content]) => (
            <>
              {content.certifications && content.certifications.map((cert) => (
                <TrainingCard
                  key={cert.id}
                  id={cert.id}
                  title={cert.title}
                  industry={cert.industry}
                  description={cert.description}
                  image={cert.image}
                />
              ))}
              {content.workshops && content.workshops.map((workshop) => (
                <TrainingCard
                  key={workshop.id}
                  id={workshop.id}
                  title={workshop.title}
                  industry={workshop.industry}
                  description={workshop.description}
                  image={workshop.image}
                />
              ))}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trainings;
