import React from 'react';
import { Outlet } from 'react-router-dom';

const UniversityLayout = () => (
  <div>
    <h1>Admin Dashboard</h1>
    {/* Add Admin-specific Header or Navigation if needed */}
    <Outlet />
  </div>
);

export default UniversityLayout;
