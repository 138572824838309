import React from 'react';
import { Link } from 'react-router-dom';
import { FaHandshake, FaGlobe, FaUsers, FaRocket } from 'react-icons/fa';



const AboutUsBrief = () => {
    const cards = [
        { icon: FaHandshake, title: "Partnerships", description: "Building lasting relationships for mutual growth." },
        { icon: FaGlobe, title: "Global Reach", description: "Connecting talent worldwide, breaking boundaries." },
        { icon: FaUsers, title: "People First", description: "Prioritizing needs of clients and candidates." },
        { icon: FaRocket, title: "Innovation Driven", description: "Leveraging cutting-edge recruitment technologies." },
    ];

    return (
        <div className="bg-custom-blue text-white py-16 xs:px-0 px-4 xm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="text-center mb-12">
                    <h2 className="text-3xl sm:text-4xl font-extrabold mb-4">Who We Are</h2>
                    <p className="text-xl max-w-3xl mx-auto">
                        TalentFirst connects talent with global opportunities,
                        empowering businesses and individuals through exceptional recruitment services.
                    </p>
                </div>

                <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
                    {cards.map((card, index) => (
                        <div key={index} className="bg-white  rounded-xl p-6 flex flex-col items-center transform hover:scale-105 transition-all duration-300">
                            <card.icon className="text-4xl mb-4 text-custom-blue" />
                            <h3 className="text-xl text-custom-blue font-semibold mb-2">{card.title}</h3>
                            <p className="text-center text-custom-blue text-sm">{card.description}</p>
                        </div>
                    ))}
                </div>

                <div className="text-center">
                    <p className="mb-8 max-w-3xl mx-auto">
                        Established in 2024, we've quickly become a trusted partner for companies
                        and professionals worldwide. Our commitment to integrity, excellence, and
                        collaboration helps you achieve your goals and unlock your full potential.
                    </p>
                    <Link to="/about" className="inline-block bg-white text-custom-blue font-semibold px-8 py-3 rounded-full shadow-lg hover:bg-blue-100 transition-colors duration-300">
                        Learn More About Us
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AboutUsBrief;
