import React from 'react';
import { MapPin, Globe, Building2 } from 'lucide-react';

const JobLocationSection = ({ formData, handleChange }) => {
  return (
    <div className="space-y-6">
      {/* Primary Location */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Building2 className="h-5 w-5 mr-2 text-blue-600" />
          Primary Location
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              City <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.location.city}
              onChange={(e) => handleChange('location', 'city', e.target.value)}
              placeholder="e.g., San Francisco"
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              State/Province <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.location.state}
              onChange={(e) => handleChange('location', 'state', e.target.value)}
              placeholder="e.g., California"
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Country <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.location.country}
              onChange={(e) => handleChange('location', 'country', e.target.value)}
              placeholder="e.g., United States"
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      {/* Remote Work Options */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Globe className="h-5 w-5 mr-2 text-blue-600" />
          Remote Work Details
        </h3>
        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="remoteWork"
              checked={formData.location.isRemoteAllowed}
              onChange={(e) => handleChange('location', 'isRemoteAllowed', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label htmlFor="remoteWork" className="text-sm font-medium text-gray-700">
              Remote work is available for this position
            </label>
          </div>

          {formData.location.isRemoteAllowed && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Remote Work Type
                </label>
                <select
                  value={formData.location.remoteType}
                  onChange={(e) => handleChange('location', 'remoteType', e.target.value)}
                  className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                >
                  <option value="">Select Type</option>
                  <option value="fully_remote">Fully Remote</option>
                  <option value="hybrid">Hybrid (Mixed)</option>
                  <option value="temporary_remote">Temporarily Remote</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Time Zone Requirements
                </label>
                <input
                  type="text"
                  value={formData.location.timeZoneRequirements}
                  onChange={(e) => handleChange('location', 'timeZoneRequirements', e.target.value)}
                  placeholder="e.g., GMT-8 to GMT-5"
                  className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Geographic Restrictions
                </label>
                <input
                  type="text"
                  value={formData.location.geographicRestrictions}
                  onChange={(e) => handleChange('location', 'geographicRestrictions', e.target.value)}
                  placeholder="e.g., US Only, North America"
                  className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Required In-Office Days
                </label>
                <input
                  type="text"
                  value={formData.location.requiredOfficeDays}
                  onChange={(e) => handleChange('location', 'requiredOfficeDays', e.target.value)}
                  placeholder="e.g., 2 days per week"
                  className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Relocation Assistance */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <MapPin className="h-5 w-5 mr-2 text-blue-600" />
          Relocation Support
        </h3>
        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="relocationAssistance"
              checked={formData.location.providesRelocation}
              onChange={(e) => handleChange('location', 'providesRelocation', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label htmlFor="relocationAssistance" className="text-sm font-medium text-gray-700">
              Relocation assistance is provided
            </label>
          </div>

          {formData.location.providesRelocation && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Relocation Package Details
                </label>
                <textarea
                  value={formData.location.relocationDetails}
                  onChange={(e) => handleChange('location', 'relocationDetails', e.target.value)}
                  placeholder="Describe the relocation package and support offered..."
                  rows={3}
                  className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Eligible Locations
                </label>
                <input
                  type="text"
                  value={formData.location.relocationEligibility}
                  onChange={(e) => handleChange('location', 'relocationEligibility', e.target.value)}
                  placeholder="e.g., International, Domestic only"
                  className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobLocationSection;