import React, { useState } from 'react';
import { BarChart2, Download, DollarSign, Calendar, ChevronRight } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function TrainerEarnings() {
  const [timeframe, setTimeframe] = useState('month');

  const earningsData = [
    { name: 'Jan', earnings: 3200 },
    { name: 'Feb', earnings: 3800 },
    { name: 'Mar', earnings: 4200 },
    { name: 'Apr', earnings: 3900 },
    { name: 'May', earnings: 4600 },
    { name: 'Jun', earnings: 5100 }
  ];

  const courseEarnings = [
    {
      id: 1,
      title: 'AWS Developer Certification',
      students: 32,
      duration: '12 weeks',
      amount: 2400
    },
    {
      id: 2,
      title: 'React Advanced Patterns',
      students: 28,
      duration: '8 weeks',
      amount: 1850
    },
    {
      id: 3,
      title: 'Python for Data Science',
      students: 25,
      duration: '10 weeks',
      amount: 1600
    }
  ];

  const paymentHistory = [
    {
      id: 1,
      month: 'October',
      date: 'Oct 1, 2024',
      amount: 4850
    },
    {
      id: 2,
      month: 'September',
      date: 'Sep 1, 2024',
      amount: 4200
    },
    {
      id: 3,
      month: 'August',
      date: 'Aug 1, 2024',
      amount: 3950
    }
  ];

  return (
    <div className="space-y-6">
      {/* Earnings Overview */}
      <div className="grid grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold">This Month</h3>
            <DollarSign className="w-6 h-6 text-green-500" />
          </div>
          <p className="text-3xl font-bold">$4,850</p>
          <p className="text-sm text-green-500 mt-2">+12% from last month</p>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold">Pending</h3>
            <Calendar className="w-6 h-6 text-blue-500" />
          </div>
          <p className="text-3xl font-bold">$2,150</p>
          <p className="text-sm text-gray-600 mt-2">Due on Nov 1, 2024</p>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold">Total 2024</h3>
            <BarChart2 className="w-6 h-6 text-purple-500" />
          </div>
          <p className="text-3xl font-bold">$24,650</p>
          <p className="text-sm text-gray-600 mt-2">Year to date</p>
        </div>
      </div>

      {/* Earnings Chart */}
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-semibold">Earnings Overview</h3>
          <div className="flex items-center gap-4">
            <select 
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
              className="p-2 border rounded-lg"
            >
              <option value="week">This Week</option>
              <option value="month">This Month</option>
              <option value="year">This Year</option>
            </select>
            <button className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50">
              <Download className="w-4 h-4" />
              Export
            </button>
          </div>
        </div>
        
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={earningsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line 
                type="monotone" 
                dataKey="earnings" 
                stroke="#2563eb" 
                strokeWidth={2} 
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Course Earnings */}
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-semibold mb-4">Course Earnings</h3>
        <div className="space-y-4">
          {courseEarnings.map(course => (
            <div key={course.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <p className="font-medium">{course.title}</p>
                <p className="text-sm text-gray-600">
                  {course.students} students • {course.duration}
                </p>
              </div>
              <p className="text-lg font-semibold">${course.amount}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Payment History */}
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Payment History</h3>
          <button className="text-blue-500 hover:text-blue-600">View All</button>
        </div>
        <div className="space-y-4">
          {paymentHistory.map(payment => (
            <div key={payment.id} className="flex items-center justify-between p-4 border-b">
              <div>
                <p className="font-medium">{payment.month} Payout</p>
                <p className="text-sm text-gray-600">{payment.date}</p>
              </div>
              <p className="text-lg font-semibold">${payment.amount}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}