import React, { useState } from 'react';
import { Search, BookOpen, Clock, Briefcase, Upload, Globe, ChevronRight } from 'lucide-react';
import CareerTransitionSearch from './CareerTransitionSearch';
import { useNavigate } from 'react-router-dom';
import { countryDemandData, jobTransitions, transitionPlansData } from './transitionData';

const CareerTransition = () => {
  const searchRef = React.useRef(null);
  const [selectedSkill, setSelectedSkill] = React.useState(null);
  const [resumeData, setResumeData] = React.useState(null);
  const [selectedCountry, setSelectedCountry] = React.useState('United States');
  const [isSearchFocused, setIsSearchFocused] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showPreview, setShowPreview] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [recentSearches, setRecentSearches] = React.useState([
    'Marketing Manager',
    'Software Developer',
    'Data Entry Specialist'
  ]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  const navigate = useNavigate();

  const handleViewTransitionPlan = (transition) => {
    // Get the matching transition plan data based on the job
    const planData = transitionPlansData[transition.toJob];
    
    // Navigate with both the transition and plan data
    navigate('/view-career-transition', {
      state: {
        transition: transition,
        planData: planData
      }
    });
  };

    const getDemandColor = (demand) => {
    const colors = {
      'Very High': 'bg-green-500',
      'High': 'bg-blue-500',
      'Medium': 'bg-yellow-500',
      'Low': 'bg-red-500'
    };
    return colors[demand] || 'bg-gray-500';
  };

  const handleResumeUpload = (file) => {
    setIsUploading(true);
    setShowPreview(true);
    
    setTimeout(() => {
      setResumeData({
        currentSkills: ['Customer Service', 'Microsoft Office', 'Team Management'],
        experience: [
          {
            title: 'Customer Service Manager',
            years: 3,
            skills: ['Team Leadership', 'Client Relations']
          }
        ],
        education: ['Bachelor in Business Administration'],
        recommendedTransitions: ['Operations Manager', 'Project Manager', 'Business Analyst']
      });
      setIsUploading(false);
    }, 1500);
  };
  


  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      <CareerTransitionSearch />

      {/* Resume Analysis Results */}
      {resumeData && (
        <div className="bg-green-50 rounded-lg shadow">
          <div className="border-b border-green-100 p-6">
            <h2 className="text-xl font-semibold">Resume Analysis Results</h2>
          </div>
          <div className="p-6">
            <div className="space-y-4">
              <div>
                <h4 className="font-medium">Current Skills</h4>
                <div className="flex flex-wrap gap-2 mt-2">
                  {resumeData.currentSkills.map((skill, index) => (
                    <span key={index} className="bg-green-200 px-3 py-1 rounded-full text-sm">
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <h4 className="font-medium">Recommended Transitions</h4>
                <div className="flex flex-wrap gap-2 mt-2">
                  {resumeData.recommendedTransitions.map((job, index) => (
                    <span key={index} className="bg-blue-200 px-3 py-1 rounded-full text-sm">
                      {job}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Job Transitions Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {jobTransitions.map((transition, index) => (
          <div key={index} className="bg-white rounded-lg shadow hover:shadow-lg transition-shadow">
            <div className="border-b p-6">
              <h2 className="text-lg font-semibold flex items-center">
                {transition.fromJob}
                <ChevronRight className="inline mx-2 text-blue-500" />
                {transition.toJob}
              </h2>
              <div className="text-sm text-green-600">Match Score: {transition.matchScore}</div>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                <div>
                  <h4 className="font-medium flex items-center gap-2">
                    <Globe className="w-4 h-4 text-blue-500" />
                    Global Demand
                  </h4>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {Object.entries(countryDemandData).map(([country, data]) => (
                      <button
                        key={country}
                        onClick={() => setSelectedCountry(country)}
                        className={`px-3 py-1 rounded-full text-sm flex items-center gap-1 
                          ${country === selectedCountry ? 'ring-2 ring-blue-500' : ''}
                          ${getDemandColor(data[transition.toJob].demand)} text-white`}
                      >
                        {country}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="bg-gray-50 p-3 rounded-lg">
                  <h4 className="font-medium">Selected Market: {selectedCountry}</h4>
                  <div className="mt-2">
                    <div className="text-lg font-semibold">
                      {countryDemandData[selectedCountry][transition.toJob].salary}
                    </div>
                    <div className="text-sm text-gray-600">
                      Demand: {countryDemandData[selectedCountry][transition.toJob].demand}
                    </div>
                  </div>
                </div>

                <div>
                  <h4 className="font-medium">Skills You Have</h4>
                  <div className="ml-6 mt-2">
                    {transition.existingSkills.map((skill, idx) => (
                      <div key={idx} className="text-green-600">✓ {skill}</div>
                    ))}
                  </div>
                </div>

                <div>
                  <h4 className="font-medium">Skills to Acquire</h4>
                  <div className="ml-6 mt-2">
                    {transition.skillsToAcquire.map((skill, idx) => (
                      <div key={idx} className="text-orange-600">○ {skill}</div>
                    ))}
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <Clock className="w-4 h-4 text-blue-500" />
                  <span>Time to achieve: {transition.timeToAchieve}</span>
                </div>

                <button 
            onClick={() => handleViewTransitionPlan(transition)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg w-full hover:bg-blue-600"
          >
            View Detailed Transition Plan
          </button>

              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Training Resources */}
      {selectedSkill && (
        <div className="bg-white rounded-lg shadow mt-6">
          <div className="border-b p-6">
            <h2 className="text-xl font-semibold">Training Resources for {selectedSkill}</h2>
          </div>
          <div className="p-6">
            <div className="space-y-4">
              <div className="flex justify-between items-center p-3 border rounded hover:bg-gray-50">
                <div>
                  <h4 className="font-medium">Online Course: {selectedSkill} Fundamentals</h4>
                  <p className="text-sm text-gray-600">Duration: 6 weeks • Certification included</p>
                </div>
                <button className="bg-green-500 text-white px-4 py-2 rounded">Enroll</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerTransition;


// import { Search, Upload, ChevronRight, Trophy, Loader2 } from 'lucide-react';
// import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';

// const JobTransitionPlatform = () => {
//   const searchRef = React.useRef(null);
//   const [selectedSkill, setSelectedSkill] = React.useState(null);
//   const [resumeData, setResumeData] = React.useState(null);
//   const [selectedCountry, setSelectedCountry] = React.useState('United States');
//   const [isSearchFocused, setIsSearchFocused] = React.useState(false);
//   const [searchQuery, setSearchQuery] = React.useState('');
//   const [showPreview, setShowPreview] = React.useState(false);
//   const [isUploading, setIsUploading] = React.useState(false);
//   const [recentSearches, setRecentSearches] = React.useState([
//     'Marketing Manager',
//     'Software Developer',
//     'Data Entry Specialist'
//   ]);

//   React.useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (searchRef.current && !searchRef.current.contains(event.target)) {
//         setIsSearchFocused(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => document.removeEventListener('mousedown', handleClickOutside);
//   }, []);

//   const countryDemandData = {
//     'United States': {
//       'AI Prompt Engineer': { demand: 'Very High', salary: '$70,000 - $85,000' },
//       'Data Analyst': { demand: 'High', salary: '$75,000 - $95,000' },
//       'Project Coordinator': { demand: 'Medium', salary: '$60,000 - $75,000' }
//     },
//     'United Kingdom': {
//       'AI Prompt Engineer': { demand: 'High', salary: '£40,000 - £55,000' },
//       'Data Analyst': { demand: 'Very High', salary: '£45,000 - £65,000' },
//       'Project Coordinator': { demand: 'Medium', salary: '£35,000 - £45,000' }
//     }
//   };

//   const jobTransitions = [
//     {
//       fromJob: "Customer Service Representative",
//       toJob: "AI Prompt Engineer",
//       requiredSkills: ["Natural Language Processing", "Creative Writing", "AI Systems"],
//       timeToAchieve: "6 months",
//       matchScore: "85%",
//       existingSkills: ["Communication", "Problem Solving"],
//       skillsToAcquire: ["NLP", "AI Fundamentals"]
//     },
//     {
//       fromJob: "Data Entry Clerk",
//       toJob: "Data Analyst",
//       requiredSkills: ["SQL", "Python", "Data Visualization"],
//       timeToAchieve: "8 months",
//       matchScore: "75%",
//       existingSkills: ["Attention to Detail", "Data Entry"],
//       skillsToAcquire: ["SQL", "Python"]
//     }
//   ];

//   const popularTransitions = [
//     {
//       title: 'Customer Service → AI Prompt Engineer',
//       growth: '+125% YoY',
//       avgSalary: '$75,000',
//       difficulty: 'Medium'
//     },
//     {
//       title: 'Teacher → Instructional Designer',
//       growth: '+85% YoY',
//       avgSalary: '$70,000',
//       difficulty: 'Low'
//     }
//   ];

//   const getDemandColor = (demand) => {
//     const colors = {
//       'Very High': 'bg-green-500',
//       'High': 'bg-blue-500',
//       'Medium': 'bg-yellow-500',
//       'Low': 'bg-red-500'
//     };
//     return colors[demand] || 'bg-gray-500';
//   };

//   const handleResumeUpload = (file) => {
//     setIsUploading(true);
//     setShowPreview(true);
    
//     setTimeout(() => {
//       setResumeData({
//         currentSkills: ['Customer Service', 'Microsoft Office', 'Team Management'],
//         experience: [
//           {
//             title: 'Customer Service Manager',
//             years: 3,
//             skills: ['Team Leadership', 'Client Relations']
//           }
//         ],
//         education: ['Bachelor in Business Administration'],
//         recommendedTransitions: ['Operations Manager', 'Project Manager', 'Business Analyst']
//       });
//       setIsUploading(false);
//     }, 1500);
//   };

//   return (
//     <div className="max-w-4xl mx-auto p-4 space-y-6 bg-gray-50">
//       <div className="relative" ref={searchRef}>
//         <div className="flex items-center space-x-2 bg-white p-4 rounded-lg border shadow-sm">
//           <Search className="w-5 h-5 text-gray-400" />
//           <input
//             type="text"
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             placeholder="Enter your current job title..."
//             className="flex-1 outline-none"
//             onFocus={() => setIsSearchFocused(true)}
//           />
//         </div>

//         {isSearchFocused && (
//           <div className="absolute w-full mt-2 bg-white rounded-lg border shadow-lg divide-y z-20">
//             <div className="p-4">
//               <div className="flex items-center justify-between mb-4">
//                 <h3 className="font-medium">Quick Resume Upload</h3>
//                 {isUploading && <Loader2 className="w-4 h-4 animate-spin" />}
//               </div>
//               <div className="flex flex-wrap gap-3">
//                 <div className="flex-1">
//                   <input 
//                     type="file" 
//                     className="hidden" 
//                     id="resume-upload"
//                     onChange={(e) => handleResumeUpload(e.target.files[0])}
//                   />
//                   <label 
//                     htmlFor="resume-upload" 
//                     className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg cursor-pointer hover:bg-blue-600"
//                   >
//                     <Upload className="w-4 h-4" />
//                     Upload Resume
//                   </label>
//                 </div>
//               </div>
//             </div>

//             {popularTransitions.length > 0 && (
//               <div className="p-4">
//                 <h3 className="font-medium mb-3">Popular Transitions</h3>
//                 {popularTransitions.map((transition, index) => (
//                   <div 
//                     key={index} 
//                     className="flex items-center justify-between py-2 px-3 hover:bg-gray-50 rounded cursor-pointer"
//                   >
//                     <div>
//                       <div className="font-medium">{transition.title}</div>
//                       <div className="text-sm text-gray-600">
//                         {transition.growth} • {transition.avgSalary}
//                       </div>
//                     </div>
//                     <Trophy className="w-4 h-4 text-yellow-500" />
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//         )}
//       </div>

//       {resumeData && (
//         <Card className="bg-white/50 backdrop-blur-sm border-gray-100">
//           <CardHeader>
//             <CardTitle className="text-gray-700">Resume Analysis</CardTitle>
//           </CardHeader>
//           <CardContent>
//             <div className="space-y-4">
//               <div>
//                 <h4 className="font-medium text-gray-600">Current Skills</h4>
//                 <div className="flex flex-wrap gap-2 mt-2">
//                   {resumeData.currentSkills.map((skill, index) => (
//                     <span key={index} className="bg-blue-50 text-blue-700 px-3 py-1 rounded-full text-sm">
//                       {skill}
//                     </span>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </CardContent>
//         </Card>
//       )}

//       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//         {jobTransitions.map((transition, index) => (
//           <Card key={index} className="hover:shadow-md transition-shadow bg-white/50 backdrop-blur-sm border-gray-100">
//             <CardHeader>
//               <CardTitle className="text-lg text-gray-700">
//                 {transition.fromJob}
//                 <ChevronRight className="inline mx-2 text-blue-500" />
//                 {transition.toJob}
//               </CardTitle>
//               <div className="text-sm text-green-600">Match Score: {transition.matchScore}</div>
//             </CardHeader>
//             <CardContent>
//               <div className="space-y-4">
//                 <div className="flex flex-wrap gap-2">
//                   {Object.entries(countryDemandData).map(([country, data]) => (
//                     <button
//                       key={country}
//                       onClick={() => setSelectedCountry(country)}
//                       className={`px-3 py-1 rounded-full text-sm flex items-center gap-1 
//                         ${country === selectedCountry ? 'ring-2 ring-blue-300' : ''}
//                         ${getDemandColor(data[transition.toJob].demand)} text-white/90`}
//                     >
//                       {country}
//                     </button>
//                   ))}
//                 </div>

//                 <div className="bg-gray-50/50 p-3 rounded-lg">
//                   <h4 className="font-medium text-gray-600">Selected Market: {selectedCountry}</h4>
//                   <div className="mt-2">
//                     <div className="text-lg font-semibold text-gray-700">
//                       {countryDemandData[selectedCountry][transition.toJob].salary}
//                     </div>
//                     <div className="text-sm text-gray-500">
//                       Demand: {countryDemandData[selectedCountry][transition.toJob].demand}
//                     </div>
//                   </div>
//                 </div>

//                 <button className="bg-blue-500/90 text-white px-4 py-2 rounded-lg w-full hover:bg-blue-600/90 transition-colors">
//                   View Detailed Transition Plan
//                 </button>
//               </div>
//             </CardContent>
//           </Card>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default JobTransitionPlatform;