// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const generateMerchantUserId = () => {
//     return `USER-${Math.random().toString(36).substr(2, 9)}`; // Generates a random user ID
// };

// const PaymentModal = ({ isOpen, onClose, training }) => {
//     const amount = training.price; // Constant amount derived from training data
//     const [merchantUserId] = useState(generateMerchantUserId());
//     const [merchantTransactionId] = useState(`TXN-${Date.now()}`);

//     // User details states
//     const [name, setName] = useState('');
//     const [email, setEmail] = useState('');
//     const [phone, setPhone] = useState('');

//     const [couponCode, setCouponCode] = useState('');
//     const [discount, setDiscount] = useState(0);
//     const [isCouponVisible, setIsCouponVisible] = useState(false);
//     const [isPolling, setIsPolling] = useState(false);
//     const [message, setMessage] = useState(''); // To store the success/failure message

//     useEffect(() => {
//         let interval;
//         if (isPolling) {
//             interval = setInterval(async () => {
//                 try {
//                     const statusResponse = await axios.get(`/api/payment/status/${merchantTransactionId}`);
//                     if (statusResponse.data.status === 'SUCCESS') {
//                         clearInterval(interval);
//                         setIsPolling(false);
//                         setMessage('Payment successful! Your course login details will be shared soon via email.');
                        
//                         // Close the modal after 2 minutes (120000 ms)
//                         setTimeout(() => {
//                             onClose();
//                         }, 120000); 
//                     } else if (statusResponse.data.status === 'FAILED') {
//                         clearInterval(interval);
//                         setIsPolling(false);
//                         setMessage('Payment failed. If any funds were withdrawn, they will be refunded within 3-4 business days.');
                        
//                         // Close the modal after 4 minutes (240000 ms)
//                         setTimeout(() => {
//                             onClose();
//                         }, 240000); 
//                     }
//                 } catch (error) {
//                     console.error('Error polling payment status:', error);
//                     clearInterval(interval);
//                     setIsPolling(false);
//                     setMessage('Error fetching payment status. Please contact support.');
//                 }
//             }, 5000); // Poll every 5 seconds
//         }

//         return () => {
//             if (interval) {
//                 clearInterval(interval);
//             }
//         };
//     }, [isPolling, merchantTransactionId, onClose]);

//     const handlePayment = async (e) => {
//         e.preventDefault();

//         const payload = {
//             merchantId: process.env.REACT_APP_MERCHANTID, // Access env variable
//             merchantTransactionId,
//             merchantUserId,
//             name,
//             email,
//             phone,
//             amount: (amount - discount) * 100, // Convert to Paise
//             redirectUrl: "https://your-redirect-url.com",
//             redirectMode: "REDIRECT",
//             callbackUrl: "https://your-callback-url.com",
//             paymentInstrument: {
//                 type: "PAY_PAGE"
//             }
//         };

//         try {
//             const response = await axios.post('/api/payment', payload);
//             if (response.data.success) {
//                 setIsPolling(true); // Start polling after successful payment initiation
//             } else {
//                 alert('Payment initiation failed: ' + response.data.message);
//             }
//         } catch (error) {
//             console.error('Error processing payment:', error);
//             alert('Error processing payment. Please try again.');
//         }
//     };

//     const handleCouponApply = () => {
//         if (couponCode === "DISCOUNT10") {
//             setDiscount(amount * 0.10); // Apply a 10% discount
//             alert('Coupon applied! You get a 10% discount.');
//         } else {
//             alert('Invalid coupon code.');
//         }
//     };

//     const handleClose = () => {
//     if (isPolling) {
//         // Only show confirmation if the payment is in progress (polling is active)
//         if (window.confirm('Are you sure you want to cancel the transaction? Any funds withdrawn will be refunded within 3-4 business days if the transaction was processed.')) {
//             setIsPolling(false); // Stop polling if it was active
//             setName('');
//             setEmail('');
//             setPhone('');
//             setCouponCode('');
//             setDiscount(0); // Reset discount on close
//             setIsCouponVisible(false);
//             onClose(); // Close the modal
//             alert('Transaction canceled. If any funds were withdrawn, they will be refunded within 3-4 business days.');
//         }
//     } else {
//         // If no payment processing, simply close the modal
//         setName('');
//         setEmail('');
//         setPhone('');
//         setCouponCode('');
//         setDiscount(0); // Reset discount on close
//         setIsCouponVisible(false);
//         onClose(); // Close the modal
//     }
// };


//     return (
//         <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ${isOpen ? '' : 'hidden'}`}>
//             <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md">
//                 <h2 className="text-xl font-bold mb-4">Payment Form</h2>
//                 {message ? (
//                     <p className="text-center text-lg text-green-500 mb-4">{message}</p>
//                 ) : (
//                     <form onSubmit={handlePayment}>
//                         <div className="mb-4">
//                             <label className="block text-gray-700 mb-2" htmlFor="name">Name</label>
//                             <input
//                                 type="text"
//                                 id="name"
//                                 value={name}
//                                 onChange={(e) => setName(e.target.value)}
//                                 required
//                                 className="border border-gray-300 p-2 rounded w-full"
//                             />
//                         </div>
//                         <div className="mb-4">
//                             <label className="block text-gray-700 mb-2" htmlFor="email">Email</label>
//                             <input
//                                 type="email"
//                                 id="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 required
//                                 className="border border-gray-300 p-2 rounded w-full"
//                             />
//                         </div>
//                         <div className="mb-4">
//                             <label className="block text-gray-700 mb-2" htmlFor="phone">Phone Number</label>
//                             <input
//                                 type="tel"
//                                 id="phone"
//                                 value={phone}
//                                 onChange={(e) => setPhone(e.target.value)}
//                                 required
//                                 className="border border-gray-300 p-2 rounded w-full"
//                             />
//                         </div>
//                         <div className="mb-4">
//                             <label className="block text-gray-700 mb-2" htmlFor="amount">Amount (INR)</label>
//                             <input
//                                 type="number"
//                                 id="amount"
//                                 value={amount - discount} // Display discounted amount
//                                 readOnly
//                                 className="border border-gray-300 p-2 rounded w-full bg-gray-100"
//                             />
//                         </div>
//                         {isCouponVisible && (
//                             <div className="mb-4">
//                                 <label className="block text-gray-700 mb-2" htmlFor="coupon">Coupon Code</label>
//                                 <input
//                                     type="text"
//                                     id="coupon"
//                                     value={couponCode}
//                                     onChange={(e) => setCouponCode(e.target.value)}
//                                     className="border border-gray-300 p-2 rounded w-full"
//                                 />
//                                 <button
//                                     type="button"
//                                     onClick={handleCouponApply}
//                                     className="mt-2 bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"
//                                 >
//                                     Apply
//                                 </button>
//                             </div>
//                         )}
//                         <p onClick={() => setIsCouponVisible(!isCouponVisible)} className="text-blue-500 cursor-pointer mb-2">
//                             {isCouponVisible ? "Remove Discount" : "Add Discount Coupon"}
//                         </p>
//                         <button
//                             type="submit"
//                             className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
//                             disabled={isPolling} // Disable the button if polling is active
//                         >
//                             {isPolling ? "Processing..." : "Pay Now"}
//                         </button>
//                         <button
//                             type="button"
//                             onClick={handleClose}
//                             className="mt-2 text-gray-600 hover:text-gray-800"
//                         >
//                             Cancel
//                         </button>
//                     </form>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default PaymentModal;


import React, { useState, useEffect } from 'react';
import axios from 'axios';

const generateMerchantUserId = () => {
    return `USER-${Math.random().toString(36).substr(2, 9)}`; 
};

const PaymentModal = ({ isOpen, onClose, training }) => {
    const amount = training.price; // Constant amount derived from training data
    const [merchantUserId] = useState(generateMerchantUserId());
    const [merchantTransactionId, setMerchantTransactionId] = useState("")

    // User details states
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('')
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL

    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [isCouponVisible, setIsCouponVisible] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    const [message, setMessage] = useState(''); // To store the success/failure message

    useEffect(() => {
        let interval;
        if (isPolling) {
            interval = setInterval(async () => {
                try {
                    const statusResponse = await axios.get(`${apiUrl}/app2/api/payment/status/${merchantTransactionId}`);
                    if (statusResponse.data.status === 'SUCCESS') {
                        clearInterval(interval);
                        setIsPolling(false);
                        setMessage('Payment successful! Your course login details will be shared soon via email.');

                        // Close the modal after 2 minutes (120000 ms)
                        setTimeout(() => {
                            onClose();
                        }, 120000);
                    } else if (statusResponse.data.status === 'FAILED') {
                        clearInterval(interval);
                        setIsPolling(false);
                        setMessage('Payment failed. If any funds were withdrawn, they will be refunded within 3-4 business days.');

                        // Close the modal after 4 minutes (240000 ms)
                        setTimeout(() => {
                            onClose();
                        }, 240000);
                    }
                } catch (error) {
                    console.error('Error polling payment status:', error);
                    clearInterval(interval);
                    setIsPolling(false);
                    setMessage('Error fetching payment status. Please contact support.');
                }
            }, 5000); // Poll every 5 seconds
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isPolling, merchantTransactionId, apiUrl, onClose]);

    const handlePayment = async (e) => {
        e.preventDefault();

        // Fetch the redirect URL and amount from the backend
        try {
            const response = await axios.post(`${apiUrl}/app2/api/payments/process`, {
                firstname,
                lastname,
                email,
                phone,
                amount: (amount - discount) * 100,
                merchantUserId,
            });

            if (response.data.success) {
                const { redirectUrl } = response.data; 
                setMerchantTransactionId(response.data.merchantTransactionId)
                window.PhonePeCheckout.transact({ tokenUrl:redirectUrl });
                setIsPolling(true); 
            } else {
                alert('Payment initiation failed: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('Error processing payment. Please try again.');
        }
    };

    const handleCouponApply = () => {
        if (couponCode === discount) {
            setDiscount(training.discount); 
            alert('Coupon applied! You get a 10% discount.');
        } else {
            alert('Invalid coupon code.');
        }
    };


    const handleClose = () => {
        if (isPolling) {
            // Only show confirmation if the payment is in progress (polling is active)
            if (window.confirm('Are you sure you want to cancel the transaction? Any funds withdrawn will be refunded within 3-4 business days if the transaction was processed.')) {
                setIsPolling(false); // Stop polling if it was active
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setCouponCode('');
                setDiscount(0); // Reset discount on close
                setIsCouponVisible(false);
                onClose(); // Close the modal
                alert('Transaction canceled. If any funds were withdrawn, they will be refunded within 3-4 business days.');
            }
        } else {
            // If no payment processing, simply close the modal
            setFirstName('');
            setLastName('')
            setEmail('');
            setPhone('');
            setCouponCode('');
            setDiscount(0); // Reset discount on close
            setIsCouponVisible(false);
            onClose(); // Close the modal
        }
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ${isOpen ? '' : 'hidden'}`}>
            <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md">
                <h2 className="text-xl font-bold mb-4">Payment Form</h2>
                {message ? (
                    <p className="text-center text-lg text-green-500 mb-4">{message}</p>
                ) : (
                    <form onSubmit={handlePayment}>
                        <div className="mb-4">
                            <label className="block text-gray-700 mb-2" htmlFor="name">First Name</label>
                            <input
                                type="text"
                                id="name"
                                value={firstname}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                className="border border-gray-300 p-2 rounded w-full"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 mb-2" htmlFor="name">Last Name</label>
                            <input
                                type="text"
                                id="name"
                                value={lastname}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                className="border border-gray-300 p-2 rounded w-full"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 mb-2" htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="border border-gray-300 p-2 rounded w-full"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 mb-2" htmlFor="phone">Phone Number</label>
                            <input
                                type="tel"
                                id="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                                className="border border-gray-300 p-2 rounded w-full"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 mb-2" htmlFor="amount">Amount (INR)</label>
                            <input
                                type="number"
                                id="amount"
                                value={amount - discount} // Display discounted amount
                                readOnly
                                className="border border-gray-300 p-2 rounded w-full bg-gray-100"
                            />
                        </div>
                        {isCouponVisible && (
                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2" htmlFor="coupon">Coupon Code</label>
                                <input
                                    type="text"
                                    id="coupon"
                                    value={couponCode}
                                    onChange={(e) => setCouponCode(e.target.value)}
                                    className="border border-gray-300 p-2 rounded w-full"
                                />
                                <button
                                    type="button"
                                    onClick={handleCouponApply}
                                    className="mt-2 bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"
                                >
                                    Apply
                                </button>
                            </div>
                        )}
                        <p onClick={() => setIsCouponVisible(!isCouponVisible)} className="text-blue-500 cursor-pointer mb-2">
                            {isCouponVisible ? "Remove Discount" : "Add Discount Coupon"}
                        </p>
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                            disabled={isPolling} 
    
                        >
                            {isPolling ? "Processing..." : "Pay Now"}
                        </button>
                        <button
                            type="button"
                            onClick={handleClose}
                            className="mt-2 text-gray-600 hover:text-gray-800"
                        >
                            Cancel
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default PaymentModal;
