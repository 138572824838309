import React from 'react';

const TeamCard = ({ image, name, title, description, email, phone, linkedin, imageHeight }) => {
    return (
        <div className="bg-white rounded-lg shadow-md p-6">
            <img
                src={image}
                alt={name}
                className={`rounded-full mb-4 mx-auto ${imageHeight ? `h-${imageHeight}` : 'h-32'}`}
                style={{ objectFit: 'cover' }}
            />
            <h3 className="text-xl font-semibold text-center">{name}</h3>
            <p className="text-gray-600 text-center">{title}</p>
            <p className="text-gray-700 mt-4 text-justify">{description}</p>

            <div className="mt-4 text-center">
                {email && (
                    <p className="text-gray-500">
                        <strong>Email:</strong> <a href={`mailto:${email}`} className="text-blue-500 hover:underline">{email}</a>
                    </p>
                )}
                {phone && (
                    <p className="text-gray-500">
                        <strong>Phone:</strong> {phone}
                    </p>
                )}
                {linkedin && (
                    <p className="text-gray-500">
                        <strong>LinkedIn:</strong> <a href={linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">LinkedIn Profile</a>
                    </p>
                )}
            </div>
        </div>
    );
};

export default TeamCard;
