import React, { useState } from 'react';
import { 
  Check, X, Calendar, Clock, Users, PlayCircle, ChevronUp, ChevronDown,
  Star, GraduationCap, MapPin, Building2 
} from 'lucide-react';

// Placeholder icons for unavailable ones
const MANUFACTURING_CATEGORIES = {
  'Automotive & EV': {
    icon: Building2,
    subcategories: ['Vehicle Assembly', 'Powertrain', 'Battery Integration', 'Quality Control']
  },
  'Battery Manufacturing': {
    icon: Building2,
    subcategories: ['Cell Production', 'Pack Assembly', 'Testing & Validation', 'Recycling']
  },
  'Semiconductor': {
    icon: Building2,
    subcategories: ['Wafer Fabrication', 'Testing & Packaging', 'Process Integration']
  },
  'Drone Manufacturing': {
    icon: Building2,
    subcategories: ['Hardware Assembly', 'Flight Systems', 'Navigation Systems']
  },
  'Green Energy': {
    icon: Building2,
    subcategories: ['Solar Panel Production', 'Wind Turbine Manufacturing', 'Energy Storage']
  }
};

const COUNTRIES = [
  'All', 'USA', 'Germany', 'Japan', 'South Korea', 'China', 'India'
];

const CategorySelector = ({ categories, onSelect }) => (
  <div className="grid grid-cols-2 md:grid-cols-5 gap-4 overflow-y-auto max-h-48">
    {Object.entries(categories).map(([category, { icon: Icon }]) => (
      <button
        key={category}
        onClick={() => onSelect(category)}
        className="p-4 border rounded-lg hover:border-blue-500 transition-colors flex flex-col items-center gap-2"
      >
        <Icon className="w-8 h-8 text-blue-600" />
        <span className="text-sm font-medium text-center">{category}</span>
      </button>
    ))}
  </div>
);

const SubcategorySelector = ({ subcategories, onSelect }) => (
  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 overflow-y-auto max-h-48">
    {subcategories.map(subcategory => (
      <button
        key={subcategory}
        onClick={() => onSelect(subcategory)}
        className="p-4 border rounded-lg hover:border-blue-500 transition-colors text-left"
      >
        <span className="font-medium">{subcategory}</span>
      </button>
    ))}
  </div>
);

const CourseCard = ({ course }) => (
  <div className="border rounded-lg p-6 mb-4 bg-white">
    <div className="flex justify-between items-start mb-4">
      <div>
        <h4 className="text-xl font-medium">{course.name}</h4>
        <p className="text-green-600 font-medium mt-1">{course.placementRate} placement rate</p>
      </div>
      <span className={`px-3 py-1 rounded-full text-sm font-medium ${
        course.enrollmentStatus === 'Open' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
      }`}>
        {course.enrollmentStatus}
      </span>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
      <div className="flex items-center gap-2">
        <Calendar className="w-5 h-5 text-blue-600" />
        <span>Starts: {new Date(course.nextBatch).toLocaleDateString()}</span>
      </div>
      <div className="flex items-center gap-2">
        <Clock className="w-5 h-5 text-blue-600" />
        <span>{course.duration}</span>
      </div>
      <div className="flex items-center gap-2">
        <Users className="w-5 h-5 text-blue-600" />
        <span>{course.studentsEnrolled}/{course.maxCapacity} enrolled</span>
      </div>
    </div>
    <div className="space-y-4">
      <div>
        <h5 className="font-medium mb-2">Course Features</h5>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {course.features?.map((feature, idx) => (
            <li key={idx} className="flex items-center gap-2">
              <Check className="w-4 h-4 text-green-500" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-between items-center pt-4 border-t">
        <div className="text-2xl font-bold">{course.price}</div>
        <button className="px-6 py-2 rounded-full font-medium bg-blue-600 text-white hover:bg-blue-700">
          Enroll Now
        </button>
      </div>
    </div>
  </div>
);

const Certifications = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('All');

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm border">
        <div className="border-b p-6">
          <h2 className="text-xl font-bold">Manufacturing Career Pathways</h2>
          <p className="text-gray-600 mt-1">Explore certifications and careers in manufacturing</p>
        </div>
        <div className="p-6 space-y-6">
          <div>
            <h3 className="text-lg font-medium mb-4">Select Manufacturing Category</h3>
            <CategorySelector 
              categories={MANUFACTURING_CATEGORIES} 
              onSelect={(category) => {
                setSelectedCategory(category);
                setSelectedSubcategory('');
              }}
            />
          </div>
          {selectedCategory && (
            <div>
              <h3 className="text-lg font-medium mb-4">Select Specialization</h3>
              <SubcategorySelector
                subcategories={MANUFACTURING_CATEGORIES[selectedCategory].subcategories}
                onSelect={setSelectedSubcategory}
              />
            </div>
          )}
          {selectedSubcategory && (
            <div>
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">Available Certifications</h3>
                <select
                  className="p-2 border rounded-md"
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  {COUNTRIES.map(country => (
                    <option key={country} value={country}>{country}</option>
                  ))}
                </select>
              </div>
              <p className="text-gray-600">Select a country to view certifications and job opportunities</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Certifications;
