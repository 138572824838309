import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Store user data
  const [token, setToken] = useState(null); // Store auth token

  const login = (userData, authToken) => {
    setUser(userData);  // User data should include role
    setToken(authToken);
    console.log(userData) // added for testing
    console.log(user.role) // added for testing
    console.log(authToken)  // added for testing
  };

  const logout = () => {
    setUser(null);
    setToken(null);
  };

  const isAuthenticated = () => !!token;

  return (
    <AuthContext.Provider value={{ user, token, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
