import React, { useState } from 'react';
import { GraduationCap, Clock, Star, PlayCircle, BookOpen, CheckCircle, Trophy, BarChart } from 'lucide-react';

export const TalentTrainings = () => {
  const [courses] = useState([
    {
      id: 1,
      title: "Advanced Critical Care Nursing",
      provider: "American Association of Critical-Care Nurses",
      duration: "12 weeks",
      level: "Advanced",
      rating: 4.8,
      reviews: 245,
      price: "$599",
      certification: true,
      progress: 65,
      topics: [
        "Critical Care Pathophysiology",
        "Advanced Life Support",
        "Mechanical Ventilation",
        "Hemodynamic Monitoring"
      ]
    },
    {
      id: 2,
      title: "Emergency Nursing Essentials",
      provider: "Emergency Nurses Association",
      duration: "8 weeks",
      level: "Intermediate",
      rating: 4.6,
      reviews: 189,
      price: "$449",
      certification: true,
      progress: 0,
      topics: [
        "Triage Protocols",
        "Emergency Assessment",
        "Trauma Care",
        "Pediatric Emergencies"
      ]
    }
  ]);

  const [learningPaths] = useState([
    {
      id: 1,
      title: "Critical Care Specialist",
      courses: 5,
      duration: "6 months",
      certifications: 2,
      completed: 2
    },
    {
      id: 2,
      title: "Emergency Care Expert",
      courses: 4,
      duration: "4 months",
      certifications: 1,
      completed: 0
    }
  ]);

  return (
    <div className="space-y-6">
      {/* Learning Paths */}
      <div className="bg-white rounded-lg border p-6">
        <h2 className="text-xl font-semibold mb-4">Learning Paths</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {learningPaths.map((path) => (
            <div key={path.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
              <h3 className="font-semibold text-lg">{path.title}</h3>
              <div className="mt-3 grid grid-cols-2 gap-3">
                <div className="flex items-center text-gray-600">
                  <BookOpen className="w-4 h-4 mr-2" />
                  <span>{path.courses} Courses</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <Clock className="w-4 h-4 mr-2" />
                  <span>{path.duration}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <Trophy className="w-4 h-4 mr-2" />
                  <span>{path.certifications} Certifications</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <CheckCircle className="w-4 h-4 mr-2" />
                  <span>{path.completed}/{path.courses} Completed</span>
                </div>
              </div>
              <div className="mt-4">
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div 
                    className="bg-blue-500 h-2 rounded-full" 
                    style={{ width: `${(path.completed / path.courses) * 100}%` }}
                  ></div>
                </div>
              </div>
              <button className="mt-4 w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                Continue Path
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Available Courses */}
      <div className="bg-white rounded-lg border p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Available Courses</h2>
          <div className="flex space-x-2">
            <select className="border rounded-lg px-3 py-2 text-sm">
              <option>All Levels</option>
              <option>Beginner</option>
              <option>Intermediate</option>
              <option>Advanced</option>
            </select>
            <select className="border rounded-lg px-3 py-2 text-sm">
              <option>All Categories</option>
              <option>Critical Care</option>
              <option>Emergency</option>
              <option>Pediatric</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6">
          {courses.map((course) => (
            <div key={course.id} className="border rounded-lg p-6 hover:shadow-md transition-shadow">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-semibold">{course.title}</h3>
                  <p className="text-gray-600 mt-1">{course.provider}</p>
                </div>
                <span className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm">
                  {course.price}
                </span>
              </div>
              
              <div className="flex items-center mt-4 space-x-4 text-sm text-gray-600">
                <div className="flex items-center">
                  <Clock className="w-4 h-4 mr-1" />
                  {course.duration}
                </div>
                <div className="flex items-center">
                  <GraduationCap className="w-4 h-4 mr-1" />
                  {course.level}
                </div>
                <div className="flex items-center">
                  <Star className="w-4 h-4 mr-1 text-yellow-400" />
                  <span>{course.rating}</span>
                  <span className="text-gray-400 ml-1">({course.reviews})</span>
                </div>
                {course.certification && (
                  <div className="flex items-center text-green-600">
                    <Trophy className="w-4 h-4 mr-1" />
                    Certification
                  </div>
                )}
              </div>

              <div className="mt-4">
                <h4 className="font-medium mb-2">Course Content</h4>
                <div className="grid grid-cols-2 gap-2">
                  {course.topics.map((topic, index) => (
                    <div key={index} className="flex items-center text-sm text-gray-600">
                      <CheckCircle className="w-4 h-4 mr-2 text-green-500" />
                      {topic}
                    </div>
                  ))}
                </div>
              </div>

              {course.progress > 0 && (
                <div className="mt-4">
                  <div className="flex justify-between text-sm mb-1">
                    <span className="text-gray-600">Progress</span>
                    <span className="font-medium">{course.progress}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div 
                      className="bg-green-500 h-2 rounded-full" 
                      style={{ width: `${course.progress}%` }}
                    ></div>
                  </div>
                </div>
              )}

              <div className="mt-4 flex justify-between items-center">
                {course.progress > 0 ? (
                  <button className="flex items-center px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600">
                    <PlayCircle className="w-4 h-4 mr-2" />
                    Continue Learning
                  </button>
                ) : (
                  <button className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                    <PlayCircle className="w-4 h-4 mr-2" />
                    Start Course
                  </button>
                )}
                <button className="text-blue-500 hover:text-blue-600">
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Course Statistics */}
      <div className="bg-white rounded-lg border p-6">
        <h2 className="text-xl font-semibold mb-4">Your Learning Statistics</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="border rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-600">Courses in Progress</p>
                <p className="text-2xl font-semibold mt-1">3</p>
              </div>
              <BookOpen className="w-8 h-8 text-blue-500" />
            </div>
          </div>
          <div className="border rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-600">Hours Learned</p>
                <p className="text-2xl font-semibold mt-1">45</p>
              </div>
              <Clock className="w-8 h-8 text-green-500" />
            </div>
          </div>
          <div className="border rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-600">Certificates Earned</p>
                <p className="text-2xl font-semibold mt-1">2</p>
              </div>
              <Trophy className="w-8 h-8 text-yellow-500" />
            </div>
          </div>
          <div className="border rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-600">Completed Courses</p>
                <p className="text-2xl font-semibold mt-1">5</p>
              </div>
              <CheckCircle className="w-8 h-8 text-purple-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};