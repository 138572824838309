import React, { useState } from 'react';
import { GraduationCap, BriefcaseIcon, Code, Languages, Award, Plus, X } from 'lucide-react';

const RequirementsSection = ({ formData, handleChange }) => {
  const [newSkill, setNewSkill] = useState('');
  const [newLanguage, setNewLanguage] = useState({ name: '', level: 'basic' });

  const addSkill = (type) => {
    if (newSkill.trim()) {
      const currentSkills = formData.requirements[type] || [];
      handleChange('requirements', type, [...currentSkills, newSkill.trim()]);
      setNewSkill('');
    }
  };

  const removeSkill = (type, index) => {
    const currentSkills = formData.requirements[type] || [];
    handleChange('requirements', type, currentSkills.filter((_, i) => i !== index));
  };

  const addLanguage = () => {
    if (newLanguage.name.trim()) {
      const currentLanguages = formData.requirements.languages || [];
      handleChange('requirements', 'languages', [...currentLanguages, newLanguage]);
      setNewLanguage({ name: '', level: 'basic' });
    }
  };

  return (
    <div className="space-y-6">
      {/* Education Requirements */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <GraduationCap className="h-5 w-5 mr-2 text-blue-600" />
          Education Requirements
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Minimum Education Level <span className="text-red-500">*</span>
            </label>
            <select
              value={formData.requirements?.minimumEducation || ''}
              onChange={(e) => handleChange('requirements', 'minimumEducation', e.target.value)}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="">Select Education Level</option>
              <option value="high_school">High School</option>
              <option value="associates">Associate's Degree</option>
              <option value="bachelors">Bachelor's Degree</option>
              <option value="masters">Master's Degree</option>
              <option value="doctorate">Doctorate</option>
              <option value="professional">Professional Degree</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Field of Study
            </label>
            <input
              type="text"
              value={formData.requirements?.fieldOfStudy || ''}
              onChange={(e) => handleChange('requirements', 'fieldOfStudy', e.target.value)}
              placeholder="e.g., Computer Science, Business Administration"
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      {/* Experience Requirements */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <BriefcaseIcon className="h-5 w-5 mr-2 text-blue-600" />
          Experience Requirements
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Years of Experience <span className="text-red-500">*</span>
            </label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <input
                  type="number"
                  value={formData.requirements?.minYearsExperience || ''}
                  onChange={(e) => handleChange('requirements', 'minYearsExperience', e.target.value)}
                  placeholder="Minimum"
                  className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div>
                <input
                  type="number"
                  value={formData.requirements?.maxYearsExperience || ''}
                  onChange={(e) => handleChange('requirements', 'maxYearsExperience', e.target.value)}
                  placeholder="Maximum"
                  className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Management Experience Required
            </label>
            <select
              value={formData.requirements?.managementExperience || ''}
              onChange={(e) => handleChange('requirements', 'managementExperience', e.target.value)}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="none">No Management Experience Required</option>
              <option value="team_lead">Team Lead Experience</option>
              <option value="manager">Management Experience</option>
              <option value="director">Director Level Experience</option>
              <option value="executive">Executive Level Experience</option>
            </select>
          </div>
        </div>
      </div>

      {/* Skills */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Code className="h-5 w-5 mr-2 text-blue-600" />
          Required Skills
        </h3>
        <div className="space-y-4">
          {/* Required Skills */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Must-Have Skills <span className="text-red-500">*</span>
            </label>
            <div className="flex space-x-2">
              <input
                type="text"
                value={newSkill}
                onChange={(e) => setNewSkill(e.target.value)}
                placeholder="Add a required skill"
                className="flex-1 rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    addSkill('requiredSkills');
                  }
                }}
              />
              <button
                type="button"
                onClick={() => addSkill('requiredSkills')}
                className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                <Plus className="h-5 w-5" />
              </button>
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {formData.requirements?.requiredSkills?.map((skill, index) => (
                <span
                  key={index}
                  className="inline-flex items-center bg-blue-100 text-blue-700 rounded-full px-3 py-1 text-sm"
                >
                  {skill}
                  <button
                    onClick={() => removeSkill('requiredSkills', index)}
                    className="ml-2 text-blue-600 hover:text-blue-800"
                  >
                    <X className="h-3 w-3" />
                  </button>
                </span>
              ))}
            </div>
          </div>

          {/* Preferred Skills */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Nice-to-Have Skills
            </label>
            <div className="flex space-x-2">
              <input
                type="text"
                value={newSkill}
                onChange={(e) => setNewSkill(e.target.value)}
                placeholder="Add a preferred skill"
                className="flex-1 rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    addSkill('preferredSkills');
                  }
                }}
              />
              <button
                type="button"
                onClick={() => addSkill('preferredSkills')}
                className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                <Plus className="h-5 w-5" />
              </button>
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {formData.requirements?.preferredSkills?.map((skill, index) => (
                <span
                  key={index}
                  className="inline-flex items-center bg-green-100 text-green-700 rounded-full px-3 py-1 text-sm"
                >
                  {skill}
                  <button
                    onClick={() => removeSkill('preferredSkills', index)}
                    className="ml-2 text-green-600 hover:text-green-800"
                  >
                    <X className="h-3 w-3" />
                  </button>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Languages */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Languages className="h-5 w-5 mr-2 text-blue-600" />
          Language Requirements
        </h3>
        <div className="space-y-4">
          <div className="flex space-x-2">
            <input
              type="text"
              value={newLanguage.name}
              onChange={(e) => setNewLanguage({ ...newLanguage, name: e.target.value })}
              placeholder="Language name"
              className="flex-1 rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            />
            <select
              value={newLanguage.level}
              onChange={(e) => setNewLanguage({ ...newLanguage, level: e.target.value })}
              className="w-48 rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="basic">Basic</option>
              <option value="conversational">Conversational</option>
              <option value="fluent">Fluent</option>
              <option value="native">Native</option>
            </select>
            <button
              type="button"
              onClick={addLanguage}
              className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="h-5 w-5" />
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {formData.requirements?.languages?.map((lang, index) => (
              <span
                key={index}
                className="inline-flex items-center bg-purple-100 text-purple-700 rounded-full px-3 py-1 text-sm"
              >
                {lang.name} ({lang.level})
                <button
                  onClick={() => {
                    const currentLanguages = formData.requirements.languages || [];
                    handleChange(
                      'requirements',
                      'languages',
                      currentLanguages.filter((_, i) => i !== index)
                    );
                  }}
                  className="ml-2 text-purple-600 hover:text-purple-800"
                >
                  <X className="h-3 w-3" />
                </button>
              </span>
            ))}
          </div>
        </div>
      </div>

      {/* Certifications */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Award className="h-5 w-5 mr-2 text-blue-600" />
          Required Certifications
        </h3>
        <div className="space-y-4">
          <div className="flex space-x-2">
            <input
              type="text"
              value={newSkill}
              onChange={(e) => setNewSkill(e.target.value)}
              placeholder="Add a required certification"
              className="flex-1 rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  addSkill('certifications');
                }
              }}
            />
            <button
              type="button"
              onClick={() => addSkill('certifications')}
              className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="h-5 w-5" />
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {formData.requirements?.certifications?.map((cert, index) => (
              <span
                key={index}
                className="inline-flex items-center bg-yellow-100 text-yellow-700 rounded-full px-3 py-1 text-sm"
              >
                {cert}
                <button
                  onClick={() => removeSkill('certifications', index)}
                  className="ml-2 text-yellow-600 hover:text-yellow-800"
                >
                  <X className="h-3 w-3" />
                </button>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequirementsSection;