import TalentDashboard from "../talent/TalentDashboard";
import TalentJobs from "../talent/components/TalentJobs";
import TalentProfile from "../talent/components/TalentProfile";
import TalentTrainings from "../talent/components/TalentTrainings";
import AddUpdateTraining from "../trainings/AddUpdateTraining";
import TrainingAdmin from "../trainings/TrainingAdmin";


const protectedRoutesPages = [
  { path: "/training/admin", element: <TrainingAdmin />, requiredRole: "admin", layout: "admin" },
  { path: "/training/admin/add-training", element: <AddUpdateTraining />, requiredRole: "admin", layout: "admin" },
  { path: "/training/admin/update-training", element: <AddUpdateTraining />, requiredRole: "admin", layout: "admin" },
//   { path: "/training/admin/delete", element: <DeleteTraining />, requiredRole: "TrainingDataAdmin", layout: "admin" },
//   { path: "/talent/dashboard", element: <TalentDashboard />, requiredRole: "talent", layout: "talent" },
//   { path: "/talent/jobs", element: <TalentJobs />, requiredRole: "talent", layout: "talent" },
//   { path: "/talent/trainings", element: <TalentTrainings />, requiredRole: "talent", layout: "talent" },
//   { path: "/talent/profile", element: <TalentProfile />, requiredRole: "TrainingDataAdmin", layout: "talent" },
//   { path: "/training/admin/add", element: <TrainingNew />, requiredRole: "TrainingDataAdmin", layout: "employer" },
//   { path: "/training/admin/add", element: <TrainingNew />, requiredRole: "TrainingDataAdmin", layout: "employer" },
//   { path: "/training/admin/add", element: <TrainingNew />, requiredRole: "TrainingDataAdmin", layout: "employer" },
//   { path: "/training/admin/add", element: <TrainingNew />, requiredRole: "TrainingDataAdmin", layout: "employer" },
//   { path: "/university/details", element: <TrainingNew />, requiredRole: "TrainingDataAdmin", layout: "university" },




];

export default protectedRoutesPages;
