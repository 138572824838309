import React, { useState } from 'react';
import TrainerCourses from './components/TrainerCourses';
import TrainerSchedule from './components/TrainerSchedule';
import TrainerEarnings from './components/TrainerEarnings';
import TrainerProfile from './components/TrainerProfile';
import { 
  Calendar, 
  Users, 
  BookOpen, 
  DollarSign, 
  Star,
  Clock,
  FileText,
  MessageSquare 
} from 'lucide-react';

function TrainingPartnerDashboard() {
  const [activeTab, setActiveTab] = useState('dashboard');

  const renderContent = () => {
    switch(activeTab) {
      case 'courses':
        return <TrainerCourses />;
      case 'schedule':
        return <TrainerSchedule />;
      case 'earnings':
        return <TrainerEarnings />;
      case 'profile':
        return <TrainerProfile />;
      default:
        return (
          <div className="space-y-6">
            {/* Stats Overview */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="bg-white rounded-lg shadow">
                <div className="p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm text-gray-600">Active Students</p>
                      <p className="text-2xl font-bold">245</p>
                    </div>
                    <Users className="w-8 h-8 text-blue-500" />
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow">
                <div className="p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm text-gray-600">Active Courses</p>
                      <p className="text-2xl font-bold">4</p>
                    </div>
                    <BookOpen className="w-8 h-8 text-green-500" />
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow">
                <div className="p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm text-gray-600">This Month Earnings</p>
                      <p className="text-2xl font-bold">$3,450</p>
                    </div>
                    <DollarSign className="w-8 h-8 text-yellow-500" />
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow">
                <div className="p-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm text-gray-600">Average Rating</p>
                      <p className="text-2xl font-bold">4.8</p>
                    </div>
                    <Star className="w-8 h-8 text-purple-500" />
                  </div>
                </div>
              </div>
            </div>

            {/* Upcoming Sessions */}
            <div className="bg-white rounded-lg shadow">
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
                  <Calendar className="w-5 h-5 text-blue-500" />
                  Upcoming Sessions
                </h3>
                <div className="space-y-4">
                  <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                    <div>
                      <p className="font-medium">AWS Developer Certification</p>
                      <p className="text-sm text-gray-600">Module 3: Cloud Architecture</p>
                    </div>
                    <div className="text-right">
                      <p className="font-medium">Today, 2:00 PM</p>
                      <p className="text-sm text-gray-600">32 Students</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                    <div>
                      <p className="font-medium">React Advanced Patterns</p>
                      <p className="text-sm text-gray-600">Module 2: Custom Hooks</p>
                    </div>
                    <div className="text-right">
                      <p className="font-medium">Tomorrow, 10:00 AM</p>
                      <p className="text-sm text-gray-600">28 Students</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Pending Tasks */}
            <div className="bg-white rounded-lg shadow">
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
                  <Clock className="w-5 h-5 text-red-500" />
                  Pending Tasks
                </h3>
                <div className="space-y-4">
                  <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                    <div className="flex items-center gap-3">
                      <FileText className="w-5 h-5 text-orange-500" />
                      <div>
                        <p className="font-medium">Grade Assignments</p>
                        <p className="text-sm text-gray-600">AWS Developer Certification</p>
                      </div>
                    </div>
                    <span className="text-orange-500 text-sm font-medium">12 Pending</span>
                  </div>
                  <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                    <div className="flex items-center gap-3">
                      <MessageSquare className="w-5 h-5 text-blue-500" />
                      <div>
                        <p className="font-medium">Answer Student Queries</p>
                        <p className="text-sm text-gray-600">React Advanced Patterns</p>
                      </div>
                    </div>
                    <span className="text-blue-500 text-sm font-medium">5 Pending</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6 bg-gray-50">
      {/* Navigation */}
      <div className="flex space-x-4 mb-8 border-b">
        <button
          onClick={() => setActiveTab('dashboard')}
          className={`pb-2 ${
            activeTab === 'dashboard'
              ? 'border-b-2 border-blue-500 text-blue-600'
              : 'text-gray-600'
          }`}
        >
          Dashboard
        </button>
        <button
          onClick={() => setActiveTab('courses')}
          className={`pb-2 ${
            activeTab === 'courses'
              ? 'border-b-2 border-blue-500 text-blue-600'
              : 'text-gray-600'
          }`}
        >
          My Courses
        </button>
        <button
          onClick={() => setActiveTab('schedule')}
          className={`pb-2 ${
            activeTab === 'schedule'
              ? 'border-b-2 border-blue-500 text-blue-600'
              : 'text-gray-600'
          }`}
        >
          Schedule
        </button>
        <button
          onClick={() => setActiveTab('earnings')}
          className={`pb-2 ${
            activeTab === 'earnings'
              ? 'border-b-2 border-blue-500 text-blue-600'
              : 'text-gray-600'
          }`}
        >
          Earnings
        </button>
        <button
          onClick={() => setActiveTab('profile')}
          className={`pb-2 ${
            activeTab === 'profile'
              ? 'border-b-2 border-blue-500 text-blue-600'
              : 'text-gray-600'
          }`}
        >
          Profile
        </button>
      </div>

      {renderContent()}
    </div>
  );
}

export default TrainingPartnerDashboard;