// Benefits.js
import React from 'react';
import { FaCheckCircle, FaMedkit, FaGlobe, FaHandsHelping } from 'react-icons/fa'; // Example icons, adjust as needed

const benefits = [
    {
        icon: FaCheckCircle,
        title: "Quality Candidates",
        description: "We rigorously screen and select only the most qualified healthcare professionals to ensure they meet the highest standards required by international healthcare organizations.",
    },
    {
        icon: FaMedkit,
        title: "Comprehensive Support",
        description: "From initial application to placement and beyond, we provide end-to-end support for both healthcare professionals and employers, ensuring a smooth and hassle-free experience.",
    },
    {
        icon: FaGlobe,
        title: "Global Opportunities",
        description: "We offer access to a wide range of healthcare opportunities across Europe and the Middle East, helping professionals expand their careers and making it easier for institutions to find top talent.",
    },
    {
        icon: FaHandsHelping,
        title: "Cultural Integration Assistance",
        description: "We assist candidates in adapting to their new environment with cultural integration support, ensuring they can settle in and perform at their best from day one.",
    },
];

const Benefits = () => {
    return (
        <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto text-center mb-12">
                <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-4">Why Choose Us?</h2>
                <p className="text-lg max-w-3xl mx-auto text-gray-600">
                    Discover the benefits of partnering with us for your healthcare recruitment needs. We ensure the best outcomes for both healthcare professionals and organizations.
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {benefits.map((benefit, index) => (
                    <div
                        key={index}
                        className="bg-white rounded-xl p-6 flex flex-col items-center text-center shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition-all duration-300"
                    >
                        <benefit.icon className="text-4xl text-custom-blue mb-4" />
                        <h3 className="text-xl font-semibold text-gray-800 mb-2">{benefit.title}</h3>
                        <p className="text-gray-600 text-base leading-relaxed text-justify">
                            {benefit.description}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Benefits;
