import React, { useState } from 'react';
import { MapPin, Building, Briefcase, Search, Filter, Star, ChevronDown } from 'lucide-react';

export const TalentJobs = () => {
  const [jobs] = useState([
    {
      id: 1,
      title: "ICU Nurse",
      company: "Mayo Clinic",
      location: "Rochester, MN, USA",
      type: "Full-time",
      salary: "$85,000 - $110,000",
      requirements: ["5+ years ICU experience", "BSN Required", "ACLS Certification"],
      posted: "2 days ago",
      urgent: true,
      saved: false
    },
    {
      id: 2,
      title: "ER Nurse",
      company: "NHS England",
      location: "London, UK",
      type: "Full-time",
      salary: "£45,000 - £55,000",
      requirements: ["3+ years ER experience", "UK NMC Registration", "Emergency Care Certification"],
      posted: "1 week ago",
      urgent: false,
      saved: true
    }
  ]);

  const [filters] = useState({
    specialties: ["ICU", "ER", "Pediatric", "Operating Room", "Critical Care"],
    locations: ["United States", "United Kingdom", "Canada", "Australia", "Germany"],
    jobTypes: ["Full-time", "Part-time", "Contract", "Temporary"],
    experienceLevels: ["Entry Level", "Mid Level", "Senior Level", "Lead"]
  });

  return (
    <div className="space-y-6">
      {/* Search and Filters */}
      <div className="bg-white rounded-lg border p-4">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search jobs by title, keyword, or company"
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
            />
          </div>
          <div className="flex gap-2">
            <select className="px-4 py-2 border rounded-lg">
              <option value="">All Locations</option>
              {filters.locations.map(location => (
                <option key={location} value={location}>{location}</option>
              ))}
            </select>
            <select className="px-4 py-2 border rounded-lg">
              <option value="">All Specialties</option>
              {filters.specialties.map(specialty => (
                <option key={specialty} value={specialty}>{specialty}</option>
              ))}
            </select>
            <button className="flex items-center px-4 py-2 border rounded-lg hover:bg-gray-50">
              <Filter className="w-4 h-4 mr-2" />
              More Filters
            </button>
          </div>
        </div>
      </div>

      {/* Job Listings */}
      <div className="bg-white rounded-lg border p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Available Positions</h2>
          <div className="flex items-center text-sm text-gray-600">
            <span>Sort by:</span>
            <select className="ml-2 px-2 py-1 border rounded">
              <option>Most Recent</option>
              <option>Salary: High to Low</option>
              <option>Salary: Low to High</option>
              <option>Best Match</option>
            </select>
          </div>
        </div>

        <div className="space-y-4">
          {jobs.map((job) => (
            <div key={job.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-semibold text-lg">{job.title}</h3>
                  <div className="space-y-2 mt-2">
                    <div className="flex items-center text-gray-600">
                      <Building className="w-4 h-4 mr-2" />
                      <span>{job.company}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <MapPin className="w-4 h-4 mr-2" />
                      <span>{job.location}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <Briefcase className="w-4 h-4 mr-2" />
                      <span>{job.type}</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-end">
                  {job.urgent && (
                    <span className="px-2 py-1 bg-red-100 text-red-600 rounded-full text-sm mb-2">
                      Urgent
                    </span>
                  )}
                  <span className="text-sm text-gray-500">{job.posted}</span>
                  <button 
                    className={`mt-2 ${job.saved ? 'text-yellow-500' : 'text-gray-400'}`}
                    title={job.saved ? 'Saved' : 'Save Job'}
                  >
                    <Star className="w-5 h-5" />
                  </button>
                </div>
              </div>
              
              <div className="mt-4">
                <span className="text-blue-600 font-medium">{job.salary}</span>
                <div className="mt-2 flex flex-wrap gap-2">
                  {job.requirements.map((req, index) => (
                    <span 
                      key={index}
                      className="px-2 py-1 bg-gray-100 text-gray-700 rounded-full text-sm"
                    >
                      {req}
                    </span>
                  ))}
                </div>
              </div>
              
              <div className="mt-4 flex justify-end gap-2">
                <button className="px-4 py-2 border border-blue-500 text-blue-500 rounded-lg hover:bg-blue-50">
                  View Details
                </button>
                <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                  Apply Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Application Tracker Summary */}
      <div className="bg-white rounded-lg border p-4">
        <h3 className="font-semibold mb-4">Your Application Stats</h3>
        <div className="grid grid-cols-4 gap-4">
          <div className="text-center p-3 border rounded-lg">
            <div className="text-2xl font-semibold text-blue-500">4</div>
            <div className="text-sm text-gray-600">Applications</div>
          </div>
          <div className="text-center p-3 border rounded-lg">
            <div className="text-2xl font-semibold text-yellow-500">2</div>
            <div className="text-sm text-gray-600">Under Review</div>
          </div>
          <div className="text-center p-3 border rounded-lg">
            <div className="text-2xl font-semibold text-green-500">1</div>
            <div className="text-sm text-gray-600">Interviews</div>
          </div>
          <div className="text-center p-3 border rounded-lg">
            <div className="text-2xl font-semibold text-purple-500">5</div>
            <div className="text-sm text-gray-600">Saved Jobs</div>
          </div>
        </div>
      </div>
    </div>
  );
};