import { RecoilRoot } from 'recoil'; // Import RecoilRoot
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Talent from "./pages/Talent";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Employer from "./pages/Employer";
import TermsConditions from "./pages/TermsConditions";
import NotFound from "./pages/NotFound";
import Trainings from "./pages/Trainings";
import PublicLayout from "./Layouts/PublicLayout";
import TrainingDetailPage from "./pages/TrainingDetailsPage";
import RefundPolicy from "./pages/RefundPolicy";
import useScrollToTop from "./hooks/scrollToTop";
import TrainingLogin from "./trainings/TrainingLogin";
import { AuthProvider } from "./authentication/AuthContext";
import ProtectedRoutes from "./authentication/ProtectedRoutes";
import protectedRoutesPages from "./authentication/ProtectedRoutePages";
import AdminLayout from "./Layouts/AdminLayout";
import TalentLayout from "./Layouts/UserLayout";
import EmployerLayout from "./Layouts/EmployerLayout";
import LoginLayout from "./Layouts/LoginLayout";
import TrainingSignup from "./trainings/TrainingSignup";
import TalentSignup from "./talent/TalentSignup";
import EmployerSignup from "./employer/EmployerSignup";
import AdminSignup from "./admin/AdminSignup";
import UniversitySignup from "./universities/UniversitySignup";
import TalentLogin from "./talent/TalentLogin";
import EmployerLogin from "./employer/EmployerLogin";
import UniversityLogin from "./universities/UniversityLogin";
import AdminLogin from "./admin/AdminLogin";
import UniversityLayout from "./Layouts/UniversityLayout";
import Unauthorized from './pages/Unauthorized';
import CertificationDetails from './career_development/CertificationDetails';
import CertificationView from './career_development/career_components/CertificationView';
import Certifications from './career_development/Certifications';
import TalentDashboard from './talent/TalentDashboard';
import TrainingPartnerDashboard from './training_partners/TrainingPartnerDashboard';
import EmployerDashboard from './employer/EmployerDashboard';
import CareerTransitionSearch from './career_transition/CareerTransitionSearch';
import CareerTransition from './career_transition/CareerTransition';
import TransitionPlan from './career_transition/TransitionPlan';


function App() {
  useScrollToTop();

  return (
    <div className="m-0 p-0">
      <RecoilRoot> {/* Wrap the app in RecoilRoot */}
        <AuthProvider>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<PublicLayout />}>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/employer" element={<Employer />} />
              <Route path="/talent" element={<Talent />} />
              <Route path="/trainings" element={<Trainings />} />
              <Route path="/trainings/:id" element={<TrainingDetailPage />} />
              <Route path="/terms&conditions" element={<TermsConditions />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/refund_policy" element={<RefundPolicy />} />
              <Route path="/certifications-details" element={<CertificationView />} />
              <Route path="/certifications" element={<Certifications />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="*" element={<NotFound />} />
              {/* trial component routes */}
              <Route path="/talent-dashboard" element={<TalentDashboard />} />
              <Route path="/training-partner" element={<TrainingPartnerDashboard />} />
              <Route path="/employer-dashboard" element={<EmployerDashboard />} />
              <Route path="/career-transition" element={<CareerTransition />} />
              <Route path="/view-career-transition" element={<TransitionPlan />} />
               
            </Route>

            {/* Login Routes */}
            <Route path="/training/login" element={<LoginLayout><TrainingLogin /></LoginLayout>} />
            <Route path="/talent/login" element={<LoginLayout><TalentLogin /></LoginLayout>} />
            <Route path="/employer/login" element={<LoginLayout><EmployerLogin /></LoginLayout>} />
            <Route path="/admin/login" element={<LoginLayout><AdminLogin /></LoginLayout>} />
            <Route path="/university/login" element={<LoginLayout><UniversityLogin /></LoginLayout>} />

            {/* Signup Routes */}
            <Route path="/training/signup" element={<LoginLayout><TrainingSignup /></LoginLayout>} />
            <Route path="/talent/signup" element={<LoginLayout><TalentSignup /></LoginLayout>} />
            <Route path="/employer/signup" element={<LoginLayout><EmployerSignup /></LoginLayout>} />
            <Route path="/admin/signup" element={<LoginLayout><AdminSignup /></LoginLayout>} />
            <Route path="/university/signup" element={<LoginLayout><UniversitySignup /></LoginLayout>} />

            {/* Protected Routes */}
            {protectedRoutesPages.map(({ path, element, requiredRole, layout }) => {
              let LayoutComponent;
              if (layout === "admin") {
                LayoutComponent = AdminLayout;
              } else if (layout === "talent") {
                LayoutComponent = TalentLayout;
              } else if (layout === "employer") {
                LayoutComponent = EmployerLayout;
              } else if (layout === "university") {
                LayoutComponent = UniversityLayout;
              }

              return (
                <Route key={path} path={path} element={
                  <ProtectedRoutes requiredRole={requiredRole}>
                    {LayoutComponent ? (
                      <LayoutComponent>{element}</LayoutComponent>
                    ) : (
                      element
                    )}
                  </ProtectedRoutes>
                } />
              );
            })}
          </Routes>
        </AuthProvider>
      </RecoilRoot>
    </div>
  );
}

export default App;
