// import { Link } from 'react-router-dom';

// const Footer = () => {
//     return (
//         <footer className="bg-gray-900 text-white py-16 relative" style={{ backgroundImage: `url('/path-to-your-bg-image.jpg')` }}>
//             <div className="container mx-auto px-4">
//                 {/* Newsletter Section */}
//                 <div className="p-16 mb-24 pt-6 border-gray-700 border-b">
//                     <div className="flex flex-col md:flex-row">
//                         <div className="md:w-1/2 mb-4 md:mb-0">
//                             <h2 className="text-xl font-semibold mb-2">
//                                 Join our email subscription now to get updates
//                                 on new jobs and notifications.
//                             </h2>
//                         </div>
//                         <div className="md:w-1/2">
//                             <form className="flex">
//                                 <input
//                                     name="news-letter"
//                                     className="form-control flex-grow py-2 px-4 rounded-l-lg border-none"
//                                     placeholder="Enter Your Email"
//                                     type="email"
//                                 />
//                                 <button
//                                     type="submit"
//                                     className="bg-blue-500 text-white py-2 px-4 rounded-r-lg border-none hover:bg-blue-600"
//                                 >
//                                     Subscribe Now
//                                 </button>
//                             </form>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Footer Sections */}
//                 <div className="flex flex-col lg:flex-row lg:items-start lg:space-x-52 xl:space-x-72 2xl:space-x-96">
//                     {/* Left Side: Logo, Company Info, and Brand Identity */}
//                     <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
//                         <img src="/path-to-your-logo.png" alt="Company Logo" className="mb-6 w-32 mx-auto lg:mx-0" />


//                         <p className="text-sm leading-relaxed text-justify mt-4 mb-10">
//                             TalentFirst connects top talent with leading companies globally. We bridge the gap between opportunity and talent, empowering both job seekers and employers.
//                         </p>

//                         <h2 className="text-xl font-semibold mb-2 flex justify-center lg:justify-start text-justify  lg:text-left">Hoppen Tech Solutions Pvt Ltd</h2>
//                         <div className="text-sm flex flex-col justify-between lg:justify-start leading-relaxed">
//                             <span className="flex justify-center items-center lg:items-start lg:justify-start flex-col ml-2">
//                                 1234 Main Street, Suite 100<br />
//                                 Anytown, CA 12345<br />
//                                 Phone: (123) 456-7890<br />
//                             </span>
//                             <span className="flex justify-center lg:justify-start text-justified lg:text-left ml-0">
//                                 Email: <a href="mailto:info@talentfirst.com" className="hover:underline text-blue-400">info@talentfirst.com</a>
//                             </span>
//                         </div>





//                         {/* Social Media Links */}
//                         <div className="mt-6 flex justify-center lg:justify-start space-x-4">
//                             <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:underline text-white">
//                                 LinkedIn
//                             </a>
//                             <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="hover:underline text-white">
//                                 Twitter
//                             </a>
//                             <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="hover:underline text-white">
//                                 Facebook
//                             </a>
//                         </div>
//                     </div>

//                     {/* Right Side: For Companies, For Job Seekers, General */}
//                     <div className="w-full lg:w-2/3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center lg:text-left">

//                         {/* For Companies Section */}
//                         <div>
//                             <h4 className="text-lg font-semibold mb-4">For Companies</h4>
//                             <ul className="space-y-3">
//                                 <li><Link to="/permanent-hiring" className="hover:underline">Permanent Hiring</Link></li>
//                                 <li><Link to="/temporary-hiring" className="hover:underline">Temporary Hiring</Link></li>
//                                 <li><Link to="/internships" className="hover:underline">Internships</Link></li>
//                                 <li><Link to="/faq-companies" className="hover:underline">FAQs</Link></li>
//                                 <li><Link to="/terms-of-service-companies" className="hover:underline">Terms of Service</Link></li>
//                             </ul>
//                         </div>

//                         {/* For Job Seekers Section */}
//                         <div>
//                             <h4 className="text-lg font-semibold mb-4">For Job Seekers</h4>
//                             <ul className="space-y-3">
//                                 <li><Link to="/job-search" className="hover:underline">Job Search</Link></li>
//                                 <li><Link to="/academy" className="hover:underline">Academy</Link></li>
//                                 <li><Link to="/faq-job-seekers" className="hover:underline">FAQs</Link></li>
//                                 <li><Link to="/terms-of-service-job-seekers" className="hover:underline">Terms of Service</Link></li>
//                             </ul>
//                         </div>

//                         {/* General Section */}
//                         <div>
//                             <h4 className="text-lg font-semibold mb-4">General</h4>
//                             <ul className="space-y-3">
//                                 <li><Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link></li>
//                             </ul>
//                         </div>

//                     </div>
//                 </div>

//                 {/* Footer Bottom */}
//                 <div className="mt-8 border-t border-gray-700 pt-6 text-center">
//                     <p className="text-sm">&copy; 2024 TalentFirst. All rights reserved.</p>
//                 </div>
//             </div>
//         </footer>
//     );
// };

// export default Footer;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import logo from "./assets/logoFooter.png";

// const Footer = () => {
//     const [email, setEmail] = useState('');
//     const [isSubscribed, setIsSubscribed] = useState(false);
//     const [errorMessage, setErrorMessage] = useState('');
//     const apiUrl = process.env.REACT_APP_API_URL;
//     const fetchUrl = `${apiUrl}/api/emaillist`;



//     // Handle email input change
//     const handleEmailChange = (e) => {
//         setEmail(e.target.value);
//     };

//     // Handle form submission for email subscription
//     const handleEmailSubmit = async (e) => {
//         e.preventDefault();

//         try {
            
//             const response = await fetch(fetchUrl, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({ email })
//             });

//             if (response.ok) {
//                 setIsSubscribed(true);
//                 setEmail('');
//                 setErrorMessage('');
//             } else {
//                 setErrorMessage('Failed to subscribe. Please try again later.');
//             }
//         } catch (error) {
//             setErrorMessage('There was a network error. Please try again later.');
//             console.error('Network error:', error);
//         }
//     };

//     return (
//         <footer className="bg-gray-900 text-white py-16 relative" style={{ backgroundImage: `url('/path-to-your-bg-image.jpg')` }}>
//             <div className="container mx-auto px-4">
//                 {/* Newsletter Section */}
//                 <div className="p-16 mb-24 pt-6 border-gray-700 border-b">
//                     <div className="flex flex-col md:flex-row">
//                         <div className="md:w-1/2 mb-4 md:mb-0">
//                             <h2 className="text-xl relative sm:text-left text-center font-semibold mb-2 mr-4">
//                                 Join our email subscription now to get updates
//                                 on new opportunities and notifications.
//                             </h2>
//                         </div>
//                         <div className="md:w-1/2">
//                             {isSubscribed ? (
//                                 // Thank You Message after successful subscription
//                                 <div className="text-center">
//                                     <p className="text-white text-lg font-semibold mb-4">
//                                         Thank you for subscribing to our email list!
//                                     </p>
//                                     <button
//                                         className="bg-custome-gray text-white py-2 px-4 rounded-lg border-none hover:bg-gray-500"
//                                         onClick={() => setIsSubscribed(false)} // Reset form submission status
//                                     >
//                                         Subscribe Another Email
//                                     </button>
//                                 </div>
//                             ) : (
//                                 // Email Subscription Form
//                                 <form className="flex" onSubmit={handleEmailSubmit}>
//                                     <input
//                                         name="news-letter"
//                                         className="form-control nl-0 justify-center text-custome-gray flex-grow py-2 px-1 sm:px-4 rounded-l-lg border-none"
//                                         placeholder="Enter Your Email"
//                                         type="email"
//                                         value={email}
//                                         onChange={handleEmailChange}
//                                         required
//                                     />
//                                     <button
//                                         type="submit"
//                                         className="bg-custome-gray text-white py-2 px-2 rounded-r-lg border-none hover:bg-gray-500"
//                                         onSubmit={handleEmailSubmit}
//                                     >
//                                         Subscribe Now
//                                     </button>
//                                 </form>
//                             )}
//                             {errorMessage && (
//                                 <p className="mt-2 text-red-500 text-sm">{errorMessage}</p>
//                             )}
//                         </div>
//                     </div>
//                 </div>

//                 {/* Footer Sections */}
//                 <div className="flex flex-col lg:flex-row lg:items-start lg:space-x-52 xl:space-x-72 2xl:space-x-96">
//                     {/* Left Side: Logo, Company Info, and Brand Identity */}
//                     <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
//                         <img src={logo} alt="TalentFirst Logo" className="mb-6 w-32 mx-auto lg:mx-0" />

//                         <p className="text-sm leading-relaxed text-justify mt-4 mb-10">
//                             TalentFirst connects top talent with leading companies globally. We bridge the gap between opportunity and talent, empowering both job seekers and employers with innovative solutions.
//                         </p>

//                         <h2 className="text-xl font-semibold mb-2 flex justify-center lg:justify-start text-justify lg:text-left">TalentFirst Pvt Ltd</h2>
//                         <div className="text-sm flex flex-col justify-between lg:justify-start leading-relaxed">
//                             <span className="flex justify-center items-center lg:items-start lg:justify-start flex-col ">
//                                 29/A, 27th Main Rd, Sector 5,<br />
//                                 11st Sector, HSR Layout,<br />
//                                 Bengaluru, Karnataka 560102<br />

//                                 <span className="flex justify-center lg:justify-start text-justified lg:text-left mt-1 ">
//                                     Phone: <a href='tel:+91-7975563057'>+91-7975563057</a><br />
//                                 </span>

//                             </span>
//                             <span className="flex justify-center lg:justify-start text-justified lg:text-left ">
//                                 Email:  <a href="mailto:connect@talentfirst.global" className="hover:underline text-blue-400"> connect@talentfirst.global</a>
//                             </span>
//                         </div>

//                         {/* Social Media Links */}
//                         <div className="mt-6 flex justify-center lg:justify-start space-x-4">
//                             <a href="https://www.linkedin.com/company/talentfirst-global" target="_blank" rel="noopener noreferrer" className="hover:underline text-white">
//                                 LinkedIn
//                             </a>
//                             <a href="https://www.instagram.com/talentfirst_recruiting/" target="_blank" rel="noopener noreferrer" className="hover:underline text-white">
//                                 Instagram
//                             </a>
//                             <a href="https://www.facebook.com/talentfirstrecruiting" target="_blank" rel="noopener noreferrer" className="hover:underline text-white">
//                                 Facebook
//                             </a>
//                         </div>
//                     </div>

//                     {/* Right Side: Navigation Links */}
//                     <div className="w-full lg:w-2/3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center lg:text-left">

//                         {/* Home Section */}
//                         <div>
//                             <h4 className="text-lg font-semibold mb-4">Site Links</h4>
//                             <ul className="space-y-3">
//                                 <li><Link to="/" className="hover:underline">Home</Link></li>
//                                 <li><Link to="/about" className="hover:underline">About</Link></li>
//                                 <li><Link to="/employer" className="hover:underline">For Employers</Link></li>
//                                 <li><Link to="/talent" className="hover:underline">For Talent</Link></li>
//                             </ul>
//                         </div>

//                         {/* Legal Section */}
//                         <div>
//                             <h4 className="text-lg font-semibold mb-4">Legal</h4>
//                             <ul className="space-y-3">
//                                 <li><Link to="/privacypolicy" className="hover:underline">Privacy Policy</Link></li>
//                                 <li><Link to="/terms&conditions" className="hover:underline">Terms & Conditions</Link></li>
//                             </ul>
//                         </div>

//                         {/* Contact Section */}
//                         <div>
//                             <h4 className="text-lg font-semibold mb-4 mt-2">Contact</h4>
//                             <ul className="space-y-3">
//                                 <li><Link to="/contact" className="hover:underline">Contact Us</Link></li>

//                             </ul>
//                         </div>

//                     </div>
//                 </div>

//                 {/* Footer Bottom */}
//                 <div className="mt-8 border-t border-gray-700 pt-6 text-center">
//                     <p className="text-sm">&copy; 2024 TalentFirst. All rights reserved. Chainlink Technology Pvt. Ltd.</p>
//                 </div>
//             </div>
//         </footer>
//     );
// };

// export default Footer;


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "./assets/logoFooter.png";

const Footer = () => {
    const [email, setEmail] = useState('');
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [shouldSubmit, setShouldSubmit] = useState(false); // State to trigger useEffect
    const apiUrl = process.env.REACT_APP_API_URL;
    const fetchUrl = `${apiUrl}/api/emaillist`;

    // Handle email input change
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // Handle form submission for email subscription
    const handleEmailSubmit = (e) => {
        e.preventDefault();
        setShouldSubmit(true); // Set state to true to trigger useEffect
    };

    // useEffect for making the API call
    useEffect(() => {
        const subscribeEmail = async () => {
            if (shouldSubmit) { // Only run if form was submitted
                try {
                    const response = await fetch(fetchUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ email })
                    });

                    if (response.ok) {
                        setIsSubscribed(true);
                        setEmail('');
                        setErrorMessage('');
                    } else {
                        setErrorMessage('Failed to subscribe. Please try again later.');
                    }
                } catch (error) {
                    setErrorMessage('There was a network error. Please try again later.');
                    console.error('Network error:', error);
                }
                setShouldSubmit(false); // Reset form submission status
            }
        };

        subscribeEmail();
    }, [shouldSubmit, fetchUrl, email]);

    return (
        <footer className="bg-gray-900 text-white py-16 relative" style={{ backgroundImage: `url('/path-to-your-bg-image.jpg')` }}>
            <div className="container mx-auto px-4">
                {/* Newsletter Section */}
                <div className="p-16 mb-24 pt-6 border-gray-700 border-b">
                    <div className="flex flex-col md:flex-row">
                        <div className="md:w-1/2 mb-4 md:mb-0">
                            <h2 className="text-xl relative sm:text-left text-center font-semibold mb-2 mr-4">
                                Join our email subscription now to get updates
                                on new opportunities and notifications.
                            </h2>
                        </div>
                        <div className="md:w-1/2">
                            {isSubscribed ? (
                                // Thank You Message after successful subscription
                                <div className="text-center">
                                    <p className="text-white text-lg font-semibold mb-4">
                                        Thank you for subscribing to our email list!
                                    </p>
                                    <button
                                        className="bg-custome-gray text-white py-2 px-4 rounded-lg border-none hover:bg-gray-500"
                                        onClick={() => setIsSubscribed(false)} // Reset form submission status
                                    >
                                        Subscribe Another Email
                                    </button>
                                </div>
                            ) : (
                                // Email Subscription Form
                                <form className="flex" onSubmit={handleEmailSubmit}>
                                    <input
                                        name="news-letter"
                                        className="form-control nl-0 justify-center text-custome-gray flex-grow py-2 px-1 sm:px-4 rounded-l-lg border-none"
                                        placeholder="Enter Your Email"
                                        type="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                    />
                                    <button
                                        type="submit"
                                        className="bg-custome-gray text-white py-2 px-2 rounded-r-lg border-none hover:bg-gray-500"
                                    >
                                        Subscribe Now
                                    </button>
                                </form>
                            )}
                            {errorMessage && (
                                <p className="mt-2 text-red-500 text-sm">{errorMessage}</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Footer Sections */}
                <div className="flex flex-col lg:flex-row lg:items-start lg:space-x-52 xl:space-x-72 2xl:space-x-96">
                    {/* Left Side: Logo, Company Info, and Brand Identity */}
                    <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
                        <img src={logo} alt="TalentFirst Logo" className="mb-6 w-32 mx-auto lg:mx-0" />

                        <p className="text-sm leading-relaxed text-justify mt-4 mb-10">
                            TalentFirst connects top talent with leading companies globally. We bridge the gap between opportunity and talent, empowering both job seekers and employers with innovative solutions.
                        </p>

                        <h2 className="text-xl font-semibold mb-2 flex justify-center lg:justify-start text-justify lg:text-left">Chainlink Technology Pvt Ltd</h2>
                        <div className="text-sm flex flex-col justify-between lg:justify-start leading-relaxed">
                            <span className="flex justify-center items-center lg:items-start lg:justify-start flex-col ">
                                29/A, 27th Main Rd, Sector 5,<br />
                                11st Sector, HSR Layout,<br />
                                Bengaluru, Karnataka 560102<br />

                                <span className="flex justify-center lg:justify-start text-justified lg:text-left mt-1 ">
                                    Phone: <a href='tel:+91-7975563057'>+91-7975563057</a><br />
                                </span>

                            </span>
                            <span className="flex justify-center lg:justify-start text-justified lg:text-left ">
                                Email:  <a href="mailto:connect@talentfirst.global" className="hover:underline text-blue-400"> connect@talentfirst.global</a>
                            </span>
                        </div>

                        {/* Social Media Links */}
                        <div className="mt-6 flex justify-center lg:justify-start space-x-4">
                            <a href="https://www.linkedin.com/company/talentfirst-global" target="_blank" rel="noopener noreferrer" className="hover:underline text-white">
                                LinkedIn
                            </a>
                            <a href="https://www.instagram.com/talentfirst_recruiting/" target="_blank" rel="noopener noreferrer" className="hover:underline text-white">
                                Instagram
                            </a>
                            <a href="https://www.facebook.com/talentfirstrecruiting" target="_blank" rel="noopener noreferrer" className="hover:underline text-white">
                                Facebook
                            </a>
                        </div>
                    </div>

                    {/* Right Side: Navigation Links */}
                    <div className="w-full lg:w-2/3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center lg:text-left">

                        {/* Home Section */}
                        <div>
                            <h4 className="text-lg font-semibold mb-4">Site Links</h4>
                            <ul className="space-y-3">
                                <li><Link to="/" className="hover:underline">Home</Link></li>
                                <li><Link to="/about" className="hover:underline">About</Link></li>
                                <li><Link to="/employer" className="hover:underline">For Employers</Link></li>
                                <li><Link to="/talent" className="hover:underline">For Talent</Link></li>
                            </ul>
                        </div>

                        {/* Legal Section */}
                        <div>
                            <h4 className="text-lg font-semibold mb-4">Legal</h4>
                            <ul className="space-y-3">
                                <li><Link to="/privacypolicy" className="hover:underline">Privacy Policy</Link></li>
                                <li><Link to="/refund_policy" className="hover:underline">Refund Policy</Link></li>
                                <li><Link to="/terms&conditions" className="hover:underline">Terms & Conditions</Link></li>
                            </ul>
                        </div>

                        {/* Contact Section */}
                        <div>
                            <h4 className="text-lg font-semibold mb-4 mt-2">Contact</h4>
                            <ul className="space-y-3">
                                <li><Link to="/contact" className="hover:underline">Contact Us</Link></li>

                            </ul>
                        </div>

                    </div>
                </div>

                {/* Footer Bottom */}
                <div className="mt-8 border-t border-gray-700 pt-6 text-center">
                    <p className="text-sm">&copy; 2024 TalentFirst. All rights reserved. Chainlink Technology Pvt. Ltd.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
