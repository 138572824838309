import React, { useState } from 'react';
import { TalentJobs } from './components/TalentJobs';
import { TalentResearch } from './components/TalentResearch';
import { TalentTrainings } from './components/TalentTrainings';
import { TalentProfile } from './components/TalentProfile';

const TalentDashboard = () => {
  const [activeTab, setActiveTab] = useState('jobs');

  const renderActiveSection = () => {
    switch (activeTab) {
      case 'jobs':
        return <TalentJobs />;
      case 'research':
        return <TalentResearch />;
      case 'training':
        return <TalentTrainings />;
      case 'profile':
        return <TalentProfile />;
      default:
        return <TalentJobs />;
    }
  };

  return (
    <div className="w-full max-w-6xl mx-auto space-y-6 p-4">
      <div className="space-y-6">
        <div className="grid w-full grid-cols-4 inline-flex h-10 items-center justify-center rounded-md bg-gray-100 p-1">
          {['jobs', 'research', 'training', 'profile'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium transition-all
                ${activeTab === tab ? 'bg-white text-gray-900 shadow-sm' : 'text-gray-500 hover:text-gray-900'}`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        {renderActiveSection()}
      </div>
    </div>
  );
};

export default TalentDashboard;