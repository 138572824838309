import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoutes = ({ children, requiredRole }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  // Check if the required role is specified and matches the user's role
  // removing user && userRole && user.role !== requireRole
  if (user.role !== requiredRole) {
    return <Navigate to="/unauthorized" />;
  }

  return children; // Render the protected component
};

export default ProtectedRoutes;
