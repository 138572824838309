// // Services.js
// import React from 'react';
// import { FaBriefcase, FaLightbulb, FaChartLine, FaPeopleCarry } from 'react-icons/fa';

// const services = [
//     {
//         icon: FaBriefcase,
//         title: "Recruitment Services",
//         description: "We connect talented professionals with companies that are looking for the best in the industry. From initial screening to final placement, we ensure the right fit.",
//     },
//     {
//         icon: FaLightbulb,
//         title: "Career Consulting",
//         description: "Our career consultants provide personalized guidance to help you make informed decisions about your career path and professional growth.",
//     },
//     {
//         icon: FaChartLine,
//         title: "Market Insights",
//         description: "We offer in-depth market analysis and insights to help you stay ahead of industry trends and make data-driven decisions.",
//     },
//     {
//         icon: FaPeopleCarry,
//         title: "HR Solutions",
//         description: "Our HR solutions help companies streamline their human resource processes, from onboarding to performance management and more.",
//     },
// ];

// const Services = () => {
//     return (
//         <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
//             <div className="max-w-7xl mx-auto text-center mb-12">
//                 <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-4">What We Offer</h2>
//                 <p className="text-xl max-w-2xl mx-auto text-gray-600">
//                     Our services are designed to support both individuals and organizations in achieving their goals. Explore the various ways we can assist you below.
//                 </p>
//             </div>

//             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
//                 {services.map((service, index) => (
//                     <div
//                         key={index}
//                         className="bg-white rounded-xl p-6 flex flex-col items-center text-center shadow-lg transform hover:scale-105 transition-all duration-300"
//                     >
//                         <service.icon className="text-4xl mb-4 text-blue-500" />
//                         <h3 className="text-xl font-semibold mb-2 text-gray-800">{service.title}</h3>
//                         <p className="text-gray-600 text-sm">{service.description}</p>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default Services;


// // Services.js
// import React from 'react';
// import { FaStethoscope, FaGlobeEurope, FaHospitalUser } from 'react-icons/fa';

// const services = [
//     {
//         icon: FaStethoscope,
//         title: "Healthcare Professionals",
//         description: "We specialize in recruiting skilled doctors, nurses, and allied healthcare professionals to meet the growing demand in Europe and the Middle East. Our extensive network ensures that only the most qualified candidates are matched with healthcare providers.",
//     },
//     {
//         icon: FaGlobeEurope,
//         title: "Placement in Europe",
//         description: "We facilitate the seamless placement of healthcare professionals in leading hospitals and clinics across Europe, ensuring compliance with local regulations and standards.",
//     },
//     {
//         icon: FaHospitalUser,
//         title: "Middle East Opportunities",
//         description: "Our expertise extends to the Middle East, where we provide tailored recruitment solutions for healthcare organizations, connecting them with professionals ready to make a difference.",
//     },
// ];

// const Services = () => {
//     return (
//         <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
//             <div className="max-w-7xl mx-auto text-center mb-16">
//                 <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-4">Our Specialized Services</h2>
//                 <p className="text-lg max-w-3xl mx-auto text-gray-600">
//                     We connect healthcare professionals with top opportunities in Europe and the Middle East, ensuring the best fit for both our clients and candidates.
//                 </p>
//             </div>

//             <div className="space-y-12">
//                 {services.map((service, index) => (
//                     <div
//                         key={index}
//                         className="flex flex-col lg:flex-row items-center lg:items-start bg-gray-50 rounded-xl p-6 lg:p-10 shadow-lg"
//                     >
//                         <service.icon className="text-5xl text-custom-blue mb-4 lg:mb-0 lg:mr-6" />
//                         <div>
//                             <h3 className="text-2xl font-semibold text-gray-800 mb-2">{service.title}</h3>
//                             <p className="text-gray-600 text-base lg:text-lg leading-relaxed text-justify">
//                                 {service.description}
//                             </p>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default Services;


// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


// // Services.js
// import React from 'react';
// import { FaStethoscope, FaUserMd, FaGlobe } from 'react-icons/fa';
// import Slider from "react-slick"; // Requires react-slick and slick-carousel

// const services = [
//     {
//         icon: FaStethoscope,
//         title: "Healthcare Professionals",
//         description: "Connecting doctors, nurses, and allied health professionals with hospitals and clinics across Europe and the Middle East."
//     },
//     {
//         icon: FaUserMd,
//         title: "Doctors & Specialists",
//         description: "Recruiting experienced doctors and specialists to fill critical roles in healthcare facilities abroad."
//     },
//     {
//         icon: FaGlobe,
//         title: "Global Opportunities",
//         description: "Providing healthcare professionals with the chance to advance their careers globally in Europe and the Middle East."
//     }
// ];

// const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
// };

// const Services = () => {
//     return (
//         <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
//             <div className="max-w-7xl mx-auto text-center mb-12">
//                 <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-4">Our Specialized Healthcare Services</h2>
//                 <p className="text-lg max-w-3xl mx-auto text-gray-600">
//                     We connect healthcare professionals with top opportunities in Europe and the Middle East, ensuring the best fit for both our clients and candidates.
//                 </p>
//             </div>

//             <Slider {...sliderSettings} className="max-w-5xl mx-auto">
//                 {services.map((service, index) => (
//                     <div key={index} className="bg-white rounded-xl p-8 shadow-lg test-center ">
//                         <service.icon className="text-6xl text-blue-500 mb-6" />
//                         <h3 className="text-2xl font-semibold text-gray-800 mb-4">{service.title}</h3>
//                         <p className="text-gray-600 text-lg leading-relaxed text-justify">
//                             {service.description}
//                         </p>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     );
// };

// export default Services;



// // Services.js
// import React, { useState } from 'react';
// import { FaStethoscope, FaUserMd, FaPlaneDeparture } from 'react-icons/fa';
// import Slider from 'react-slick';


// const services = [
//     {
//         icon: FaStethoscope,
//         title: "Doctors & Specialists",
//         description: "Recruiting highly qualified doctors and specialists across various fields to meet the healthcare demands in Europe and the Middle East. We ensure compliance with all local regulatory requirements."
//     },
//     {
//         icon: FaUserMd,
//         title: "Nurses & Allied Health",
//         description: "Providing hospitals and clinics with experienced nurses, technicians, and other allied healthcare professionals to support their operations and improve patient care."
//     },
//     {
//         icon: FaPlaneDeparture,
//         title: "International Placement Support",
//         description: "Comprehensive support for healthcare professionals relocating abroad, including visa processing, certification, and cultural integration assistance."
//     },
// ];

// const Services = () => {
//     return (
//         <div>
//             <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
//                 <div className="max-w-7xl mx-auto text-center mb-12">
//                     <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-4">Our Healthcare Recruitment Services</h2>
//                     <p className="text-lg max-w-3xl mx-auto text-gray-600">
//                         We specialize in sourcing and placing healthcare professionals in key roles across Europe and the Middle East. Our services are tailored to ensure the best fit for both healthcare providers and candidates.
//                     </p>
//                 </div>

//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//                     {services.map((service, index) => (
//                         <div key={index} className="bg-gray-50 rounded-xl p-6 text-center shadow-lg">
//                             <service.icon className="text-5xl text-blue-500 mb-4" />
//                             <h3 className="text-xl font-semibold text-gray-800 mb-2">{service.title}</h3>
//                             <p className="text-gray-600 text-base leading-relaxed text-justify">{service.description}</p>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <Services1 />
//             <Services2 />
//         </div>
//     );
// };

// export default Services;



// const Services1 = () => {
//     const [activeService, setActiveService] = useState(0);

//     return (
//         <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
//             <div className="max-w-7xl mx-auto flex flex-col lg:flex-row">
//                 {/* Vertical Tabs */}
//                 <div className="lg:w-1/4 flex lg:flex-col justify-around lg:justify-start mb-8 lg:mb-0 lg:mr-8">
//                     {services.map((service, index) => (
//                         <button
//                             key={index}
//                             className={`flex items-center space-x-2 px-4 py-3 lg:py-4 mb-2 lg:mb-4 rounded-lg text-left transition-colors duration-200 w-full ${activeService === index ? "bg-blue-500 text-white" : "bg-gray-100 text-gray-800"
//                                 }`}
//                             onClick={() => setActiveService(index)}
//                         >
//                             <service.icon className="text-2xl" />
//                             <span className="font-medium">{service.title}</span>
//                         </button>
//                     ))}
//                 </div>

//                 {/* Service Description */}
//                 <div className="lg:w-3/4 bg-gray-50 rounded-xl p-6 shadow-lg">
//                     <h3 className="text-2xl font-semibold text-gray-800 mb-4">{services[activeService].title}</h3>
//                     <p className="text-gray-600 text-base lg:text-lg leading-relaxed text-justify">
//                         {services[activeService].description}
//                     </p>
//                 </div>
//             </div>
//         </div>
//     );
// };


// const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
// };

// const Services2 = () => {
//     return (
//         <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
//             <div className="max-w-7xl mx-auto text-center mb-12">
//                 <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-4">Our Specialized Healthcare Services</h2>
//                 <p className="text-lg max-w-3xl mx-auto text-gray-600">
//                     We connect healthcare professionals with top opportunities in Europe and the Middle East, ensuring the best fit for both our clients and candidates.
//                 </p>
//             </div>

//             <Slider {...sliderSettings} className="max-w-5xl mx-auto">
//                 {services.map((service, index) => (
//                     <div key={index} className="bg-white rounded-xl p-8 shadow-lg">
//                         <service.icon className="text-6xl text-blue-500 mb-6" />
//                         <h3 className="text-2xl font-semibold text-gray-800 mb-4">{service.title}</h3>
//                         <p className="text-gray-600 text-lg leading-relaxed text-justify">
//                             {service.description}
//                         </p>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     );
// };


// Services1.js
import React, { useState, useEffect } from 'react';
import { FaUserCheck, FaShieldAlt, FaChalkboardTeacher } from 'react-icons/fa'; // Example icons, adjust as needed

const services = [
    {
        icon: FaUserCheck,
        title: "Onboarding",
        description: "Our structured onboarding process helps new employees acclimate to the company culture and become productive quickly, improving retention and engagement."
    },
    {
        icon: FaShieldAlt,
        title: "Background Verification",
        description: "We conduct thorough background checks to ensure candidates meet safety and compliance standards, mitigating risks for your organization."
    },
    {
        icon: FaChalkboardTeacher,
        title: "Training",
        description: "We provide tailored training programs that equip employees with the necessary skills and knowledge, ensuring they excel in their roles and contribute to company success."
    }
];

const Services = () => {
    const [activeService, setActiveService] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveService((prevService) => (prevService + 1) % services.length);
        }, 3000); // Change service every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto text-center mb-12">
                <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-4">Our Specialized Services</h2>
                <p className="text-lg max-w-3xl mx-auto text-gray-600">
                We provide essential services to enhance your organization's hiring and employee development processes.
                </p>
            </div>
            <div className="max-w-7xl mx-auto flex flex-col lg:flex-row">
                {/* Vertical Tabs */}
                <div className="lg:w-1/4 flex lg:flex-col justify-around lg:justify-start mb-8 lg:mb-0 lg:mr-8">
                    {services.map((service, index) => (
                        <button
                            key={index}
                            className={`flex items-center space-x-2 p-1 m-1 px-4 py-3 lg:py-4 mb-2 lg:mb-4 rounded-lg text-left transition-colors duration-200 w-full ${activeService === index ? "bg-custom-blue text-white" : "bg-gray-100 text-gray-800"
                                }`}
                            onClick={() => setActiveService(index)}
                        >
                            <service.icon className="text-2xl" />
                            <span className="font-medium">{service.title}</span>
                        </button>
                    ))}
                </div>

                {/* Service Description */}
                <div className="lg:w-3/4 bg-gray-50 rounded-xl p-6 shadow-lg">
                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">{services[activeService].title}</h3>
                    <p className="text-gray-600 text-base lg:text-lg leading-relaxed text-justify">
                        {services[activeService].description}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Services;
