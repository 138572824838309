import React, { useState } from 'react';
import { BarChart, Bar, LineChart, Line, PieChart, Pie, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { 
  BarChart2, TrendingUp, LayoutGrid, ListFilter, 
  MessageSquare, X, Send, Building, DollarSign,
  Globe2, Briefcase, Users, ChevronRight, MinusCircle,
  PlusCircle, ExternalLink
} from 'lucide-react';

export const TalentResearch = () => {
  const [researchMode, setResearchMode] = useState('visual');
  const [salaryViewType, setSalaryViewType] = useState('bar');
  const [showAIChat, setShowAIChat] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([
    {
      type: 'assistant',
      content: "Hi! I can help you research nursing careers and opportunities. Ask me about salaries, companies, requirements, or market trends!"
    }
  ]);

  // Enhanced salary data by position
  const salaryByPosition = [
    { position: 'ICU Nurse', entry: 75000, mid: 95000, senior: 120000 },
    { position: 'ER Nurse', entry: 70000, mid: 90000, senior: 115000 },
    { position: 'OR Nurse', entry: 72000, mid: 92000, senior: 118000 },
    { position: 'Pediatric Nurse', entry: 68000, mid: 88000, senior: 110000 },
    { position: 'Critical Care', entry: 76000, mid: 98000, senior: 125000 }
  ];

  // Market trend data
  const marketTrends = [
    { month: 'Jan', openings: 1200, applications: 3600, hires: 800 },
    { month: 'Feb', openings: 1400, applications: 4200, hires: 950 },
    { month: 'Mar', openings: 1600, applications: 4800, hires: 1100 },
    { month: 'Apr', openings: 1800, applications: 5400, hires: 1250 },
    { month: 'May', openings: 2000, applications: 6000, hires: 1400 }
  ];

  // Company insights data
  const companyInsights = [
    {
      name: "Mayo Clinic",
      totalEmployees: 15000,
      nursingStaff: 5000,
      avgTenure: 5.2,
      benefits: ["Health Insurance", "401k Match", "Education Reimbursement"],
      ratings: {
        workLife: 4.2,
        salary: 4.5,
        career: 4.8,
        culture: 4.6
      },
      openings: {
        ICU: 25,
        ER: 15,
        OR: 10,
        Other: 30
      }
    },
    {
      name: "Cleveland Clinic",
      totalEmployees: 12000,
      nursingStaff: 4000,
      avgTenure: 4.8,
      benefits: ["Health Insurance", "Pension Plan", "Relocation Assistance"],
      ratings: {
        workLife: 4.0,
        salary: 4.3,
        career: 4.6,
        culture: 4.4
      },
      openings: {
        ICU: 20,
        ER: 18,
        OR: 12,
        Other: 25
      }
    }
  ];

  const handleChatSubmit = () => {
    if (!chatMessage.trim()) return;
    
    setChatHistory([
      ...chatHistory,
      { type: 'user', content: chatMessage },
      { type: 'assistant', content: "I'm analyzing the market data to answer your question..." }
    ]);
    setChatMessage('');
  };

  return (
    <div className="space-y-6 relative">
      {/* Market Overview */}
      <div className="bg-white rounded-lg border p-6">
        <h2 className="text-xl font-semibold mb-6">Nursing Market Overview</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={marketTrends}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="openings" stackId="1" stroke="#8884d8" fill="#8884d8" />
              <Area type="monotone" dataKey="applications" stackId="2" stroke="#82ca9d" fill="#82ca9d" />
              <Area type="monotone" dataKey="hires" stackId="3" stroke="#ffc658" fill="#ffc658" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Salary Analysis */}
      <div className="bg-white rounded-lg border p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Salary Analysis by Position</h2>
          <div className="flex gap-2">
            <select className="border rounded-lg px-3 py-2">
              <option>United States</option>
              <option>Canada</option>
              <option>United Kingdom</option>
            </select>
            <select className="border rounded-lg px-3 py-2">
              <option>All Cities</option>
              <option>Major Metro Areas</option>
              <option>Rural Areas</option>
            </select>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={salaryByPosition}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="position" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="entry" name="Entry Level" fill="#8884d8" />
                <Bar dataKey="mid" name="Mid Level" fill="#82ca9d" />
                <Bar dataKey="senior" name="Senior Level" fill="#ffc658" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="space-y-4">
            {salaryByPosition.map((item, index) => (
              <div key={index} className="border rounded-lg p-4">
                <h3 className="font-medium mb-2">{item.position}</h3>
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <p className="text-sm text-gray-600">Entry Level</p>
                    <p className="font-medium">${item.entry.toLocaleString()}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">Mid Level</p>
                    <p className="font-medium">${item.mid.toLocaleString()}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">Senior Level</p>
                    <p className="font-medium">${item.senior.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Company Insights */}
      <div className="bg-white rounded-lg border p-6">
        <h2 className="text-xl font-semibold mb-6">Company Insights</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {companyInsights.map((company, index) => (
            <div key={index} className="border rounded-lg p-4">
              <div className="flex justify-between items-start mb-4">
                <h3 className="font-semibold text-lg">{company.name}</h3>
                <button className="text-blue-500 hover:text-blue-600">
                  <ExternalLink className="w-5 h-5" />
                </button>
              </div>
              
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <p className="text-sm text-gray-600">Total Employees</p>
                  <p className="font-medium">{company.totalEmployees.toLocaleString()}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Nursing Staff</p>
                  <p className="font-medium">{company.nursingStaff.toLocaleString()}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Avg Tenure</p>
                  <p className="font-medium">{company.avgTenure} years</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Current Openings</p>
                  <p className="font-medium">{Object.values(company.openings).reduce((a, b) => a + b, 0)}</p>
                </div>
              </div>

              <div className="mb-4">
                <p className="text-sm text-gray-600 mb-2">Ratings</p>
                <div className="grid grid-cols-2 gap-2">
                  {Object.entries(company.ratings).map(([key, value]) => (
                    <div key={key} className="flex items-center">
                      <div className="w-24 text-sm">{key.charAt(0).toUpperCase() + key.slice(1)}</div>
                      <div className="flex-1 h-2 bg-gray-200 rounded-full">
                        <div 
                          className="h-2 bg-blue-500 rounded-full"
                          style={{ width: `${(value / 5) * 100}%` }}
                        ></div>
                      </div>
                      <div className="w-8 text-sm text-right">{value}</div>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <p className="text-sm text-gray-600 mb-2">Benefits</p>
                <div className="flex flex-wrap gap-2">
                  {company.benefits.map((benefit, idx) => (
                    <span 
                      key={idx}
                      className="px-2 py-1 bg-blue-50 text-blue-700 rounded-full text-sm"
                    >
                      {benefit}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* AI Chat Interface */}
      <div className={`fixed bottom-6 right-6 transition-all duration-300 ${showAIChat ? 'w-96' : 'w-auto'}`}>
        {!showAIChat ? (
          <button
            onClick={() => setShowAIChat(true)}
            className="bg-blue-500 text-white rounded-full p-4 hover:bg-blue-600 shadow-lg"
          >
            <MessageSquare className="w-6 h-6" />
          </button>
        ) : (
          <div className="bg-white rounded-lg shadow-xl border">
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="font-semibold">Research Assistant</h3>
              <button 
                onClick={() => setShowAIChat(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="h-96 p-4 overflow-y-auto space-y-4">
              {chatHistory.map((msg, index) => (
                <div
                  key={index}
                  className={`flex ${msg.type === 'user' ? 'justify-end' : 'justify-start'}`}
                >
                  <div
                    className={`max-w-[80%] rounded-lg p-3 ${
                      msg.type === 'user'
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {msg.content}
                  </div>
                </div>
              ))}
            </div>
            <div className="p-4 border-t">
              <div className="flex gap-2">
                <input
                  type="text"
                  value={chatMessage}
                  onChange={(e) => setChatMessage(e.target.value)}
                  placeholder="Ask about market trends, salaries, companies..."
                  className="flex-1 px-3 py-2 border rounded-lg"
                  onKeyPress={(e) => e.key === 'Enter' && handleChatSubmit()}
                />
                <button
                  onClick={handleChatSubmit}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  <Send className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};