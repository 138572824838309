// import React from 'react';
// import trainingData from '../data/trainingData.json'; // Adjust the path accordingly
// import { Link } from 'react-router-dom'; // For navigation

// const TrainingShowcase = () => {
//   return (
//     <div className="bg-custom-blue py-10">
//       <div className="max-w-7xl mx-auto px-6">
//         <h2 className="text-3xl text-white font-bold text-center mb-6">Industry-Led Trainings and Workshops</h2>
//         <p className="text-center mb-6 text-gray-200">
//           Our trainings and workshops are designed to meet the evolving needs of companies globally. We offer
//           industry-relevant certifications and hands-on workshops that equip students with the skills required
//           to excel in today's competitive job market.
//         </p>
        
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-6">
//           {Object.keys(trainingData.trainings).map((category) => (
//             <div key={category} className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
//               <h3 className="text-2xl font-semibold mb-4">{category}</h3>
//               <h4 className="font-bold text-lg mb-2">Certifications</h4>
//               <ul className="list-disc pl-5 mb-4">
//                 {trainingData.trainings[category].certifications.map((cert) => (
//                   <li key={cert.id} className="text-gray-700">
//                     <Link to={`/training/${cert.id}`} className="hover:text-blue-500">{cert.title}</Link>
//                   </li>
//                 ))}
//               </ul>
//               <h4 className="font-bold text-lg mb-2">Workshops</h4>
//               <ul className="list-disc pl-5">
//                 {trainingData.trainings[category].workshops.map((workshop) => (
//                   <li key={workshop.id} className="text-gray-700">
//                     <Link to={`/training/${workshop.id}`} className="hover:text-blue-500">{workshop.title}</Link>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//         </div>
        
//         <div className="text-center">
//           <Link to="/trainings" className="inline-block bg-white text-black px-4 py-2 rounded hover:bg-custome-gray transition">
//             View More
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TrainingShowcase;
import React, { useEffect, useState } from 'react';
import trainingData from '../data/trainingData.json'; // Adjust the path accordingly
import { Link } from 'react-router-dom'; // For navigation
import ReactCardFlip from 'react-card-flip';

const TrainingShowcase = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl text-custom-blue font-bold text-center mb-8">Industry-Led Trainings and Workshops</h2>
        <p className="text-center mb-10 text-gray-800">
          Our trainings and workshops are designed to meet the evolving needs of companies globally. We offer
          industry-relevant certifications and hands-on workshops that equip students with the skills required
          to excel in today's competitive job market.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {Object.entries(trainingData.trainings).map(([category, data]) => (
            <TrainingCard
              key={category}
              image={data.image}
              category={category}
              certifications={data.certifications}
              workshops={data.workshops}
            />
          ))}
        </div>

        <div className="text-center mt-10">
          <Link to="/trainings" className="inline-block bg-custom-blue text-white font-semibold hover:text-black px-6 py-3 rounded-lg shadow hover:bg-gray-100 transition-colors">
            View More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrainingShowcase;

const TrainingCard = ({ category, image, certifications, workshops }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.matchMedia("(min-width: 1024px)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    const handleMediaQueryChange = (e) => setIsLargeScreen(e.matches);

    mediaQuery.addListener(handleMediaQueryChange);
    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  const handleClick = () => {
    if (!isLargeScreen) {
      setIsFlipped(!isFlipped);
    }
  };

  const handleMouseEnter = () => {
    if (isLargeScreen) {
      setIsFlipped(true);
    }
  };

  const handleMouseLeave = () => {
    if (isLargeScreen) {
      setIsFlipped(false);
    }
  };

  return (
    <div 
      className="relative w-full h-full cursor-pointer group bg-slate-50"
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        {/* Front side: Image and Industry Name */}
        <div className="w-full h-96 bg-white overflow-hidden rounded-lg shadow-lg transition-transform transform group-hover:scale-105">
          <img 
            src={image} 
            alt={category}
            className="w-full h-full object-cover"
          />
          {/* <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <h3 className="text-3xl font-bold text-white text-center">{category}</h3>
          </div> */}
        </div>

        {/* Back side: Details */}
        <div className="w-full h-96 bg-white p-6 rounded-lg overflow-y-auto shadow-lg transition-transform transform group-hover:scale-105">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">{category}</h3>
          <h4 className="font-bold text-lg mb-2 text-gray-700">Certifications</h4>
          <ul className="list-disc pl-5 mb-4">
            {certifications.map((cert) => (
              <li key={cert.id} className="text-gray-600">
                <Link to={`/trainings/${cert.id}`} className="hover:text-blue-500 transition-colors">{cert.title}</Link>
              </li>
            ))}
          </ul>
          <h4 className="font-bold text-lg mb-2 text-gray-700">Workshops</h4>
          <ul className="list-disc pl-5">
            {workshops.map((workshop) => (
              <li key={workshop.id} className="text-gray-600">
                <Link to={`/trainings/${workshop.id}`} className="hover:text-blue-500 transition-colors">{workshop.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </ReactCardFlip>
    </div>
  );
};
