// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='m-10 mt-20 p-20 w-full h-96 flex flex-col items-center'>
      <h2 className='text-xl font-bold'>404 - Page Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
      <p>Can't find what you're looking for? <br />
         Contact us at: <a href="mailto:connect@talentfirst.global" className='text-custom-blue hover:text-red-400'>connect@talentfirst.global</a>
      </p>
      <p>Go back to <Link to="/" className='text-custom-blue hover:text-red-400'>Home</Link></p>
    </div>
  );
};

export default NotFound;

