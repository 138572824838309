import React, { useState } from 'react';
import { Shield, FileCheck, UserCheck, AlertTriangle, Fingerprint, Globe, Scale, BadgeCheck } from 'lucide-react';

const JobCompliance = ({ formData, handleChange }) => {
  const [newRequirement, setNewRequirement] = useState('');

  const addRequirement = (category) => {
    if (newRequirement.trim()) {
      const currentRequirements = formData.compliance[category] || [];
      handleChange('compliance', category, [...currentRequirements, {
        requirement: newRequirement.trim(),
        mandatory: true,
        details: ''
      }]);
      setNewRequirement('');
    }
  };

  return (
    <div className="space-y-6">
      {/* Legal Requirements */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Scale className="h-5 w-5 mr-2 text-blue-600" />
          Legal Requirements
        </h3>
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Employment Type Compliance <span className="text-red-500">*</span>
              </label>
              <select
                value={formData.compliance?.employmentType || ''}
                onChange={(e) => handleChange('compliance', 'employmentType', e.target.value)}
                className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Type</option>
                <option value="full_time_employee">Full-Time Employee</option>
                <option value="part_time_employee">Part-Time Employee</option>
                <option value="contractor">Independent Contractor</option>
                <option value="temporary">Temporary Worker</option>
                <option value="intern">Intern</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Work Authorization <span className="text-red-500">*</span>
              </label>
              <div className="space-y-2">
                {[
                  { id: 'citizenship', label: 'Citizenship Verification Required' },
                  { id: 'work_permit', label: 'Work Permit Verification' },
                  { id: 'visa_sponsorship', label: 'Visa Sponsorship Available' }
                ].map((item) => (
                  <label key={item.id} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.compliance?.workAuthorization?.[item.id] || false}
                      onChange={(e) => handleChange('compliance', 'workAuthorization', {
                        ...formData.compliance?.workAuthorization,
                        [item.id]: e.target.checked
                      })}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-600">{item.label}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Background Checks */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Fingerprint className="h-5 w-5 mr-2 text-blue-600" />
          Background Verification
        </h3>
        <div className="space-y-4">
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              checked={formData.compliance?.requiresBackgroundCheck || false}
              onChange={(e) => handleChange('compliance', 'requiresBackgroundCheck', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="ml-2 text-sm text-gray-700">Background check required for this position</span>
          </div>

          {formData.compliance?.requiresBackgroundCheck && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pl-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Check Types Required
                </label>
                {[
                  'Criminal History',
                  'Education Verification',
                  'Employment History',
                  'Professional License',
                  'Drug Screening',
                  'Credit Check',
                  'Motor Vehicle Record'
                ].map((type) => (
                  <label key={type} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.compliance?.backgroundChecks?.includes(type) || false}
                      onChange={(e) => {
                        const current = formData.compliance?.backgroundChecks || [];
                        const updated = e.target.checked
                          ? [...current, type]
                          : current.filter(t => t !== type);
                        handleChange('compliance', 'backgroundChecks', updated);
                      }}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-600">{type}</span>
                  </label>
                ))}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Timing of Check
                </label>
                <select
                  value={formData.compliance?.backgroundCheckTiming || ''}
                  onChange={(e) => handleChange('compliance', 'backgroundCheckTiming', e.target.value)}
                  className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                >
                  <option value="">Select Timing</option>
                  <option value="pre_interview">Pre-Interview</option>
                  <option value="post_interview">Post-Interview</option>
                  <option value="pre_offer">Pre-Offer</option>
                  <option value="post_offer">Post-Offer</option>
                  <option value="pre_start">Pre-Start Date</option>
                </select>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Required Certifications */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <BadgeCheck className="h-5 w-5 mr-2 text-blue-600" />
          Required Certifications & Licenses
        </h3>
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Professional Licenses
              </label>
              <div className="space-y-2">
                {formData.compliance?.requiredLicenses?.map((license, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={license.name}
                      onChange={(e) => {
                        const current = [...(formData.compliance?.requiredLicenses || [])];
                        current[index] = { ...license, name: e.target.value };
                        handleChange('compliance', 'requiredLicenses', current);
                      }}
                      className="flex-1 rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                      placeholder="License name"
                    />
                    <select
                      value={license.verificationTiming}
                      onChange={(e) => {
                        const current = [...(formData.compliance?.requiredLicenses || [])];
                        current[index] = { ...license, verificationTiming: e.target.value };
                        handleChange('compliance', 'requiredLicenses', current);
                      }}
                      className="w-48 rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                    >
                      <option value="pre_start">Pre-Start</option>
                      <option value="post_start">Post-Start</option>
                    </select>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => {
                    const current = formData.compliance?.requiredLicenses || [];
                    handleChange('compliance', 'requiredLicenses', [...current, { name: '', verificationTiming: 'pre_start' }]);
                  }}
                  className="text-sm text-blue-600 hover:text-blue-700"
                >
                  + Add License Requirement
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Compliance Training */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <FileCheck className="h-5 w-5 mr-2 text-blue-600" />
          Compliance Training Requirements
        </h3>
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              {[
                'Sexual Harassment Prevention',
                'Ethics and Conduct',
                'Data Privacy',
                'Information Security',
                'Health and Safety',
                'Anti-discrimination',
                'Industry-specific Compliance'
              ].map((training) => (
                <label key={training} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.compliance?.requiredTraining?.includes(training)}
                    onChange={(e) => {
                      const current = formData.compliance?.requiredTraining || [];
                      const updated = e.target.checked
                        ? [...current, training]
                        : current.filter(t => t !== training);
                      handleChange('compliance', 'requiredTraining', updated);
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">{training}</span>
                </label>
              ))}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Training Completion Timeline
              </label>
              <select
                value={formData.compliance?.trainingTimeline || ''}
                onChange={(e) => handleChange('compliance', 'trainingTimeline', e.target.value)}
                className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Timeline</option>
                <option value="before_start">Before Start Date</option>
                <option value="first_week">First Week</option>
                <option value="first_month">First Month</option>
                <option value="first_quarter">First Quarter</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Requirements */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <AlertTriangle className="h-5 w-5 mr-2 text-blue-600" />
          Additional Requirements
        </h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Special Requirements
            </label>
            <textarea
              value={formData.compliance?.additionalRequirements || ''}
              onChange={(e) => handleChange('compliance', 'additionalRequirements', e.target.value)}
              placeholder="Any additional compliance requirements or notes..."
              rows={4}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCompliance;