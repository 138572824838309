import React from 'react';
import { Outlet } from 'react-router-dom';

const EmployerLayout = () => (
  <div>
    <h1>Employer Dashboard</h1>
    {/* Add User-specific Header or Navigation if needed */}
    <Outlet />
  </div>
);

export default EmployerLayout;
