import React, { useState } from 'react';
import { GraduationCap, BookOpen, Clock, Calendar, Target, Award, Plus, X } from 'lucide-react';

const JobTraining = ({ formData, handleChange }) => {
  const [newTrainingModule, setNewTrainingModule] = useState('');

  const addTrainingModule = () => {
    if (newTrainingModule.trim()) {
      const currentModules = formData.training?.modules || [];
      handleChange('training', 'modules', [...currentModules, {
        name: newTrainingModule.trim(),
        status: 'pending',
        duration: '',
        type: 'technical'
      }]);
      setNewTrainingModule('');
    }
  };

  return (
    <div className="space-y-6">
      {/* Onboarding Program */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <GraduationCap className="h-5 w-5 mr-2 text-blue-600" />
          Onboarding Program
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Program Duration <span className="text-red-500">*</span>
            </label>
            <div className="grid grid-cols-2 gap-4">
              <input
                type="number"
                value={formData.training?.onboarding?.duration || ''}
                onChange={(e) => handleChange('training', 'onboarding', {
                  ...formData.training?.onboarding,
                  duration: e.target.value
                })}
                placeholder="Duration"
                className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              />
              <select
                value={formData.training?.onboarding?.durationUnit || ''}
                onChange={(e) => handleChange('training', 'onboarding', {
                  ...formData.training?.onboarding,
                  durationUnit: e.target.value
                })}
                className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              >
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
                <option value="months">Months</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Training Format
            </label>
            <select
              value={formData.training?.onboarding?.format || ''}
              onChange={(e) => handleChange('training', 'onboarding', {
                ...formData.training?.onboarding,
                format: e.target.value
              })}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="">Select Format</option>
              <option value="in_person">In-Person</option>
              <option value="virtual">Virtual</option>
              <option value="hybrid">Hybrid</option>
              <option value="self_paced">Self-Paced</option>
            </select>
          </div>
        </div>
      </div>

      {/* Required Training Modules */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <BookOpen className="h-5 w-5 mr-2 text-blue-600" />
          Required Training Modules
        </h3>
        <div className="space-y-4">
          <div className="flex space-x-2">
            <input
              type="text"
              value={newTrainingModule}
              onChange={(e) => setNewTrainingModule(e.target.value)}
              placeholder="Add a training module"
              className="flex-1 rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  addTrainingModule();
                }
              }}
            />
            <button
              type="button"
              onClick={addTrainingModule}
              className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus className="h-5 w-5" />
            </button>
          </div>

          <div className="space-y-3">
            {formData.training?.modules?.map((module, index) => (
              <div key={index} className="bg-gray-50 rounded-lg p-4 space-y-3">
                <div className="flex items-center justify-between">
                  <span className="font-medium text-gray-900">{module.name}</span>
                  <button
                    onClick={() => {
                      const currentModules = formData.training?.modules || [];
                      handleChange(
                        'training',
                        'modules',
                        currentModules.filter((_, i) => i !== index)
                      );
                    }}
                    className="text-gray-400 hover:text-red-600"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <select
                    value={module.type}
                    onChange={(e) => {
                      const currentModules = [...(formData.training?.modules || [])];
                      currentModules[index] = { ...module, type: e.target.value };
                      handleChange('training', 'modules', currentModules);
                    }}
                    className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                  >
                    <option value="technical">Technical</option>
                    <option value="soft_skills">Soft Skills</option>
                    <option value="compliance">Compliance</option>
                    <option value="product">Product</option>
                    <option value="process">Process</option>
                  </select>
                  <input
                    type="text"
                    value={module.duration}
                    onChange={(e) => {
                      const currentModules = [...(formData.training?.modules || [])];
                      currentModules[index] = { ...module, duration: e.target.value };
                      handleChange('training', 'modules', currentModules);
                    }}
                    placeholder="Duration (e.g., 2 hours)"
                    className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Learning & Development */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Target className="h-5 w-5 mr-2 text-blue-600" />
          Learning & Development
        </h3>
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Development Resources
              </label>
              <div className="space-y-2">
                {[
                  'Online Courses',
                  'Mentorship Program',
                  'Conference Attendance',
                  'Professional Certifications',
                  'Internal Training Sessions',
                  'External Workshops'
                ].map((resource) => (
                  <label key={resource} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.training?.developmentResources?.includes(resource)}
                      onChange={(e) => {
                        const current = formData.training?.developmentResources || [];
                        const updated = e.target.checked
                          ? [...current, resource]
                          : current.filter(r => r !== resource);
                        handleChange('training', 'developmentResources', updated);
                      }}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-600">{resource}</span>
                  </label>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Learning Budget
              </label>
              <div className="flex space-x-2">
                <span className="inline-flex items-center px-3 rounded-l-lg border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                  $
                </span>
                <input
                  type="number"
                  value={formData.training?.learningBudget || ''}
                  onChange={(e) => handleChange('training', 'learningBudget', e.target.value)}
                  placeholder="Annual budget per employee"
                  className="flex-1 rounded-r-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Evaluation & Progress */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Award className="h-5 w-5 mr-2 text-blue-600" />
          Training Evaluation & Progress
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Evaluation Method
            </label>
            <select
              value={formData.training?.evaluationMethod || ''}
              onChange={(e) => handleChange('training', 'evaluationMethod', e.target.value)}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="">Select Method</option>
              <option value="quiz">Quizzes & Tests</option>
              <option value="practical">Practical Assessment</option>
              <option value="presentation">Presentation</option>
              <option value="project">Project Completion</option>
              <option value="peer_review">Peer Review</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Progress Tracking
            </label>
            <select
              value={formData.training?.progressTracking || ''}
              onChange={(e) => handleChange('training', 'progressTracking', e.target.value)}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="">Select Method</option>
              <option value="weekly">Weekly Check-ins</option>
              <option value="biweekly">Bi-weekly Reviews</option>
              <option value="monthly">Monthly Evaluations</option>
              <option value="completion">Completion Based</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobTraining;