import React, { useState } from 'react';
import { GraduationCap, Calendar, BookOpen, Users, Clock, CheckCircle, Search, Filter, Download } from 'lucide-react';

const EmployerTrainings = () => {
  const [activeView, setActiveView] = useState('ongoing');
  const [searchTerm, setSearchTerm] = useState('');

  const trainings = [
    {
      id: 1,
      worker: "John Smith",
      type: "Technical Onboarding",
      startDate: "2024-03-15",
      endDate: "2024-03-29",
      status: "In Progress",
      progress: 65,
      trainer: "Mike Johnson",
      department: "Engineering"
    },
    {
      id: 2,
      worker: "Sarah Wilson",
      type: "Safety Training",
      startDate: "2024-03-20",
      endDate: "2024-03-22",
      status: "Completed",
      progress: 100,
      trainer: "Lisa Brown",
      department: "Operations"
    }
  ];

  const upcomingTrainings = [
    {
      id: 3,
      name: "Project Management Fundamentals",
      startDate: "2024-04-05",
      participants: 12,
      duration: "3 days",
      trainer: "David Clark",
      status: "Scheduled"
    }
  ];

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Training Management</h1>
        <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
          <Calendar className="h-4 w-4 mr-2" />
          Schedule Training
        </button>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Active Trainings</span>
            <GraduationCap className="h-5 w-5 text-blue-600" />
          </div>
          <div className="text-2xl font-bold">8</div>
          <div className="text-sm text-gray-500">3 ending this week</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Workers in Training</span>
            <Users className="h-5 w-5 text-green-600" />
          </div>
          <div className="text-2xl font-bold">24</div>
          <div className="text-sm text-gray-500">15 Permanent • 9 Temporary</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Completion Rate</span>
            <CheckCircle className="h-5 w-5 text-yellow-600" />
          </div>
          <div className="text-2xl font-bold">92%</div>
          <div className="text-sm text-gray-500">+5% from last month</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Upcoming Sessions</span>
            <Clock className="h-5 w-5 text-purple-600" />
          </div>
          <div className="text-2xl font-bold">5</div>
          <div className="text-sm text-gray-500">Next 7 days</div>
        </div>
      </div>

      {/* Navigation */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex space-x-4">
          {['ongoing', 'upcoming', 'completed'].map((view) => (
            <button
              key={view}
              onClick={() => setActiveView(view)}
              className={`px-4 py-2 rounded-lg ${
                activeView === view ? 'bg-blue-50 text-blue-600' : 'text-gray-600'
              }`}
            >
              {view.charAt(0).toUpperCase() + view.slice(1)}
            </button>
          ))}
        </div>

        <div className="flex space-x-4">
          <div className="relative">
            <Search className="h-5 w-5 absolute left-3 top-2.5 text-gray-400" />
            <input
              type="text"
              placeholder="Search trainings..."
              className="pl-10 pr-4 py-2 border rounded-lg w-64"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button className="flex items-center px-4 py-2 border rounded-lg">
            <Filter className="h-4 w-4 mr-2" />
            Filters
          </button>
          <button className="flex items-center px-4 py-2 border rounded-lg">
            <Download className="h-4 w-4 mr-2" />
            Export
          </button>
        </div>
      </div>

      {/* Training Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        {activeView === 'ongoing' && (
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Worker</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Start Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">End Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Trainer</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Progress</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {trainings.map((training) => (
                <tr key={training.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">{training.worker}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{training.type}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{training.startDate}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{training.endDate}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{training.trainer}</td>
                  <td className="px-6 py-4 text-sm">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      training.status === 'Completed' 
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {training.status}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div 
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${training.progress}%` }}
                      ></div>
                    </div>
                    <span className="text-xs text-gray-500 mt-1">{training.progress}%</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {activeView === 'upcoming' && (
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Training Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Start Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Duration</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Participants</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Trainer</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {upcomingTrainings.map((training) => (
                <tr key={training.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">{training.name}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{training.startDate}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{training.duration}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{training.participants}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{training.trainer}</td>
                  <td className="px-6 py-4 text-sm">
                    <span className="px-2 py-1 rounded-full text-xs bg-blue-100 text-blue-800">
                      {training.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default EmployerTrainings;