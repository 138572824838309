import { useEffect, useState } from "react";



const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        breakpoint: "",
        cards: 0
    })

    const getBreakPoint = (width) => {
        if(width<640) return "base";
        if(width>=640 && width < 768) return "sm";
        if(width>=768 && width <1024) return "md";
        if(width>=1024) return "lg"
    }

    const getCards = (breakpoint) =>{
        if(breakpoint === "base") return 1;
        if(breakpoint === "sm") return 2;
        if(breakpoint === "md") return 3;
        if(breakpoint === "lg") return 4;
    }


    useEffect(()=>{
        const handleResize = () => {
            const width = window.innerWidth;
            const breakpoint = getBreakPoint(width);
            const cards = getCards(breakpoint);

            setWindowSize({
                width,
                breakpoint,
                cards
            })
        }
        

        window.addEventListener("resize",handleResize)
        handleResize()

        return ()=> window.removeEventListener("resize",handleResize)
    },[])

    return windowSize
}

export default useWindowSize

// import { useEffect, useState } from "react";

// const useWindowSize = () => {
//     const [windowSize, setWindowSize] = useState({
//         width: typeof window !== "undefined" ? window.innerWidth : 0,
//         breakpoint: "",
//         cards: 1 // Default to 1 card for initial render (can adjust based on use case)
//     });

//     const getBreakPoint = (width) => {
//         if (width < 640) return "base";
//         if (width >= 640 && width < 768) return "sm";
//         if (width >= 768 && width < 1024) return "md";
//         if (width >= 1024) return "lg";
//     };

//     const getCards = (breakpoint) => {
//         if (breakpoint === "base") return 1;
//         if (breakpoint === "sm") return 2;
//         if (breakpoint === "md") return 3;
//         if (breakpoint === "lg") return 4;
//     };

//     useEffect(() => {
//         // Avoids accessing window on the server
//         if (typeof window === "undefined") return;

//         const handleResize = () => {
//             const width = window.innerWidth;
//             const breakpoint = getBreakPoint(width);
//             const cards = getCards(breakpoint);

//             setWindowSize({
//                 width,
//                 breakpoint,
//                 cards
//             });
//         };

//         window.addEventListener("resize", handleResize);
//         handleResize(); // Initial call on mount

//         return () => window.removeEventListener("resize", handleResize);
//     }, []);

//     return windowSize;
// };

// export default useWindowSize;


// import React, { useState, useEffect } from 'react';
// import { ChevronLeft, ChevronRight } from 'lucide-react';
// import useWindowSize from '../hooks/useWindowSize';
// import { CgCardSpades } from 'react-icons/cg';

// const IndustryList = () => {
//     const { cards } = useWindowSize(); // Get the number of cards based on screen size
//     const industries = [
//         "Construction",
//         "Manufacturing",
//         "Healthcare",
//         "Hospitality and Tourism",
//         "IT",
//         "Maritime",
//         "Logistics and Warehousing",
//         "Oil and Gas",
//         "Tech Startups",
//         "eCommerce"
//     ];

//     const [currentIndex, setCurrentIndex] = useState(0);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             setCurrentIndex((prevIndex) => (prevIndex + 1) % industries.length);
//         }, 3000);

//         return () => clearInterval(interval);
//     }, [industries.length]);

//     const handleNext = () => {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % industries.length);
//     };

//     const handlePrev = () => {
//         setCurrentIndex((prevIndex) => (prevIndex - 1 + industries.length) % industries.length);
//     };

//     return (
//         <div className="w-full max-w-8xl mx-auto p-10 bg-gray-300 border border-red-400">
//             <h2 className="text-3xl font-bold text-center mb-2">Industries We Serve</h2>
//             <h3 className='text-1xl font-medium text-center mt-0 lg:ml-20 lg:mr-20 p-3'>
//                 Unlock your business potential with TalentFirst. As your dedicated staffing partner, we specialize in sourcing and placing exceptional talent across a wide range of industries. With our deep expertise, we're committed to fulfilling your staffing needs and helping you achieve your business goals.
//             </h3>
//             <div className="relative overflow-hidden">
//                 <div className="flex items-center justify-between mb-4">
//                     <button onClick={handlePrev} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
//                         <ChevronLeft size={24} />
//                     </button>
//                     <button onClick={handleNext} className="p-2 rounded-full bg-gray-200 hover:bg-gray-300">
//                         <ChevronRight size={24} />
//                     </button>
//                 </div>
//                 <div
//                     className="flex transition-transform duration-300 ease-in-out"
//                     style={{ transform: `translateX(-${currentIndex * (100 / cards)}%)` }}
//                 >
//                     {industries.concat(industries.slice(0, cards)).map((industry, index) => (
//                         <div
//                             key={index}
//                             className={`w-${100 / cards} flex-shrink-0 px-2`} // Dynamically adjust width based on the number of cards
//                         >
//                             <div className="bg-white p-4 rounded shadow text-center h-24 flex items-center justify-center">
//                                 <p className="font-semibold">{industry}</p>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default IndustryList;
