import React, { useState } from 'react';
import { 
  Users,
  Clock,
  Calendar,
  Video,
  FileText,
  MessageSquare,
  Plus
} from 'lucide-react';

export default function TrainerCourses() {
  const [activeView, setActiveView] = useState('active');

  const courses = {
    active: [
      {
        id: 1,
        title: 'AWS Developer Certification',
        students: 32,
        progress: {
          currentWeek: 4,
          totalWeeks: 12,
          completion: 85,
          attendance: 92,
          assignments: { completed: 12, total: 15 },
          rating: 4.8
        },
        nextSession: 'Today, 2:00 PM',
        schedule: 'Mon/Wed 6-8pm EST'
      },
      {
        id: 2,
        title: 'React Advanced Patterns',
        students: 28,
        progress: {
          currentWeek: 6,
          totalWeeks: 8,
          completion: 78,
          attendance: 88,
          assignments: { completed: 8, total: 10 },
          rating: 4.6
        },
        nextSession: 'Tomorrow, 10:00 AM',
        schedule: 'Tue/Thu 7-9pm EST'
      }
    ],
    upcoming: [
      {
        id: 3,
        title: 'Python for Data Science',
        students: 25,
        startDate: '2024-11-15',
        schedule: 'Mon/Wed 5-7pm EST'
      }
    ],
    completed: [
      {
        id: 4,
        title: 'JavaScript Fundamentals',
        students: 35,
        endDate: '2024-08-30',
        finalStats: {
          completion: 95,
          avgGrade: 88,
          satisfaction: 4.9
        }
      }
    ]
  };

  return (
    <div className="space-y-6">
      {/* Filter */}
      <div className="flex space-x-4 mb-6">
        <button
          onClick={() => setActiveView('active')}
          className={`px-4 py-2 rounded-lg ${
            activeView === 'active'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
        >
          Active ({courses.active.length})
        </button>
        <button
          onClick={() => setActiveView('upcoming')}
          className={`px-4 py-2 rounded-lg ${
            activeView === 'upcoming'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
        >
          Upcoming ({courses.upcoming.length})
        </button>
        <button
          onClick={() => setActiveView('completed')}
          className={`px-4 py-2 rounded-lg ${
            activeView === 'completed'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
        >
          Completed ({courses.completed.length})
        </button>
      </div>

      {/* Course List */}
      <div className="grid grid-cols-1 gap-6">
        {activeView === 'active' && courses.active.map(course => (
          <div key={course.id} className="bg-white rounded-lg shadow">
            <div className="p-6">
              {/* Header */}
              <div className="border-b border-gray-200 pb-4 mb-4">
                <div className="flex justify-between items-start mb-2">
                  <h3 className="text-xl font-semibold">{course.title}</h3>
                  <div className="flex items-center gap-4 text-sm text-gray-600">
                    <span className="flex items-center gap-1">
                      <Users className="w-4 h-4" />
                      {course.students} Students
                    </span>
                    <span className="flex items-center gap-1">
                      <Clock className="w-4 h-4" />
                      Week {course.progress.currentWeek} of {course.progress.totalWeeks}
                    </span>
                    <span className="flex items-center gap-1">
                      <Calendar className="w-4 h-4" />
                      {course.schedule}
                    </span>
                  </div>
                </div>
              </div>

              {/* Progress Stats */}
              <div className="grid grid-cols-4 gap-4 mb-6">
                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="text-sm text-gray-600">Completion Rate</p>
                  <p className="text-lg font-semibold">{course.progress.completion}%</p>
                </div>
                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="text-sm text-gray-600">Attendance</p>
                  <p className="text-lg font-semibold">{course.progress.attendance}%</p>
                </div>
                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="text-sm text-gray-600">Assignments</p>
                  <p className="text-lg font-semibold">
                    {course.progress.assignments.completed}/{course.progress.assignments.total}
                  </p>
                </div>
                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="text-sm text-gray-600">Rating</p>
                  <p className="text-lg font-semibold">{course.progress.rating}/5.0</p>
                </div>
              </div>

              {/* Actions */}
              <div className="grid grid-cols-4 gap-4">
                <button className="flex flex-col items-center justify-center p-4 bg-blue-50 rounded-lg text-blue-600 hover:bg-blue-100">
                  <Video className="w-6 h-6 mb-2" />
                  <span className="text-sm">Start Session</span>
                </button>
                <button className="flex flex-col items-center justify-center p-4 bg-purple-50 rounded-lg text-purple-600 hover:bg-purple-100">
                  <FileText className="w-6 h-6 mb-2" />
                  <span className="text-sm">Grade Tasks</span>
                </button>
                <button className="flex flex-col items-center justify-center p-4 bg-green-50 rounded-lg text-green-600 hover:bg-green-100">
                  <Plus className="w-6 h-6 mb-2" />
                  <span className="text-sm">Add Material</span>
                </button>
                <button className="flex flex-col items-center justify-center p-4 bg-orange-50 rounded-lg text-orange-600 hover:bg-orange-100">
                  <MessageSquare className="w-6 h-6 mb-2" />
                  <span className="text-sm">Messages</span>
                </button>
              </div>
            </div>
          </div>
        ))}

        {activeView === 'upcoming' && courses.upcoming.map(course => (
          <div key={course.id} className="bg-white rounded-lg shadow">
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
                  <div className="flex items-center gap-4 text-sm text-gray-600">
                    <span className="flex items-center gap-1">
                      <Users className="w-4 h-4" />
                      {course.students} Students
                    </span>
                    <span className="flex items-center gap-1">
                      <Calendar className="w-4 h-4" />
                      {course.schedule}
                    </span>
                  </div>
                </div>
                <div className="text-right">
                  <p className="text-sm text-gray-600">Starts on</p>
                  <p className="font-medium">{new Date(course.startDate).toLocaleDateString()}</p>
                </div>
              </div>
              <button className="w-full mt-4 p-3 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100">
                Prepare Course Material
              </button>
            </div>
          </div>
        ))}

        {activeView === 'completed' && courses.completed.map(course => (
          <div key={course.id} className="bg-white rounded-lg shadow">
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
                  <div className="flex items-center gap-4 text-sm text-gray-600">
                    <span className="flex items-center gap-1">
                      <Users className="w-4 h-4" />
                      {course.students} Students
                    </span>
                    <span className="flex items-center gap-1">
                      <Calendar className="w-4 h-4" />
                      Completed on {new Date(course.endDate).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-4 mt-4">
                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="text-sm text-gray-600">Completion Rate</p>
                  <p className="text-lg font-semibold">{course.finalStats.completion}%</p>
                </div>
                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="text-sm text-gray-600">Average Grade</p>
                  <p className="text-lg font-semibold">{course.finalStats.avgGrade}%</p>
                </div>
                <div className="bg-gray-50 p-3 rounded-lg">
                  <p className="text-sm text-gray-600">Satisfaction</p>
                  <p className="text-lg font-semibold">{course.finalStats.satisfaction}/5.0</p>
                </div>
              </div>

              <button className="w-full mt-4 p-3 bg-gray-50 text-gray-600 rounded-lg hover:bg-gray-100">
                View Course Analytics
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}