import React, { useState } from 'react';
import { Users, Search, Filter, Download, Briefcase, Clock, DollarSign, GraduationCap } from 'lucide-react';

const EmployerWorkers = () => {
  const [workerType, setWorkerType] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  const workers = [
    {
      id: 1,
      name: "John Smith",
      type: "Permanent",
      position: "Senior Software Engineer",
      department: "Engineering",
      startDate: "2024-01-15",
      salary: 95000,
      status: "Active",
      trainingCompleted: true,
      location: "Remote",
      supervisor: "Jane Manager"
    },
    {
      id: 2,
      name: "Sarah Wilson",
      type: "Temporary",
      position: "Project Manager",
      department: "Operations",
      startDate: "2024-03-01",
      hourlyRate: 75,
      status: "Training",
      trainingCompleted: false,
      location: "Hybrid",
      supervisor: "Mike Director",
      contractEnd: "2024-09-01"
    }
  ];

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Workers Management</h1>
        <div className="flex space-x-4">
          <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
            Import Workers
          </button>
        </div>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Total Workers</span>
            <Users className="h-5 w-5 text-blue-600" />
          </div>
          <div className="text-2xl font-bold">45</div>
          <div className="text-sm text-gray-500">32 Permanent • 13 Temporary</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">In Training</span>
            <GraduationCap className="h-5 w-5 text-orange-600" />
          </div>
          <div className="text-2xl font-bold">8</div>
          <div className="text-sm text-gray-500">Training completion: 85%</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Contract Ending</span>
            <Clock className="h-5 w-5 text-yellow-600" />
          </div>
          <div className="text-2xl font-bold">5</div>
          <div className="text-sm text-gray-500">Next 30 days</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Monthly Cost</span>
            <DollarSign className="h-5 w-5 text-green-600" />
          </div>
          <div className="text-2xl font-bold">$284.5k</div>
          <div className="text-sm text-gray-500">+2.5% from last month</div>
        </div>
      </div>

      {/* Filters and Search */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex space-x-2">
          {['all', 'permanent', 'temporary', 'training'].map((type) => (
            <button
              key={type}
              onClick={() => setWorkerType(type)}
              className={`px-3 py-1 rounded-full text-sm ${
                workerType === type 
                  ? 'bg-blue-100 text-blue-600' 
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </button>
          ))}
        </div>

        <div className="flex space-x-4">
          <div className="relative">
            <Search className="h-5 w-5 absolute left-3 top-2.5 text-gray-400" />
            <input
              type="text"
              placeholder="Search workers..."
              className="pl-10 pr-4 py-2 border rounded-lg w-64"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button className="flex items-center px-4 py-2 border rounded-lg">
            <Filter className="h-4 w-4 mr-2" />
            Filters
          </button>
          <button className="flex items-center px-4 py-2 border rounded-lg">
            <Download className="h-4 w-4 mr-2" />
            Export
          </button>
        </div>
      </div>

      {/* Workers Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Position</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Department</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Location</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Start Date</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Compensation</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {workers.map((worker) => (
              <tr key={worker.id} className="hover:bg-gray-50 cursor-pointer">
                <td className="px-6 py-4 text-sm font-medium text-gray-900">{worker.name}</td>
                <td className="px-6 py-4 text-sm text-gray-500">{worker.position}</td>
                <td className="px-6 py-4 text-sm text-gray-500">{worker.type}</td>
                <td className="px-6 py-4 text-sm text-gray-500">{worker.department}</td>
                <td className="px-6 py-4 text-sm">
                  <span className={`px-2 py-1 rounded-full text-xs ${
                    worker.status === 'Active' 
                      ? 'bg-green-100 text-green-800'
                      : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {worker.status}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">{worker.location}</td>
                <td className="px-6 py-4 text-sm text-gray-500">{worker.startDate}</td>
                <td className="px-6 py-4 text-sm text-gray-500 text-right">
                  {worker.salary 
                    ? `$${worker.salary.toLocaleString()}/yr`
                    : `$${worker.hourlyRate}/hr`
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployerWorkers;