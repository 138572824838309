import React from 'react';

const JobDetailsSection = ({ formData, handleChange }) => {
  return (
    <div className="space-y-6">
      {/* Job Identification */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Job Identification</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Job Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.jobDetails.title}
              onChange={(e) => handleChange('jobDetails', 'title', e.target.value)}
              placeholder={"random job"} //select random job based on hiring profile
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Department <span className="text-red-500">*</span>
            </label>
            <select
              value={formData.jobDetails.department}
              onChange={(e) => handleChange('jobDetails', 'department', e.target.value)}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="">Select Department</option>
              <option value="engineering">Engineering</option>
              <option value="product">Product</option>
              <option value="design">Design</option>
              <option value="marketing">Marketing</option>
              <option value="sales">Sales</option>
              <option value="operations">Operations</option>
              <option value="hr">Human Resources</option>
              <option value="finance">Finance</option>
            </select>
          </div>
        </div>
      </div>

      {/* Employment Details */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Employment Details</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Employment Type <span className="text-red-500">*</span>
              </label>
              <select
                value={formData.jobDetails.employmentType}
                onChange={(e) => handleChange('jobDetails', 'employmentType', e.target.value)}
                className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Type</option>
                <option value="full_time">Full Time</option>
                <option value="part_time">Part Time</option>
                <option value="contract">Contract</option>
                <option value="temporary">Temporary</option>
                <option value="internship">Internship</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Job Type <span className="text-red-500">*</span>
              </label>
              <select
                value={formData.jobDetails.jobType}
                onChange={(e) => handleChange('jobDetails', 'jobType', e.target.value)}
                className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Type</option>
                <option value="permanent">Permanent</option>
                <option value="temporary">Temporary</option>
              </select>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Number of Openings <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                value={formData.jobDetails.numberOfOpenings}
                onChange={(e) => handleChange('jobDetails', 'numberOfOpenings', e.target.value)}
                placeholder="e.g., 1"
                min="1"
                className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Urgency
              </label>
              <select
                value={formData.jobDetails.urgency}
                onChange={(e) => handleChange('jobDetails', 'urgency', e.target.value)}
                className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Urgency</option>
                <option value="immediate">Immediate</option>
                <option value="within_month">Within 1 Month</option>
                <option value="within_quarter">Within Quarter</option>
                <option value="flexible">Flexible</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* Work Arrangement */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-4">Work Arrangement</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Work Mode <span className="text-red-500">*</span>
            </label>
            <select
              value={formData.jobDetails.workMode}
              onChange={(e) => handleChange('jobDetails', 'workMode', e.target.value)}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="">Select Work Mode</option>
              <option value="on_site">On-site</option>
              <option value="hybrid">Hybrid</option>
              <option value="remote">Remote</option>
            </select>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={formData.jobDetails.startDate}
                onChange={(e) => handleChange('jobDetails', 'startDate', e.target.value)}
                className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              />
            </div>
            
            {(formData.jobDetails.jobType === 'temporary' || 
              formData.jobDetails.employmentType === 'contract') && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Duration (months)
                </label>
                <input
                  type="number"
                  value={formData.jobDetails.duration}
                  onChange={(e) => handleChange('jobDetails', 'duration', e.target.value)}
                  placeholder="e.g., 6"
                  min="1"
                  className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsSection;