
// const Hero = () => {
//     return (
//         <section className="relative h-screen bg-white">
//             <div className="container mx-auto px-6 h-full flex items-center justify-center">
//                 <div className="text-center">
//                     <h1 className="text-5xl font-bold mb-4 text-gray-800">Build Your Team with Confidence</h1>
//                     <p className="text-xl mb-8 text-gray-600">Let us handle hiring qualified talent globally so you can focus on growth.</p>
//                     <a href="#" className="bg-custom-blue text-white py-3 px-6 rounded-lg hover:bg-custome-gray transition duration-300">Submit Requirements</a>
//                 </div>
//             </div>
//         </section>
//     );
// };

// // export default Hero;
// import React, { useState } from 'react';
// import videoFile from '../assets/videoHero.mp4';
// import FormModal from './FormModal';

// const Hero = () => {
//     const [isFormOpen, setIsFormOpen] = useState(false);

//     const openFormModal = () => {
//         console.log("Opening modal");
//         setIsFormOpen(true);
//     };

//     const closeFormModal = () => {
//         console.log("Closing modal");
//         setIsFormOpen(false);
//     };

//     const googleFormLink = 'https://forms.gle/3A5HELdYoEfLyCRHA';

//     return (
//         <section className="relative h-screen bg-white m-10 mt-28 overflow-hidden rounded-3xl">
//             {/* Video Background */}
//             <video
//                 className="absolute top-0 left-0 w-full h-full object-cover z-0 rounded-3xl"
//                 src={videoFile}
//                 autoPlay
//                 loop
//                 muted
//                 playsInline
//             />

//             {/* Overlay */}
//             <div className="absolute inset-0 bg-black opacity-70 z-10 rounded-3xl"></div>

//             {/* Content */}
//             <div className="container mx-auto px-6 h-full flex items-center justify-center relative z-20">
//                 <div className="text-center text-white">
//                     <h1 className="text-6xl font-bold mb-4">Build Your Team with Confidence</h1>
//                     <p className="text-xl mb-8">Let us handle hiring qualified talent globally so you can focus on growth.</p>
//                     <button
//                         onClick={openFormModal}
//                         className="bg-white border-custom-blue border-2 text-custom-blue font-normal py-3 px-6 rounded-lg hover:bg-gray-100 transition duration-300"
//                     >
//                         Submit Requirements
//                     </button>
//                 </div>
//             </div>

//             {/* Form Modal */}
//             <FormModal isOpen={isFormOpen} onClose={closeFormModal} formUrl={googleFormLink} />
//         </section>
//     );
// };

// export default Hero;


import React, { useEffect } from 'react';
import videoFile from '../assets/videoHero.mp4';

const Hero = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);
    const googleFormLink = 'https://forms.gle/3A5HELdYoEfLyCRHA'; 

    return (
        <section className="relative w-auto xs:w-auto h-screen bg-white m-10 mt-28 overflow-hidden rounded-3xl">
            {/* Video Background */}
            <video
                className="absolute top-0 left-0 w-full h-full object-cover z-0 rounded-3xl"
                src={videoFile}
                autoPlay
                loop
                muted
                playsInline
            />

            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-70 z-10 rounded-3xl"></div>

            {/* Content */}
            <div className="container mx-auto px-6 h-full flex items-center justify-center relative z-20">
                <div className="text-center text-white">
                    <h1 className="sm:text-5xl md:text-6xl text-5xl font-bold mb-4">Build Your Team with Confidence</h1>
                    <p className="text-xl mb-8">Let us handle hiring qualified talent globally so you can focus on growth.</p>
                    {/* Link to Google Form */}
                    <a
                        href={googleFormLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-white border-custom-blue border-2 text-custom-blue font-normal py-3 px-6 rounded-lg hover:bg-gray-100 transition duration-300"
                    >
                        Submit Requirements
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Hero;
