import React from 'react';
import { DollarSign, Gift, Briefcase, Award } from 'lucide-react';

const JobCompensation = ({ formData, handleChange }) => {
  return (
    <div className="space-y-6">
      {/* Salary Range */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <DollarSign className="h-5 w-5 mr-2 text-blue-600" />
          Compensation Range
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Minimum Salary <span className="text-red-500">*</span>
            </label>
            <div className="flex">
              <span className="inline-flex items-center px-3 rounded-l-lg border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                $
              </span>
              <input
                type="number"
                value={formData.compensation.salaryMin}
                onChange={(e) => handleChange('compensation', 'salaryMin', e.target.value)}
                placeholder="e.g., 50000"
                className="w-full rounded-r-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Maximum Salary <span className="text-red-500">*</span>
            </label>
            <div className="flex">
              <span className="inline-flex items-center px-3 rounded-l-lg border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                $
              </span>
              <input
                type="number"
                value={formData.compensation.salaryMax}
                onChange={(e) => handleChange('compensation', 'salaryMax', e.target.value)}
                placeholder="e.g., 80000"
                className="w-full rounded-r-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Pay Period <span className="text-red-500">*</span>
            </label>
            <select
              value={formData.compensation.payPeriod}
              onChange={(e) => handleChange('compensation', 'payPeriod', e.target.value)}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            >
              <option value="">Select Period</option>
              <option value="hourly">Per Hour</option>
              <option value="monthly">Per Month</option>
              <option value="yearly">Per Year</option>
            </select>
          </div>
        </div>
      </div>

      {/* Benefits Package */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Gift className="h-5 w-5 mr-2 text-blue-600" />
          Benefits Package
        </h3>
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Healthcare Benefits
              </label>
              <div className="space-y-2">
                {['Medical Insurance', 'Dental Insurance', 'Vision Insurance', 'Life Insurance'].map((benefit) => (
                  <label key={benefit} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.compensation.benefits?.healthcare?.includes(benefit)}
                      onChange={(e) => {
                        const currentBenefits = formData.compensation.benefits?.healthcare || [];
                        const newBenefits = e.target.checked
                          ? [...currentBenefits, benefit]
                          : currentBenefits.filter(b => b !== benefit);
                        handleChange('compensation', 'benefits', {
                          ...formData.compensation.benefits,
                          healthcare: newBenefits
                        });
                      }}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-600">{benefit}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Time Off Benefits
              </label>
              <div className="space-y-2">
                {['Paid Time Off', 'Sick Leave', 'Parental Leave', 'Paid Holidays'].map((benefit) => (
                  <label key={benefit} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.compensation.benefits?.timeOff?.includes(benefit)}
                      onChange={(e) => {
                        const currentBenefits = formData.compensation.benefits?.timeOff || [];
                        const newBenefits = e.target.checked
                          ? [...currentBenefits, benefit]
                          : currentBenefits.filter(b => b !== benefit);
                        handleChange('compensation', 'benefits', {
                          ...formData.compensation.benefits,
                          timeOff: newBenefits
                        });
                      }}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-600">{benefit}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Compensation */}
      <div className="border-b pb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Award className="h-5 w-5 mr-2 text-blue-600" />
          Additional Compensation
        </h3>
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Bonuses */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Bonus Structure
              </label>
              <div className="space-y-2">
                {['Performance Bonus', 'Signing Bonus', 'Annual Bonus', 'Commission'].map((bonus) => (
                  <label key={bonus} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.compensation.bonuses?.includes(bonus)}
                      onChange={(e) => {
                        const currentBonuses = formData.compensation.bonuses || [];
                        const newBonuses = e.target.checked
                          ? [...currentBonuses, bonus]
                          : currentBonuses.filter(b => b !== bonus);
                        handleChange('compensation', 'bonuses', newBonuses);
                      }}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-600">{bonus}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* Equity */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Equity Compensation
              </label>
              <div className="space-y-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.compensation.hasEquity}
                    onChange={(e) => handleChange('compensation', 'hasEquity', e.target.checked)}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">Equity package available</span>
                </div>
                {formData.compensation.hasEquity && (
                  <textarea
                    value={formData.compensation.equityDetails}
                    onChange={(e) => handleChange('compensation', 'equityDetails', e.target.value)}
                    placeholder="Describe the equity package details..."
                    rows={3}
                    className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Benefits */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <Briefcase className="h-5 w-5 mr-2 text-blue-600" />
          Additional Benefits
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Professional Development
            </label>
            <div className="space-y-2">
              {[
                'Training Budget',
                'Conference Allowance',
                'Education Reimbursement',
                'Certification Support'
              ].map((benefit) => (
                <label key={benefit} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.compensation.benefits?.professional?.includes(benefit)}
                    onChange={(e) => {
                      const currentBenefits = formData.compensation.benefits?.professional || [];
                      const newBenefits = e.target.checked
                        ? [...currentBenefits, benefit]
                        : currentBenefits.filter(b => b !== benefit);
                      handleChange('compensation', 'benefits', {
                        ...formData.compensation.benefits,
                        professional: newBenefits
                      });
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-600">{benefit}</span>
                </label>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Additional Notes
            </label>
            <textarea
              value={formData.compensation.additionalNotes}
              onChange={(e) => handleChange('compensation', 'additionalNotes', e.target.value)}
              placeholder="Any additional compensation or benefits details..."
              rows={4}
              className="w-full rounded-lg border px-3 py-2 focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCompensation;