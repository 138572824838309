import React, { useState, useMemo } from 'react';
import { Check, Clock, Briefcase, Building2, ArrowLeft, Award, BookOpen, Star } from 'lucide-react';
import SlidingPanel from './SlidingPanel';
import CertificationCard from './CertificationCard';
import certificationData from '../../data/certificationData.json';

const SPECIALIZATIONS = {
  manufacturing: {
    name: "Manufacturing",
    subCategories: {
      automotive: "Automotive Manufacturing",
      electricVehicles: "Electric Vehicles",
      drones: "Drone Manufacturing",
      greenEnergy: "Green Energy Manufacturing",
      chip: "Semiconductor & Chip Manufacturing"
    }
  }
};

const CertificationView = () => {
  const [showPanel, setShowPanel] = useState(false);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  // Memoized filtered certifications
  const filteredCertifications = useMemo(() => {
    let filtered = certificationData.certificationData;

    if (selectedCategory) {
      filtered = filtered.filter(cert => cert.category === selectedCategory);
    }

    if (selectedSubCategory) {
      filtered = filtered.filter(cert => cert.subCategory === selectedSubCategory);
    }

    return filtered;
  }, [selectedCategory, selectedSubCategory]);

  const handleViewDetails = (certification) => {
    setSelectedCertification(certification);
    setShowPanel(true);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    setSelectedSubCategory(""); // Reset subcategory when category changes
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="mb-8">
          <div className="flex items-center gap-2 mb-2">
            <Award className="h-8 w-8 text-blue-600" />
            <h1 className="text-3xl font-bold text-gray-900">Certifications</h1>
          </div>
          <p className="text-lg text-gray-600">
            Explore industry-recognized certifications and advance your career
          </p>
        </div>

        {/* Filters Section */}
        <div className="mb-8 bg-white p-4 rounded-lg shadow-sm border border-gray-200">
          <div className="flex flex-col sm:flex-row gap-4">
            {/* Category Filter */}
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Category
              </label>
              <select
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">All Categories</option>
                {Object.entries(SPECIALIZATIONS).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Subcategory Filter */}
            {selectedCategory && (
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Specialization
                </label>
                <select
                  value={selectedSubCategory}
                  onChange={(e) => setSelectedSubCategory(e.target.value)}
                  className="w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="">All Specializations</option>
                  {Object.entries(SPECIALIZATIONS[selectedCategory].subCategories).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          {/* Active Filters */}
          {(selectedCategory || selectedSubCategory) && (
            <div className="mt-4 flex flex-wrap gap-2">
              {selectedCategory && (
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                  {SPECIALIZATIONS[selectedCategory].name}
                  <button
                    onClick={() => {
                      setSelectedCategory("");
                      setSelectedSubCategory("");
                    }}
                    className="ml-2 inline-flex text-blue-400 hover:text-blue-600"
                  >
                    ×
                  </button>
                </span>
              )}
              {selectedSubCategory && (
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                  {SPECIALIZATIONS[selectedCategory].subCategories[selectedSubCategory]}
                  <button
                    onClick={() => setSelectedSubCategory("")}
                    className="ml-2 inline-flex text-blue-400 hover:text-blue-600"
                  >
                    ×
                  </button>
                </span>
              )}
            </div>
          )}
        </div>

        {/* Results Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCertifications.length > 0 ? (
            filteredCertifications.map((cert) => (
              <CertificationCard 
                key={cert.id} 
                certification={cert} 
                onViewDetails={handleViewDetails} 
              />
            ))
          ) : (
            <div className="col-span-full text-center py-12">
              <BookOpen className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No certifications found</h3>
              <p className="mt-1 text-sm text-gray-500">
                Try adjusting your filters or checking back later for new certifications.
              </p>
            </div>
          )}
        </div>
      </div>

      <SlidingPanel 
        certification={selectedCertification} 
        isOpen={showPanel} 
        onClose={() => setShowPanel(false)} 
      />
    </div>
  );
};

export default CertificationView;