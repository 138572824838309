import React from 'react'
import Hero from '../components/hero'
import AboutUsBrief from '../components/AboutUsBrief'
import Services from '../components/Services'
import Benefits from '../components/Benefits'
import IndustryList from '../components/IndustryList'
import TrainingShowcase from '../components/TrainingShowcase'


const Home = () => {
    return (
        <div>
            <Hero />
            <AboutUsBrief />
            <Services />
            <Benefits />
            <IndustryList />
            <TrainingShowcase />

        </div>
    )
}

export default Home