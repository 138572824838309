import TeamCard from '../components/TeamCard';
import bryanImage from '../assets/bryan-photo.JPG';
import sisterImage from '../assets/WhatsApp Image 2024-09-24 at 02.04.20.jpeg';

const About = () => {

    return (
        <div className="min-h-screen py-10 m-20">
            <div className="container mx-auto px-4">
                <div className="bg-white shadow-md rounded-lg p-8">
                    <h1 className="text-4xl font-bold text-custom-blue mb-6 text-center sm:text-left">About Us</h1>
                    <p className="text-gray-700 text-lg text-justify mb-4">
                        Welcome to TalentFirst, where we connect top talent with leading companies around the world. Our mission is to bridge the gap between skilled professionals and global opportunities, ensuring that businesses have access to the best talent available.
                    </p>
                    <p className="text-gray-700 text-lg text-justify mb-4">
                        Founded in 2024, TalentFirst has been dedicated to providing exceptional recruiting services that are tailored to the unique needs of each client. Our team is passionate about helping companies grow and succeed by finding the right people for the right roles.
                    </p>

                    <h2 className="text-2xl font-bold text-custom-blue mb-4 text-center sm:text-left">Our Team</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {/* Bryan's TeamCard with contact details */}
                        <TeamCard
                            image={bryanImage}
                            name="Bryan Rodrigeus"
                            title="Founder & CEO"
                            description="Bryan is the visionary behind TalentFirst, with a passion for connecting talent with opportunities. His expertise in global recruitment helps businesses find the right people to drive success."
                            email="bryan@talentfirst.global"
                            // linkedin="https://www.linkedin.com/in/bryanrodrigeus"
                            imageHeight="40"
                        />
                        {/* Sister's TeamCard with contact details */}
                        <TeamCard
                            image={sisterImage}
                            name="Chrysilla Rodrigues"
                            title="CTO"
                            description="An accomplished software engineer at Netflix, she brings her expertise in technology and innovation to TalentFirst, helping to bridge the gap between talent and opportunities through advanced software solutions."
                            email="chrysilla@talentfirstt.global"
                            // linkedin="https://www.linkedin.com/in/sister-profile"
                            imageHeight="40"
                        />
                    </div>

                    <h2 className="text-2xl font-bold text-custom-blue mb-4 mt-8 text-center sm:text-left">Our Vision</h2>
                    <p className="text-gray-700 text-lg text-justify mb-4">
                        Our vision is to be the leading provider of global recruitment solutions, empowering businesses to achieve their goals and professionals to reach their full potential. We believe in creating opportunities that lead to a brighter future for both employers and employees.
                    </p>
                    <h2 className="text-2xl font-bold text-custom-blue mb-4 text-center sm:text-left">Our Values</h2>
                    <ul className="list-disc list-inside text-gray-700 text-lg mb-4 text-justify">
                        <li>Integrity: We uphold the highest standards of integrity in all of our actions.</li>
                        <li>Excellence: We strive for excellence in everything we do.</li>
                        <li>Collaboration: We work together to achieve the best results for our clients and candidates.</li>
                        <li>Innovation: We embrace change and continuously seek new ways to improve our services.</li>
                        <li>Respect: We respect and value the diverse backgrounds and perspectives of our team members, clients, and candidates.</li>
                    </ul>
                    <p className="text-gray-700 text-lg mb-4">
                        Thank you for choosing TalentFirst. We look forward to working with you and helping you achieve your recruitment goals.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default About;
