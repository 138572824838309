import React, { useState } from 'react';
import { 
  User, Award, BookOpen, Briefcase, MapPin, 
  Phone, Mail, Calendar, Globe2, FileText,
  Clock, Star, Shield 
} from 'lucide-react';

export const TalentProfile = () => {
  const [profile] = useState({
    personal: {
      name: "Sarah Johnson",
      title: "Registered Nurse, ICU Specialist",
      email: "sarah.johnson@email.com",
      phone: "+1 (555) 123-4567",
      location: "Boston, MA",
      yearsOfExperience: 8,
      availableFrom: "Immediate",
      willingToRelocate: true
    },
    certifications: [
      {
        name: "Critical Care Registered Nurse (CCRN)",
        issuer: "American Association of Critical-Care Nurses",
        validUntil: "2025",
        verified: true
      },
      {
        name: "Advanced Cardiac Life Support (ACLS)",
        issuer: "American Heart Association",
        validUntil: "2024",
        verified: true
      },
      {
        name: "Pediatric Advanced Life Support (PALS)",
        issuer: "American Heart Association",
        validUntil: "2024",
        verified: true
      }
    ],
    education: [
      {
        degree: "Master of Science in Nursing",
        institution: "Boston College",
        year: "2018",
        specialization: "Critical Care"
      },
      {
        degree: "Bachelor of Science in Nursing",
        institution: "University of Massachusetts",
        year: "2015"
      }
    ],
    experience: [
      {
        role: "Senior ICU Nurse",
        hospital: "Massachusetts General Hospital",
        duration: "2019 - Present",
        responsibilities: [
          "Lead critical care team of 8 nurses",
          "Manage complex patient cases in 20-bed ICU",
          "Implement new patient care protocols"
        ]
      },
      {
        role: "ICU Nurse",
        hospital: "Beth Israel Deaconess Medical Center",
        duration: "2015 - 2019",
        responsibilities: [
          "Provided critical care to patients",
          "Coordinated with interdisciplinary teams",
          "Mentored new nursing staff"
        ]
      }
    ],
    careerPreferences: {
      desiredRole: "ICU Department Head",
      preferredLocations: ["Boston", "New York", "San Francisco"],
      expectedSalary: "$120,000 - $150,000",
      preferredShift: "Day Shift",
      workplacePreference: "Hybrid"
    },
    skills: [
      { name: "Critical Care", level: "Expert" },
      { name: "Team Leadership", level: "Advanced" },
      { name: "Patient Assessment", level: "Expert" },
      { name: "Ventilator Management", level: "Advanced" },
      { name: "Emergency Response", level: "Expert" }
    ]
  });

  return (
    <div className="space-y-6">
      {/* Personal Information */}
      <div className="bg-white rounded-lg border p-6">
        <div className="flex items-start justify-between">
          <div className="flex-1">
            <h2 className="text-2xl font-semibold">{profile.personal.name}</h2>
            <p className="text-gray-600 mt-1">{profile.personal.title}</p>
            <div className="mt-4 space-y-2">
              <div className="flex items-center text-gray-600">
                <Mail className="w-4 h-4 mr-2" />
                <span>{profile.personal.email}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <Phone className="w-4 h-4 mr-2" />
                <span>{profile.personal.phone}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <MapPin className="w-4 h-4 mr-2" />
                <span>{profile.personal.location}</span>
              </div>
            </div>
          </div>
          <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
            Edit Profile
          </button>
        </div>
      </div>

      {/* Career Preferences */}
      <div className="bg-white rounded-lg border p-6">
        <h3 className="text-lg font-semibold mb-4">Career Preferences</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <div className="flex items-center">
              <Briefcase className="w-4 h-4 mr-2 text-gray-600" />
              <span className="text-gray-600">Desired Role:</span>
              <span className="ml-2 font-medium">{profile.careerPreferences.desiredRole}</span>
            </div>
            <div className="flex items-center">
              <MapPin className="w-4 h-4 mr-2 text-gray-600" />
              <span className="text-gray-600">Preferred Locations:</span>
              <span className="ml-2 font-medium">{profile.careerPreferences.preferredLocations.join(", ")}</span>
            </div>
          </div>
          <div className="space-y-2">
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-2 text-gray-600" />
              <span className="text-gray-600">Preferred Shift:</span>
              <span className="ml-2 font-medium">{profile.careerPreferences.preferredShift}</span>
            </div>
            <div className="flex items-center">
              <Globe2 className="w-4 h-4 mr-2 text-gray-600" />
              <span className="text-gray-600">Workplace:</span>
              <span className="ml-2 font-medium">{profile.careerPreferences.workplacePreference}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Skills */}
      <div className="bg-white rounded-lg border p-6">
        <h3 className="text-lg font-semibold mb-4">Skills & Expertise</h3>
        <div className="flex flex-wrap gap-2">
          {profile.skills.map((skill, index) => (
            <div 
              key={index}
              className="px-3 py-1 bg-blue-50 border border-blue-100 rounded-full flex items-center"
            >
              <span className="font-medium text-blue-700">{skill.name}</span>
              <span className="ml-2 text-sm text-blue-500">• {skill.level}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Certifications */}
      <div className="bg-white rounded-lg border p-6">
        <h3 className="text-lg font-semibold mb-4">Certifications</h3>
        <div className="space-y-4">
          {profile.certifications.map((cert, index) => (
            <div key={index} className="flex items-start justify-between border-b last:border-0 pb-4 last:pb-0">
              <div>
                <div className="flex items-center">
                  <h4 className="font-medium">{cert.name}</h4>
                  {cert.verified && (
                    <Shield className="w-4 h-4 ml-2 text-green-500" />
                  )}
                </div>
                <p className="text-gray-600 text-sm mt-1">{cert.issuer}</p>
              </div>
              <span className="text-sm text-gray-500">Valid until {cert.validUntil}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Experience */}
      <div className="bg-white rounded-lg border p-6">
        <h3 className="text-lg font-semibold mb-4">Work Experience</h3>
        <div className="space-y-6">
          {profile.experience.map((exp, index) => (
            <div key={index} className="border-b last:border-0 pb-6 last:pb-0">
              <h4 className="font-medium">{exp.role}</h4>
              <p className="text-gray-600">{exp.hospital}</p>
              <p className="text-sm text-gray-500 mt-1">{exp.duration}</p>
              <ul className="mt-2 space-y-1">
                {exp.responsibilities.map((resp, idx) => (
                  <li key={idx} className="text-gray-600 text-sm flex items-start">
                    <span className="mr-2">•</span>
                    {resp}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* Education */}
      <div className="bg-white rounded-lg border p-6">
        <h3 className="text-lg font-semibold mb-4">Education</h3>
        <div className="space-y-4">
          {profile.education.map((edu, index) => (
            <div key={index} className="border-b last:border-0 pb-4 last:pb-0">
              <h4 className="font-medium">{edu.degree}</h4>
              <p className="text-gray-600">{edu.institution}</p>
              <div className="flex items-center mt-1">
                <Calendar className="w-4 h-4 mr-2 text-gray-500" />
                <span className="text-gray-500">{edu.year}</span>
                {edu.specialization && (
                  <>
                    <span className="mx-2">•</span>
                    <span className="text-gray-500">{edu.specialization}</span>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};