import React, { useState } from 'react';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom'; 
import { useAuth } from '../authentication/AuthContext';

const TrainingLogin = () => {
  const { login } = useAuth(); 
  const [username, setUsername] = useState(''); 
  const [password, setPassword] = useState(''); 
  const [error, setError] = useState(''); 
  const navigate = useNavigate(); 
  const apiUrl = process.env.REACT_APP_API_URL

  // Function to call the backend API for login
  const loginUser = async (username, password) => {
    try {
      const response = await axios.post(`${apiUrl}/app3/api/auth/login`, { username, password }); 
      return response.data; 
    } catch (error) {
      throw new Error('Login failed: ' + error.response.data.message); 
    }
  };

  // Handle login button click
  const handleLogin = async (e) => {
    e.preventDefault(); 
    setError(''); 

    try {
      const { user, token } = await loginUser(username, password); // Call the API function
      await login(user, token); // Update the context with user data and token
      console.log(user.role) // for debugging the role
      navigate('/training/admin'); // Redirect to the admin page (or any other page)
    } catch (error) {
      setError(error.message); // Set error message from API response
    }
  };

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 h-screen">
      <div className="bg-black flex items-center justify-center">
        <h1 className="text-white text-3xl">Welcome to Training</h1>
      </div>
      <div className="bg-slate-500 flex items-center justify-center">
        <form onSubmit={handleLogin} className="bg-white p-8 rounded shadow-md w-full max-w-md">
          <h2 className="text-xl font-bold mb-4">Login</h2>
          {error && <p className="text-red-500 mb-4">{error}</p>} {/* Show error message */}
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
            <input
              type="email"
              id="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)} // Update email state
              required
              className="border border-gray-300 p-2 w-full rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 mb-2">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Update password state
              required
              className="border border-gray-300 p-2 w-full rounded"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default TrainingLogin;
