import React, { useState } from 'react';
import { Plus, Search, Filter, Download, BriefcaseIcon, Users, Clock, CheckCircle } from 'lucide-react';
import EmployerNewJob from './EmployerNewJob';

const EmployerHiring = () => {
  const [activeView, setActiveView] = useState('jobs');
  const [jobType, setJobType] = useState('all');
  const [newJobPanel,setNewJobPanel] = useState(false)

  const jobs = [
    {
      id: 1,
      title: "Senior Software Engineer",
      type: "Permanent",
      department: "Engineering",
      location: "Remote",
      applications: 24,
      status: "Active",
      postedDate: "2024-03-15"
    },
    {
      id: 2,
      title: "Project Manager",
      type: "Temporary",
      department: "Operations",
      location: "Hybrid",
      applications: 18,
      status: "Active",
      postedDate: "2024-03-20"
    }
  ];

  const applications = [
    {
      id: 1,
      candidate: "Sarah Wilson",
      position: "Senior Software Engineer",
      type: "Permanent",
      status: "Interview",
      appliedDate: "2024-03-16",
      match: "85%"
    }
  ];

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Hiring Management</h1>
        <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700" onClick={()=>{setNewJobPanel(true)}}>
          <Plus className="h-4 w-4 mr-2" />
          Post New Job
        </button>
      </div>
      <EmployerNewJob isOpen={newJobPanel} onClose={setNewJobPanel} />

      {/* Stats */}
      <div className="grid grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Open Positions</span>
            <BriefcaseIcon className="h-5 w-5 text-blue-600" />
          </div>
          <div className="text-2xl font-bold">12</div>
          <div className="text-sm text-gray-500">8 Permanent • 4 Temporary</div>
        </div>
        
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Total Applications</span>
            <Users className="h-5 w-5 text-green-600" />
          </div>
          <div className="text-2xl font-bold">156</div>
          <div className="text-sm text-gray-500">24 New this week</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Time to Hire</span>
            <Clock className="h-5 w-5 text-orange-600" />
          </div>
          <div className="text-2xl font-bold">18 days</div>
          <div className="text-sm text-gray-500">Average duration</div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Hired This Month</span>
            <CheckCircle className="h-5 w-5 text-purple-600" />
          </div>
          <div className="text-2xl font-bold">8</div>
          <div className="text-sm text-gray-500">5 Permanent • 3 Temporary</div>
        </div>
      </div>

      {/* Navigation */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex space-x-4">
          <button 
            onClick={() => setActiveView('jobs')}
            className={`px-4 py-2 rounded-lg ${
              activeView === 'jobs' ? 'bg-blue-50 text-blue-600' : 'text-gray-600'
            }`}
          >
            Job Listings
          </button>
          <button 
            onClick={() => setActiveView('applications')}
            className={`px-4 py-2 rounded-lg ${
              activeView === 'applications' ? 'bg-blue-50 text-blue-600' : 'text-gray-600'
            }`}
          >
            Applications
          </button>
        </div>

        <div className="flex space-x-4">
          <div className="relative">
            <Search className="h-5 w-5 absolute left-3 top-2.5 text-gray-400" />
            <input
              type="text"
              placeholder="Search..."
              className="pl-10 pr-4 py-2 border rounded-lg w-64"
            />
          </div>
          <button className="flex items-center px-4 py-2 border rounded-lg">
            <Filter className="h-4 w-4 mr-2" />
            Filters
          </button>
          <button className="flex items-center px-4 py-2 border rounded-lg">
            <Download className="h-4 w-4 mr-2" />
            Export
          </button>
        </div>
      </div>

      {/* Job Type Filter */}
      <div className="flex space-x-2 mb-6">
        {['all', 'permanent', 'temporary'].map((type) => (
          <button
            key={type}
            onClick={() => setJobType(type)}
            className={`px-3 py-1 rounded-full text-sm ${
              jobType === type 
                ? 'bg-blue-100 text-blue-600' 
                : 'bg-gray-100 text-gray-600'
            }`}
          >
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </button>
        ))}
      </div>

      {/* Content */}
      {activeView === 'jobs' ? (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Position</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Department</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Location</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Applications</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Posted Date</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {jobs.map((job) => (
                <tr key={job.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">{job.title}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{job.type}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{job.department}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{job.location}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{job.applications}</td>
                  <td className="px-6 py-4 text-sm">
                    <span className="px-2 py-1 rounded-full text-xs bg-green-100 text-green-800">
                      {job.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">{job.postedDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Candidate</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Position</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Applied Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Match</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {applications.map((app) => (
                <tr key={app.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">{app.candidate}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{app.position}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{app.type}</td>
                  <td className="px-6 py-4 text-sm">
                    <span className="px-2 py-1 rounded-full text-xs bg-blue-100 text-blue-800">
                      {app.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">{app.appliedDate}</td>
                  <td className="px-6 py-4 text-sm">
                    <span className="px-2 py-1 rounded-full text-xs bg-green-100 text-green-800">
                      {app.match}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default EmployerHiring;