import React from 'react';
import { useNavigate } from 'react-router-dom';

const TrainingCard = ({ title, industry, description, image, id }) => {
  const navigate = useNavigate();

  // Function to navigate to detailed page
  const handleCardClick = () => {
    navigate(`/trainings/${id}`);  // Assuming you have a detailed route setup
  };

  return (
    <div
      onClick={handleCardClick}
      className="bg-white border rounded-lg shadow-md hover:shadow-xl transform hover:scale-105 transition-all cursor-pointer max-w-sm mx-auto"
    >
      <img src={image} alt={title} className="rounded-t-lg w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-sm text-gray-600 mb-4">{industry}</p>
        <p className="text-gray-700 text-sm mb-4">{description}</p>
        <button className="text-blue-500 hover:underline">Learn More</button>
      </div>
    </div>
  );
};

export default TrainingCard;
