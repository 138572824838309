import React from 'react';

const PrivacyPolicy = () => {


    return (
        <div className="min-h-screen py-10 px-4 lg:px-20 bg-gray-50 mt-20">
            <div className="container mx-auto bg-white shadow-md rounded-lg p-8">
                <h1 className="text-4xl font-bold text-custom-blue mb-6 text-center">Privacy Policy</h1>
                <p className="text-gray-700 text-lg mb-4">
                    At TalentFirst, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you interact with our services.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">1. Information We Collect</h2>
                <p className="text-gray-700 text-lg mb-4">
                    We collect various types of information to provide and improve our services, including:
                </p>
                <ul className="list-disc list-inside text-gray-700 text-lg mb-4">
                    <li>Personal Identification Information (e.g., name, email address, phone number).</li>
                    <li>Professional Information (e.g., resume, employment history, qualifications).</li>
                    <li>Technical Data (e.g., IP address, browser type, and device information).</li>
                </ul>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">2. How We Use Your Information</h2>
                <p className="text-gray-700 text-lg mb-4">
                    The information we collect is used to:
                </p>
                <ul className="list-disc list-inside text-gray-700 text-lg mb-4">
                    <li>Provide, operate, and maintain our services.</li>
                    <li>Improve, personalize, and expand our services.</li>
                    <li>Communicate with you, either directly or through one of our partners, for customer service and promotional purposes.</li>
                    <li>Process your job applications and match you with relevant job opportunities.</li>
                </ul>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">3. Sharing Your Information</h2>
                <p className="text-gray-700 text-lg mb-4">
                    We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted partners and service providers for the purposes outlined in this policy.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">4. Data Security</h2>
                <p className="text-gray-700 text-lg mb-4">
                    We implement appropriate security measures to protect your data from unauthorized access, alteration, disclosure, or destruction.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">5. Your Data Protection Rights</h2>
                <p className="text-gray-700 text-lg mb-4">
                    You have the right to access, update, or delete the information we have on you. If you would like to exercise any of these rights, please contact us.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">6. Changes to This Privacy Policy</h2>
                <p className="text-gray-700 text-lg mb-4">
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">7. Contact Us</h2>
                <p className="text-gray-700 text-lg mb-4">
                    If you have any questions or concerns about this Privacy Policy, please contact us at:
                </p>
                <ul className="list-none text-gray-700 text-lg mb-4">
                    <li>Email: <a href="mailto:connect@talentfirst.global" className="text-blue-500 hover:underline">connect@talentfirst.global</a></li>
                    <li>Phone: <a href="tel:+917975563057" className='text-blue-500'>+91-7975563057</a></li>
                    <li>Address: 29/A, 27th Main Rd, Sector 5, 11th Sector, HSR Layout, Bengaluru, Karnataka 560102</li>
                </ul>
            </div>
        </div>
    );
};

export default PrivacyPolicy;