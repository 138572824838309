import React, { useState } from 'react';
import { 
  BriefcaseIcon, MapPin, DollarSign, 
  GraduationCap, FileText, Users, Shield, X 
} from 'lucide-react';
import JobDetailsSection from './JobDetailsSection';
import JobLocationSection from './JobLocationSection'; 
import JobCompensation from './JobCompensation';
import JobRequirements from './JobRequirements';
import JobResponsibilities from './JobResponsibilities';
import JobTraining from './JobTraining';
import JobCompliance from './JobCompliance';

const EmployerNewJob = ({ isOpen, onClose }) => {
  const [activeSection, setActiveSection] = useState('basic');
  const [formData, setFormData] = useState({
    jobDetails: {
      title: '',
      department: '',
      employmentType: '',
      jobType: '',
      numberOfOpenings: '',
      urgency: '',
      workMode: '',
      startDate: '',
      duration: '',
      reportsTo: '',
      teamSize: '',
      level: ''
    },
    location: {
      city: '',
      state: '',
      country: '',
      isRemoteAllowed: false,
      remoteType: '',
      timeZoneRequirements: '',
      geographicRestrictions: '',
      requiredOfficeDays: '',
      providesRelocation: false,
      relocationDetails: '',
      relocationEligibility: ''
    },
    compensation: {
      salaryMin: '',
      salaryMax: '',
      payPeriod: '',
      benefits: {
        healthcare: [],
        timeOff: [],
        professional: []
      },
      bonuses: [],
      hasEquity: false,
      equityDetails: '',
      additionalNotes: ''
    },
    requirements: {
      minimumEducation: '',
      fieldOfStudy: '',
      minYearsExperience: '',
      maxYearsExperience: '',
      managementExperience: '',
      requiredSkills: [],
      preferredSkills: [],
      languages: [],
      certifications: []
    },
    responsibilities: {
      coreResponsibilities: [],
      keyObjectives: [],
      projectInvolvement: '',
      decisionMaking: '',
      teamInteraction: '',
      crossFunctional: [],
      additionalNotes: ''
    },
    training: {
      onboarding: {
        duration: '',
        durationUnit: 'weeks',
        format: ''
      },
      modules: [],
      developmentResources: [],
      learningBudget: '',
      evaluationMethod: '',
      progressTracking: ''
    },
    compliance: {
      employmentType: '',
      workAuthorization: {
        citizenship: false,
        work_permit: false,
        visa_sponsorship: false
      },
      requiresBackgroundCheck: false,
      backgroundChecks: [],
      backgroundCheckTiming: '',
      requiredLicenses: [],
      requiredTraining: [],
      trainingTimeline: '',
      additionalRequirements: ''
    }
  });

  // Navigation sections
  const sections = [
    { id: 'basic', label: 'Job Details', icon: BriefcaseIcon },
    { id: 'location', label: 'Location', icon: MapPin },
    { id: 'compensation', label: 'Compensation', icon: DollarSign },
    { id: 'requirements', label: 'Requirements', icon: GraduationCap },
    { id: 'responsibilities', label: 'Responsibilities', icon: FileText },
    { id: 'training', label: 'Training', icon: Users },
    { id: 'compliance', label: 'Compliance', icon: Shield }
  ];

  // Utility function for handling form changes
  const handleChange = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const renderActiveSection = () => {
    switch (activeSection) {
      case 'basic':
        return <JobDetailsSection formData={formData} handleChange={handleChange} />;
      case 'location':
        return <JobLocationSection formData={formData} handleChange={handleChange} />;
      case 'compensation':
        return <JobCompensation formData={formData} handleChange={handleChange} />;
      case 'requirements':
        return <JobRequirements formData={formData} handleChange={handleChange} />;
      case 'responsibilities':
        return <JobResponsibilities formData={formData} handleChange={handleChange} />;
      case 'training':
        return <JobTraining formData={formData} handleChange={handleChange} />;
      case 'compliance':
        return <JobCompliance formData={formData} handleChange={handleChange} />;
      default:
        return <JobLocationSection formData={formData} handleChange={handleChange} />;
    }
  };

  return (
    <>
      <div className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={onClose} />
      <div className={`fixed inset-y-0 right-0 w-full md:w-2/3 lg:w-1/2 bg-white shadow-xl z-50 transform transition-transform duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="h-full flex flex-col">
          <div className="border-b">
            <div className="p-4 flex items-center gap-4">
              <button onClick={()=>onClose(false)} className="p-2 hover:bg-gray-100 rounded-full">
                <X className="w-5 h-5" />
              </button>
              <div>
                <h2 className="text-xl font-bold">New Hiring Request</h2>
                <p className="text-sm text-gray-600">Please provide detailed information about your hiring needs</p>
              </div>
            </div>

            {/* Scrollable Navigation Tabs */}
            <div className="px-4 border-b overflow-x-auto whitespace-nowrap">
              <nav className="flex space-x-4">
                {sections.map((section) => (
                  <button
                    key={section.id}
                    onClick={() => setActiveSection(section.id)}
                    className={`px-4 py-2 border-b-2 flex items-center transition-colors ${
                      activeSection === section.id ? 'border-blue-600 text-blue-600' : 'border-transparent text-gray-600 hover:text-gray-900'
                    }`}
                  >
                    <section.icon className="h-5 w-5 mr-2" />
                    {section.label}
                  </button>
                ))}
              </nav>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto">
            <div className="p-6 space-y-6">
              {renderActiveSection()}
            </div>
          </div>

          <div className="border-t p-4 bg-white">
            <div className="flex justify-end space-x-4">
              <button 
                type="button"
                className="px-4 py-2 border rounded-lg text-gray-600 hover:bg-gray-50"
              >
                Save as Draft
              </button>
              <button 
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Submit Request
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployerNewJob;
