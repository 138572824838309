import React from 'react';

const RefundPolicy = () => {


    return (
        <div className="min-h-screen py-10 px-4 lg:px-20 bg-gray-50 mt-20">
            <div className="container mx-auto bg-white shadow-md rounded-lg p-8">
                <h1 className="text-4xl font-bold text-custom-blue mb-6 text-center">Refund and Cancellation Policy</h1>

                <p className="text-gray-700 text-lg mb-4">
                    This refund and cancellation policy outlines how you can cancel or seek a refund for a course that you have purchased through our platform.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">1. Cancellation Requests</h2>
                <p className="text-gray-700 text-lg mb-4">
                    Cancellation requests for courses will only be considered if the request is made within 7 days of the purchase date. 
                    If you have accessed the course material, cancellation requests may not be entertained.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">2. Refund Eligibility</h2>
                <p className="text-gray-700 text-lg mb-4">
                    Refunds may be granted if you are unsatisfied with the course content or if the course does not match the description provided on our platform. 
                    To request a refund, please contact our customer service within 7 days of purchase.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">3. Damaged or Defective Access</h2>
                <p className="text-gray-700 text-lg mb-4">
                    If you experience issues accessing course materials due to technical problems, please report this to our customer service team within 7 days of the issue occurring. 
                    We will investigate the issue and take appropriate action.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">4. Refund Processing</h2>
                <p className="text-gray-700 text-lg mb-4">
                    If a refund is approved, please allow up to 3 business days for the refund to be processed back to your original payment method.
                </p>

                <h2 className="text-2xl font-bold text-custom-blue mb-4">5. Contact Us</h2>
                <p className="text-gray-700 text-lg mb-4">
                    For any queries regarding our refund and cancellation policy, please contact our customer service team.
                </p>
            </div>
        </div>
    );
};

export default RefundPolicy;
