import React, { useState } from 'react';
import { Camera, Clock, Building2, Mail, Phone, Globe, MapPin, Award, Edit, Briefcase, BookOpen, Users } from 'lucide-react';

export default function TrainerProfile() {
  const [profileType, setProfileType] = useState('INDIVIDUAL'); // or 'INSTITUTION'
  const [isEditing, setIsEditing] = useState(false);

  const [profile, setProfile] = useState({
    type: 'INDIVIDUAL',
    basicInfo: {
      name: 'John Doe',
      title: 'Senior Technical Trainer',
      institutionName: 'Tech Academy Plus', // For institutions
      email: 'john@example.com',
      phone: '+1 234 567 8900',
      website: 'www.example.com',
      location: 'New York, USA',
      bio: 'Experienced technical trainer with 10+ years in cloud and web technologies.',
      logo: '/placeholder-logo.png', // For institutions
      profilePicture: '/placeholder-avatar.png' // For individuals
    },
    expertise: [
      'Cloud Computing',
      'Web Development',
      'DevOps',
      'System Architecture'
    ],
    certifications: [
      {
        name: 'AWS Certified Trainer',
        issuer: 'Amazon Web Services',
        year: 2023
      },
      {
        name: 'Microsoft Certified Trainer',
        issuer: 'Microsoft',
        year: 2022
      }
    ],
    institutionDetails: { // For institutions only
      established: 2015,
      branches: 3,
      trainersCount: 25,
      accreditations: [
        'ISO 9001:2015',
        'National Training Authority'
      ]
    },
    experience: [
      {
        role: 'Lead Technical Trainer',
        company: 'Tech Solutions Inc.',
        duration: '2020 - Present',
        description: 'Leading technical training programs in cloud computing and web development.'
      }
    ],
    courses: [
      {
        title: 'AWS Developer Certification',
        students: 245,
        rating: 4.8,
        completionRate: 92
      },
      {
        title: 'React Advanced Patterns',
        students: 180,
        rating: 4.7,
        completionRate: 88
      }
    ],
    availability: {
      timeZone: 'EST',
      preferredHours: '9:00 AM - 6:00 PM',
      daysAvailable: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
    }
  });

  return (
    <div className="space-y-6">
      {/* Profile Type Toggle */}
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => setProfileType('INDIVIDUAL')}
          className={`px-4 py-2 rounded-lg ${
            profileType === 'INDIVIDUAL' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100'
          }`}
        >
          Individual Trainer
        </button>
        <button
          onClick={() => setProfileType('INSTITUTION')}
          className={`px-4 py-2 rounded-lg ${
            profileType === 'INSTITUTION' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100'
          }`}
        >
          Training Institution
        </button>
      </div>

      {/* Profile Header */}
      <div className="bg-white rounded-lg shadow">
        <div className="relative h-48 bg-blue-500 rounded-t-lg">
          <button className="absolute top-4 right-4 p-2 bg-white rounded-full shadow hover:bg-gray-50">
            <Edit className="w-5 h-5 text-gray-600" />
          </button>
        </div>
        
        <div className="px-6 pb-6">
          <div className="relative flex justify-between">
            <div className="flex gap-6">
              <div className="relative -mt-16">
                {profileType === 'INDIVIDUAL' ? (
                  <div className="w-32 h-32 rounded-full border-4 border-white bg-gray-100 overflow-hidden">
                    <img
                      src={profile.basicInfo.profilePicture}
                      alt="Profile"
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <div className="w-32 h-32 rounded-lg border-4 border-white bg-white overflow-hidden">
                    <img
                      src={profile.basicInfo.logo}
                      alt="Institution Logo"
                      className="w-full h-full object-contain"
                    />
                  </div>
                )}
                <button className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow hover:bg-gray-50">
                  <Camera className="w-4 h-4 text-gray-600" />
                </button>
              </div>
              
              <div className="pt-4">
                <h1 className="text-2xl font-bold">
                  {profileType === 'INDIVIDUAL' ? profile.basicInfo.name : profile.basicInfo.institutionName}
                </h1>
                <p className="text-gray-600">
                  {profileType === 'INDIVIDUAL' ? profile.basicInfo.title : `Training Institution • Est. ${profile.institutionDetails.established}`}
                </p>
                <div className="flex gap-4 mt-4">
                  <span className="flex items-center gap-1 text-sm text-gray-600">
                    <MapPin className="w-4 h-4" />
                    {profile.basicInfo.location}
                  </span>
                  <span className="flex items-center gap-1 text-sm text-gray-600">
                    <Globe className="w-4 h-4" />
                    {profile.basicInfo.website}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6">
        {/* Left Column */}
        <div className="space-y-6">
          {/* Contact Information */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold mb-4">Contact Information</h2>
            <div className="space-y-3">
              <div className="flex items-center gap-3">
                <Mail className="w-5 h-5 text-gray-600" />
                <span>{profile.basicInfo.email}</span>
              </div>
              <div className="flex items-center gap-3">
                <Phone className="w-5 h-5 text-gray-600" />
                <span>{profile.basicInfo.phone}</span>
              </div>
              <div className="flex items-center gap-3">
                <Globe className="w-5 h-5 text-gray-600" />
                <span>{profile.basicInfo.website}</span>
              </div>
            </div>
          </div>

          {/* Expertise */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold mb-4">Areas of Expertise</h2>
            <div className="flex flex-wrap gap-2">
              {profile.expertise.map((skill, index) => (
                <span
                  key={index}
                  className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>

          {/* Certifications */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold mb-4">Certifications</h2>
            <div className="space-y-4">
              {profile.certifications.map((cert, index) => (
                <div key={index} className="flex items-start gap-3">
                  <Award className="w-5 h-5 text-blue-500 mt-1" />
                  <div>
                    <p className="font-medium">{cert.name}</p>
                    <p className="text-sm text-gray-600">{cert.issuer} • {cert.year}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Center Column */}
        <div className="space-y-6 col-span-2">
          {/* About */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold mb-4">About</h2>
            <p className="text-gray-600">{profile.basicInfo.bio}</p>
          </div>

          {profileType === 'INSTITUTION' && (
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-semibold mb-4">Institution Overview</h2>
              <div className="grid grid-cols-3 gap-4 mb-6">
                <div className="text-center p-4 bg-gray-50 rounded-lg">
                  <Building2 className="w-6 h-6 text-blue-500 mx-auto mb-2" />
                  <p className="text-2xl font-bold">{profile.institutionDetails.branches}</p>
                  <p className="text-sm text-gray-600">Branches</p>
                </div>
                <div className="text-center p-4 bg-gray-50 rounded-lg">
                  <Users className="w-6 h-6 text-green-500 mx-auto mb-2" />
                  <p className="text-2xl font-bold">{profile.institutionDetails.trainersCount}</p>
                  <p className="text-sm text-gray-600">Trainers</p>
                </div>
                <div className="text-center p-4 bg-gray-50 rounded-lg">
                  <Award className="w-6 h-6 text-purple-500 mx-auto mb-2" />
                  <p className="text-2xl font-bold">{profile.institutionDetails.accreditations.length}</p>
                  <p className="text-sm text-gray-600">Accreditations</p>
                </div>
              </div>
              <div className="space-y-2">
                {profile.institutionDetails.accreditations.map((accred, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <Award className="w-4 h-4 text-yellow-500" />
                    <span>{accred}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Experience */}
          {profileType === 'INDIVIDUAL' && (
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-semibold mb-4">Experience</h2>
              <div className="space-y-6">
                {profile.experience.map((exp, index) => (
                  <div key={index} className="flex gap-4">
                    <Briefcase className="w-5 h-5 text-gray-600 mt-1" />
                    <div>
                      <h3 className="font-medium">{exp.role}</h3>
                      <p className="text-sm text-gray-600">{exp.company} • {exp.duration}</p>
                      <p className="mt-2 text-gray-600">{exp.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Courses */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold mb-4">Training Courses</h2>
            <div className="space-y-4">
              {profile.courses.map((course, index) => (
                <div key={index} className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex justify-between items-start mb-2">
                    <h3 className="font-medium">{course.title}</h3>
                    <div className="flex items-center gap-2">
                      <BookOpen className="w-4 h-4 text-blue-500" />
                      <span className="text-sm text-gray-600">{course.students} students</span>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <span className="text-sm text-gray-600">Rating: {course.rating}/5.0</span>
                    <span className="text-sm text-gray-600">Completion Rate: {course.completionRate}%</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Availability */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold mb-4">Availability</h2>
            <div className="space-y-3">
              <div className="flex items-center gap-2">
                <Clock className="w-5 h-5 text-gray-600" />
                <span>{profile.availability.preferredHours} ({profile.availability.timeZone})</span>
              </div>
              <div className="flex flex-wrap gap-2">
                {profile.availability.daysAvailable.map((day, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-gray-100 rounded-full text-sm"
                  >
                    {day}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}